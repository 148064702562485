import React, { Component } from "react";

import "./viewTranslationDetail.css";

import { AlertMessage, ImageName } from "../../../../enums";
import { ApiCall } from "../../../../services/middleware";
import { CryptoDecoder, Decoder } from "../../../../services/auth";
import { CommonData, ErrorCode, UsersEnums } from "../../../../services/constant";
import { toast, ToastContainer } from "react-toastify";
import {
  courseFeeValidate,
  inputEmptyValidate,
  mobileNumberValidator,
  numberValidator,
  startsWithZero,
  zipValidate,
} from "../../../../validators";
import { APP_URL, Regex } from "../../../../services/config";

import history from "../../../../history";
import {
  consoleLog,
  decimalValue,
  getLanguageArray,
  SetDatabaseDateFormat,
  SetDateFormat,
  SetDOBFormat,
  SetTimeFormat,
  SetUSAdateFormat,
  SetUSAdateFormatV2,
  textTruncate,
} from "../../../../services/common-function";
import ReactLoader from "../../../Loader";
import { IMAGE_PATH_ONLY } from "../../../../services/config/api_url";
// import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
import { MultiSelectBox, SelectBox } from "../../SharedComponents/inputText";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { COMMON } from "../../../../services/constant/connpmData";
import Select, { components } from "react-select";
import { Link } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import { FiEdit } from "react-icons/fi";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import MainChatPage from "../../../ChatPages/Mainpage";
import ChatGroupList from "../../Manage_Interpretation_jobs/JobDetails/ChatGroupList";
import { CgCloseO } from "react-icons/cg";
import EditServiceInfoModal from "./EditServiceInfoModal";
import { AiOutlineEye } from "react-icons/ai";
import { Stepper } from 'react-form-stepper';
import Button from '@mui/material/Button';
import { MdDelete } from "react-icons/md";
import moment from "moment";

import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { RiMailSendLine } from "react-icons/ri";
import { MdOutlineSms } from "react-icons/md";
import EmailModal from "../../Manage_Interpretation_jobs/EmailModal/EmailModal";
import SmsModal from "../../Manage_Interpretation_jobs/SmsModal/SmsModal";

// ..........................style for react select........................

const customStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    boxShadow: "0px 0px 4px 0px rgb(0 0 0 / 28%)",
    borderRadius: "6px",
    // width: "120%",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = "yellow";

    return {
      ...styles,
      color: isFocused ? "grey" : "white",
      //   backgroundColor: isDisabled ? "red" : "white",
      color: "#000",
      cursor: isDisabled ? "not-allowed" : "default",
    };
  },
};
// .......................for react select icon.............................................

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img
        src={ImageName.IMAGE_NAME.DOWN_ARROW_BLUE}
        style={{ width: "17px" }}
      />
    </components.DropdownIndicator>
  );
};

// ...................For Action Modal..........................
const styleCancelModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "500px",
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  // p: 4,
  borderRadius: "15px"
};

// ...................For Action Modal..........................
const styleEmailViewModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "50%",
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  // p: 4,
  borderRadius: "15px"
};

export default class ViewTranslationDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: true,
      requestId: "",
      clientId: "",
      clientFirstName: "",
      clientLastName: "",
      clientName: "",
      clientEmailId: "",
      clientPhone: "",
      clientIndustryType: "",
      //   .................jobDetails...........
      jobId: "",
      appointmentType: "",
      jobType: "",
      language: "",
      date: "",
      serviceAddress: "",
      siteContant: "",
      consumer: "",
      notesByClient: "",
      notesBy7C: "",
      taskList: [],
      taskData: [],
      // ................sendQuote............
      quoteId: "",
      quoteStatus: 0,
      targetAdience: "",
      location: "",
      dateTime: "",
      notesFromClient: "",
      deliveryType: "",
      duration: "",
      rushFee: 0,
      interpretationFee: 0,
      particularsArr: [],
      particular: "",
      particularAmount: [],
      totalParticularAmt: 0,
      particularAmt: 0,
      particularDataArr: [],
      particularAmountDataArr: [],
      totalAmount: 0,

      appointmentTypeArr: [],
      appointmentTypeData: [],
      languageArr: [],

      sourceLangData: {},
      targetLangData: {},
      listData: [],
      rushFee: "",
      taskDetails: [],
      translationUnitCost: "",
      dtpUnitCost: "",
      translationRate: "",
      countryCode: "",

      allChecked: false,
      vendorAssigned: [],
      targetLanguage: "",
      bidFee: 0,
      totalBidFee: 0,
      bidTask: "",
      bidtaskType: "",
      bidVendorDetails: [],
      bidWidth: "10%",
      additionalTranslationCharges: [],
      availabileData: {},
      allSourceDocuments: [],
      allTranslatedList: [],
      allVendorReviewdocs: [],
      allClientReviewDocs: [],
      allFinalDocs: [],

      open: false,
      anchorEl: "",
      curIndex: "",
      reviewNote: "",
      reviewDocId: "",
      newTaskList: [],
      allLanguageArr: [],
      allTranslationService: [],

      vendorListModalDetails: {},

      dummyTaskList: [],
      // ................

      dummySourceDoc: [],
      dummyTranslatedDoc: [],
      dummyVendorReviewDoc: [],
      dummyClientReviewDoc: [],
      dummyFinalDoc: [],
      pathCheck: false,
      actionModal: false,
      modalActionArr: [],
      selectedModalActionArr: {},
      modalNotes: "",
      modalActionData: {},
      modalDataPos: {},

      addDocumentModal: false,
      docModalData: [],
      addDocModalNotes: "",
      allTaskDropdownList: [],
      selectedTaskDropdown: {},
      addDocModalFlag: '',
      documentNameAddDocModal: "",

      quoteApprovalStatus: 0,

      payable: [],
      receivable: [],
      expectedDeadline: "",
      isUpdateDeadline: false,
      allJobNotes: [],
      notesText: "",

      addParticipantShow: false,
      chatParticipants: [],
      selectedChatParticipants: [],
      chatGroup: [],
      selectedChatGroupValue: '',
      chatGroupName: "",
      viewDocNotes: false,
      docNotes: "",
      docSubmit: false,

      editServiceModal: false,
      searchText: "",
      currentSearchData: {},
      isUpdateProjectSummary: false,
      updateTargetAudience: "",
      updateProjectDescription: "",
      updateNotesForAdmin: "",
      isDtp: 0,
      updateDTP: "",
      noteForAdmin: "",
      markAsCompleteModal: false,
      completeNote: "",
      isEnabled: true,
      isComplete: false,
      isDocUpload: false,
      progressLogData: [],
      showProgressLog: [],
      progressLogLimit: 10,
      currentLog: 0,
      allVendorRating: [
        {
          label: "All",
          value: "",
        },
        {
          label: "0",
          value: "0",
        },
        {
          label: "1",
          value: "1",
        },
        {
          label: "2",
          value: "2",
        },
        {
          label: "3",
          value: "3",
        },
        {
          label: "4",
          value: "4",
        },
        {
          label: "5",
          value: "5",
        },
      ],
      selectedVendorFilterRating: {
        label: "All",
        value: ""
      },
      allVendorFilterStatus: [
        {
          label: "All",
          value: "",
        },
        {
          label: "Offer Not sent",
          value: "-1",
        },
        {
          label: "Offered",
          value: "0",
        },
        {
          label: "Bid Recieved",
          value: "1",
        },
        {
          label: "Bid Rejected",
          value: "5",
        }
      ],
      selectedVendorFilterStatus: {
        label: "All",
        value: ""
      },
      vendorFilterName: "",
      vendorFilterPhone: "",
      vendorFilterEmail: "",

      isStateLoader: false,
      groupChatParticipant: [],
      addGrouoChatParticipantModal: false,
      selectedAddGroupChatParticipantValue: [],
      addGroupChatParticipant: [],
      chatParticipantModal: false,
      vendorSentOfferBtn: false,
      projectCancelReason: "",
      projectStatus: "",
      quoteRejectReason: "",
      quoteRejectDescription: "",
      allJobNotifications: [],
      allEmailSmsLog: [],
      viewEmailModal: false,
      viewEmailModalData: {},
      sentEmailModal: false,
      sentSmsModal: false,
      loader: false,
      userType: 0,
      numJobId: 0,
      completeNotes: "",
      showTab: "project"
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    window.$(".doc-wrap h3").click(function () {
      window.$(this).parent().find(".table-listing-app").slideToggle();
    });
    document.getElementById("backdrop").style.display = "none";
    let mainData = this.props.location,
      preData = mainData.state;
    if (mainData.from !== undefined) {
      if (mainData.from != 1) {
        this.setState({
          showTab: mainData.from
        })
      }
    } else {
      this.setState({
        showTab: "project"
      })
    }
    if (preData === undefined) {
      return history.push("/adminProjectList");
    } else {
      this.load();
    }
    // this.load();
    if (
      this.props.match.path === "/adminTranslationDetailsFromBillVerification"
    ) {
      this.setState({
        pathCheck: true,
      });
    } else {
      this.setState({
        pathCheck: false,
      });
    }


    let authData = localStorage.getItem("AuthToken");
    let authUser = Decoder.decode(authData);

    this.setState({
      userType: authUser.data.usertypeid
    });

    var classInstance = this;

    var viewModal = document.getElementById("viewModal");
    var bidModal = document.getElementById("bid-modal");
    var reviewModal = document.getElementById("review-modal");
    var vendorListModal = document.getElementById("vendor_list_modal");
    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function (event) {
      if (event.target == viewModal) {
        classInstance.closeViewModal();
      } else if (event.target == bidModal) {
        classInstance.closeBidModal();
      } else if (event.target == reviewModal) {
        classInstance.closeReviewModal();
      } else if (event.target == vendorListModal) {
        classInstance.closeVendorListModal();
      }
    };
  }

  load = async () => {
    let mainData = this.props.location,
      preData = mainData.state;
    this.setState({
      requestId: preData,
    });
    let detailData = {
      requestId: preData,
    };
    // consoleLog("Translation DetailData from Listing page >>> ::", detailData);
    this.fetchNotes(preData);
    this.fetchChatParticipants(preData);
    await this.fetchGroupChatParticipants(preData);
    this.fetchChatGroup(preData);
    this.fetchEmailSmsLog(preData);

    let clientDetails = {},
      jobDetails = {},
      quoteInfo = {},
      taskDetails = [],
      newTaskList = [],
      arr = [],
      quoteId = "",
      additionalTranslationCharges = [],
      allTranslationService = [],
      allLanguageArr = [],
      allTaskDropdownList = [],
      payable = [],
      receivable = [],
      quoteApprovalStatus = 0;

    this.getAllDocTaskList(detailData);

    this.getAllSourceDocuments(detailData);
    allLanguageArr = await getLanguageArray();

    axios.post(APP_URL.VENDOR_SERVICE_OFFERED).then((res) => {
      // console.log("RES>>>>", res);
      let respObject = res.data;
      if (
        respObject.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        respObject.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        let payload = Decoder.decode(respObject.data.payload);
        // console.log("service response payload>>>", payload);
        if (payload.data) {
          if (payload.data.services) {
            if (payload.data.services.length > 0) {
              payload.data.services.map(async (ser) => {
                if (
                  ser.id === 46 &&
                  ser.name === "Translation" &&
                  ser.subItem.length > 0
                ) {
                  ser.subItem.map((item) => {
                    allTranslationService.push({
                      label: item.subItemName,
                      value: item.id,
                    });
                  });
                  // consoleLog("allTranslationService::", allTranslationService)
                  // consoleLog(
                  //   "All Translation Services::>>>",
                  //   allTranslationService
                  // );

                  this.setState({
                    allTranslationService: allTranslationService,
                  });
                  // .................Translation Details............................

                  let detailsRes = await ApiCall("getTranslationDetails", detailData);
                  // consoleLog("Api res::",detailsRes)

                  let detailPayload = Decoder.decode(detailsRes.data.payload);
                  consoleLog("Project Details Translation>>>>", detailPayload);

                  clientDetails = detailPayload.data.clientDetails;
                  jobDetails = detailPayload.data.jobDetails;
                  this.fetchProgressData(preData);
                  this.fetchJobNotification(preData);


                  if (jobDetails.quoteStatus === 10) {
                    //...............Get Payable and Receivable Data............

                    let payableRes = await ApiCall(
                      "fetchPayableTranslation",
                      detailData
                    );
                    if (
                      payableRes.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
                      payableRes.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
                    ) {
                      let payload = Decoder.decode(payableRes.data.payload);
                      // consoleLog("Payable List:::", payload.data);
                      if (payload.data.details.length > 0) {
                        payable = payload.data.details;
                      }
                    }

                    let receivableRes = await ApiCall(
                      "fetchReceivableTranslation",
                      detailData
                    );
                    if (
                      receivableRes.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
                      receivableRes.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
                    ) {
                      let payload = Decoder.decode(receivableRes.data.payload);
                      // consoleLog("Receivable List:::", payload.data);
                      if (payload.data.details.length > 0) {
                        receivable = payload.data.details;
                      }
                    }
                  }
                  taskDetails = detailPayload.data.taskList;
                  if (detailPayload.data.quoteInfo) {
                    // if (jobDetails.quoteStatus > 0) {
                    quoteInfo = detailPayload.data.quoteInfo;
                    // }
                  }

                  if (taskDetails.length > 0) {
                    taskDetails.map((task, t) => {
                      // consoleLog("Sub Task main::", task.subTask)
                      let selectedTask = {},
                        selectedSourceLanguage = {},
                        selectedTargetLangugae = [],
                        subTask = [];

                      if (task.parentId != 0) {
                        newTaskList.map((newTask, n) => {
                          if (newTask.taskId == task.parentId) {
                            let selectedSubTask = {},
                              selectedSourceLanguageSub = {},
                              selectedTargetLangugaeSub = [];

                            let targetLanArrSub = task.targetLanguageId;

                            if (allTranslationService.length > 0) {
                              allTranslationService.map((aa) => {
                                if (aa.value == task.serviceId) {
                                  selectedSubTask = aa;
                                }
                              });
                            }

                            if (allLanguageArr.length > 0) {
                              allLanguageArr.map((ll) => {
                                if (ll.value == task.sourceLanguageId) {
                                  selectedSourceLanguageSub = ll;
                                }
                              });
                            }

                            allLanguageArr.map((ll) => {
                              if (targetLanArrSub == ll.value) {
                                selectedTargetLangugaeSub.push(ll);
                              }
                            });

                            let cc = 0;

                            if (newTask.subTask.length > 0) {
                              newTask.subTask.map((ss, s) => {
                                cc = Number(s + 1);
                              })
                            }

                            newTask.subTask.push({
                              id: task.id,
                              mid: task.id,
                              taskId: task.taskId,
                              taskNo: task.taskNo,
                              taskRequestId: task.taskRequestId,
                              subTaskNo: Number(cc + 1),
                              targetLanguageId: task.targetLanguageId,
                              selectedTargetLangugae: selectedTargetLangugaeSub,
                              sourceLanguageId: task.sourceLanguageId,
                              selectedSourceLanguage: selectedSourceLanguageSub,
                              selectedTask: selectedSubTask,
                              sourceLanguage: task.sourceLanguage,
                              serviceName: task.serviceName,
                              serviceId: task.serviceId,
                              targetLanguage: task.targetLanguage,
                              dueDate: SetUSAdateFormat(task.dueDate),
                              documentName: task.documentName,
                              docZipPath: task.docZipPath,
                              docDataArr: task.docDataArr,
                              wordCount: task.wordCount,
                              notes: task.notes,
                              vendors: [],
                              isDisabled: false,
                              bidCounter: task.bidCounter,
                              vendor: task.vendor,
                              status: task.status
                            })
                            // newTask.subTask = subTask;
                          }
                        })
                        // consoleLog("New task list:", newTaskList)
                      } else {

                        let targetLanArr = task.targetLanguageId;

                        allLanguageArr.map((ll) => {
                          if (targetLanArr == ll.value) {
                            selectedTargetLangugae.push(ll);
                          }
                        })

                        if (allTranslationService.length > 0) {
                          allTranslationService.map((aa) => {
                            if (aa.value == task.serviceId) {
                              selectedTask = aa;
                            }
                          });
                        }

                        if (allLanguageArr.length > 0) {
                          allLanguageArr.map((ll) => {
                            if (ll.value == task.sourceLanguageId) {
                              selectedSourceLanguage = ll;
                            }
                          });
                        }



                        // task.subTask.map((sub, s) => {
                        //   let selectedSubTask = {},
                        //     selectedSourceLanguageSub = {},
                        //     selectedTargetLangugaeSub = [];

                        //   let targetLanArrSub = sub.targetLanguageId;

                        //   if (allTranslationService.length > 0) {
                        //     allTranslationService.map((aa) => {
                        //       if (aa.value == sub.serviceId) {
                        //         selectedSubTask = aa;
                        //       }
                        //     });
                        //   }

                        //   if (allLanguageArr.length > 0) {
                        //     allLanguageArr.map((ll) => {
                        //       if (ll.value == sub.sourceLanguageId) {
                        //         selectedSourceLanguageSub = ll;
                        //       }
                        //     });
                        //   }

                        //   allLanguageArr.map((ll) => {
                        //     if (targetLanArrSub == ll.value) {
                        //       selectedTargetLangugaeSub.push(ll);
                        //     }
                        //   })

                        //   let len = task.subTask.length;

                        //   subTask.push({
                        //     id: t + 1 + "." + Number(s + 1),
                        //     taskId: sub.taskId,
                        //     taskNo: sub.taskNo,
                        //     taskRequestId: sub.taskRequestId,
                        //     subTaskNo: s + 1,
                        //     targetLanguageId: sub.targetLanguageId,
                        //     selectedTargetLangugae: selectedTargetLangugaeSub,
                        //     sourceLanguageId: sub.sourceLanguageId,
                        //     selectedSourceLanguage: selectedSourceLanguageSub,
                        //     selectedTask: selectedSubTask,
                        //     sourceLanguage: sub.sourceLanguage,
                        //     serviceName: sub.serviceName,
                        //     serviceId: sub.serviceId,
                        //     targetLanguage: sub.targetLanguage,
                        //     dueDate: SetUSAdateFormat(sub.dueDate),
                        //     documentName: sub.documentName,
                        //     docZipPath: sub.docZipPath,
                        //     docDataArr: sub.docDataArr,
                        //     wordCount: sub.wordCount,
                        //     notes: sub.notes,
                        //     vendors: [],
                        //     isDisabled: true,
                        //     bidCounter: sub.bidCounter,
                        //     vendor: sub.vendor
                        //   })

                        // })

                        newTaskList.push({
                          id: task.id,
                          taskId: task.taskId,
                          taskRequestId: task.taskRequestId,
                          taskNo: task.taskNo,
                          subTaskNo: task.subTaskNo,
                          selectedTask: selectedTask,
                          serviceId: task.serviceId,
                          serviceName: task.serviceName,
                          documentName: task.documentName,
                          docZipPath: task.docZipPath,
                          docDataArr: task.docDataArr,
                          wordCount: task.wordCount,
                          sourceLanguage: task.sourceLanguage,
                          targetLanguage: task.targetLanguage,
                          bidCounter: task.bidCounter,
                          targetLanguageId: task.targetLanguageId,
                          selectedTargetLangugae: selectedTargetLangugae,
                          sourceLanguageId: task.sourceLanguageId,
                          selectedSourceLanguage: selectedSourceLanguage,
                          dueDate: SetUSAdateFormat(task.dueDate),
                          notes: task.notes,
                          isDisabled: false,
                          vendors: [],
                          subTask: [],
                          vendor: task.vendor,
                          status: task.status
                        });
                      }
                    });
                  }

                  if (Object.keys(quoteInfo).length > 0) {
                    quoteId = quoteInfo.quoteId;
                    quoteApprovalStatus = quoteInfo.approvalStatus;
                    try {
                      let quoteData = JSON.parse(quoteInfo.additionalFee),
                        brr = quoteData.task;
                      if (quoteData.additionalFee) {
                        additionalTranslationCharges = quoteData.additionalFee;
                      }
                      brr.map((data) => {
                        arr.push({
                          taskId: parseInt(data.taskId),
                          service: data.service,
                          sourceLanguage: data.sourceLanguage,
                          targetLanguage: data.targetLanguage,
                          wordCountQty: Number(data.wordCountQty),
                          wordCountCost: Number(data.wordCountCost),
                          wordCountRate: Number(data.wordCountRate),
                          dtpQty: Number(data.dtpQty),
                          dtpCost: Number(data.dtpCost),
                          dtpRate: Number(data.dtpRate),
                          rushFeeUnit: Number(data.rushFeeUnit),
                          rushFeeCost: Number(data.rushFeeCost),
                          rushFeeRate: Number(data.rushFeeRate),
                          SubCost: Number(data.SubCost),
                          wordCountDesc: data.wordCountDesc,
                          dtpDesc: data.dtpDesc,
                          rushFeeDesc: data.rushFeeDesc
                        });
                      });
                    } catch (e) {
                      console.log(e);
                    }
                    this.setState({
                      totalAmount: Number(quoteInfo.total)
                    });
                  } else
                    if (taskDetails.length > 0) {
                      taskDetails.map((data) => {
                        arr.push({
                          taskId: data.taskId,
                          service: data.serviceName,
                          sourceLanguage: data.sourceLanguage,
                          targetLanguage: data.targetLanguage,
                          wordCountQty: 0,
                          wordCountCost: 0,
                          wordCountRate: 0,
                          dtpQty: 0,
                          dtpCost: 0,
                          dtpRate: 0,
                          rushFeeUnit: 0,
                          rushFeeCost: 0,
                          rushFeeRate: 0,
                          SubCost: 0,
                          wordCountDesc: "",
                          dtpDesc: "",
                          rushFeeDesc: ""
                        })
                      })
                    }

                  // ...........................................


                  this.setState({
                    // ..........Client Details..........
                    clientId: jobDetails.clientId,
                    clientFirstName: clientDetails.fName,
                    clientLastName: clientDetails.lName,
                    clientEmailId: clientDetails.businessEmail,
                    clientName: clientDetails.clientName,
                    clientIndustryType: clientDetails.industtryType,
                    clientPhone: clientDetails.adminPhone,
                    //.................Job details..............
                    quoteStatus: jobDetails.quoteStatus,
                    jobId: jobDetails.jobId,
                    numJobId: this.state.requestId,
                    appointmentType: jobDetails.appointmentType,
                    jobType:
                      jobDetails.jobType === null ||
                        jobDetails.jobType === undefined ||
                        jobDetails.jobType === ""
                        ? "N/A"
                        : jobDetails.jobType,
                    language: jobDetails.sourceLanguage,
                    date: jobDetails.expectedDeadline,
                    expectedDeadline: SetUSAdateFormatV2(jobDetails.expectedDeadline),
                    countryCode: clientDetails.adminCountryCode,
                    serviceAddress:
                      jobDetails.location === null ||
                        jobDetails.location === undefined ||
                        jobDetails.location === ""
                        ? "N/A"
                        : jobDetails.location,
                    siteContant:
                      jobDetails.siteContact === null ||
                        jobDetails.siteContact === undefined ||
                        jobDetails.siteContact === ""
                        ? "N/A"
                        : jobDetails.siteContact,
                    consumer: jobDetails.consumer,
                    notesByClient: jobDetails.noteByClient,
                    updateProjectDescription: jobDetails.noteByClient,
                    notesBy7C: jobDetails.noteFor7C,
                    quoteId: quoteId,
                    targetAdience:
                      jobDetails.targetAudience === null ||
                        jobDetails.targetAudience === undefined ||
                        jobDetails.targetAudience === ""
                        ? "N/A"
                        : jobDetails.targetAudience,
                    updateTargetAudience: jobDetails.targetAudience === null ||
                      jobDetails.targetAudience === undefined ||
                      jobDetails.targetAudience === ""
                      ? "N/A"
                      : jobDetails.targetAudience,
                    isDtp: jobDetails.isDtp,
                    updateDTP: jobDetails.isDtp,
                    location:
                      jobDetails.location === null ||
                        jobDetails.location === undefined ||
                        jobDetails.location === ""
                        ? "N/A"
                        : jobDetails.location,
                    dateTime: jobDetails.scheduleDate,
                    notesFromClient: jobDetails.noteByClient,
                    noteForAdmin: jobDetails.noteFor7C,
                    updateNotesForAdmin: jobDetails.noteFor7C,
                    deliveryType: clientDetails.isOutOfTown === 1 ? "OUT OF TOWN" : "IN TOWN",
                    duration: jobDetails.duration,
                    clientIndustryType: clientDetails.industtryType,
                    translationRate: quoteInfo.fee,
                    taskList: taskDetails,
                    taskData: arr,
                    rushFee: quoteInfo.rushFee,
                    additionalTranslationCharges: additionalTranslationCharges,
                    // totalAmount: quoteInfo.total,
                    newTaskList: newTaskList,
                    allLanguageArr: allLanguageArr,
                    quoteApprovalStatus: quoteApprovalStatus,
                    payable: payable,
                    receivable: receivable,
                    projectCancelReason: jobDetails.rejectReason ? jobDetails.rejectReason : "",
                    projectStatus: jobDetails.approvalStatus ? jobDetails.approvalStatus : "0",
                    quoteRejectReason: jobDetails.declinedType ? jobDetails.declinedType : "",
                    quoteRejectDescription: jobDetails.declinedReason ? jobDetails.declinedReason : "",
                    completeNotes: jobDetails.completionSummaryNote ? jobDetails.completionSummaryNote : "",
                    isLoad: false,
                  });
                  // this.isQuoteEnabled(quoteApprovalStatus)
                  this.isQuoteEnabled(jobDetails.approvalStatus)
                }
              });
            }
          }
        }
      }
    });
  };

  // listApi = async (data) => {
  //   const res = await ApiCall("fetchapprovedclientcontactlist", data);
  //   console.log("resData::::", res);
  //   if (
  //     res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
  //     res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
  //   ) {
  //     const decodeData = Decoder.decode(res.data.payload);
  //     console.log("Payload data>>>", decodeData);
  //     let listDetails = decodeData.data.clientContactDetailsList;
  //     let totalPage = Math.ceil(decodeData.data.totalCount / this.state.limit);
  //     console.log("Total Page>>>", listDetails);
  //     this.setState({
  //       listData: decodeData.data.clientContactDetailsList,
  //       total_page: totalPage,
  //     });
  //   }
  // };

  getAllSourceDocuments = async (data) => {
    let allSourceDocuments = [],
      allTranslatedList = [],
      allVendorReviewdocs = [],
      allClientReviewDocs = [],
      allFinalDocs = [];
    let res = await ApiCall("getAdminTranslationDocs", data);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let payload = Decoder.decode(res.data.payload);
      consoleLog("AllSourceDocs::", payload.data);
      if (payload.data.source.length > 0) {
        payload.data.source.map((doc) => {
          let fileArr = [],
            actionArr = [];
          if (Object.keys(payload.data.action).length > 0 && doc.taskNo in payload.data.action) {
            // consoleLog("DOcument Action::", payload.data.action[doc.taskNo])

            payload.data.action[doc.taskNo].map((ac) => {
              let textObj = {
                label: "Sent to " + ac.name + " for " + ac.serviceName,
                value: "Sent to " + ac.name + " for " + ac.serviceName,
                userId: ac.userId,
                name: ac.name,
                taskId: ac.taskId,
                requestId: ac.requestId,
                taskNo: ac.taskNo,
                subTaskNo: ac.subTaskNo,
                serviceId: ac.serviceId,
                serviceName: ac.serviceName,
                bidId: ac.bidId,
                flag: "vendor",
              }
              actionArr.push(textObj);
            })
          }
          fileArr = doc.docPath.split("/");
          allSourceDocuments.push({
            id: doc.id,
            filename: fileArr[2],
            serviceName: doc.serviceName,
            requestId: doc.requestId,
            serviceId: doc.serviceId,
            taskId: doc.taskId,
            taskNo: doc.taskNo,
            subTaskNo: doc.subTaskNo,
            revisionNo: doc.revisionNo,
            docName: doc.docName,
            docPath: doc.docPath,
            uploadedBy: doc.uploadedBy,
            uploadedByName: doc.uploadedByName,
            assignedTo: doc.assignedTo,
            assignedName: doc.assignedName,
            assignedTaskId: doc.assignedTaskId,
            directory: doc.directory,
            notes: doc.notes,
            action: doc.action,
            lastUpdated: doc.lastUpdated,
            bidId: doc.bidId,
            action: doc.action,
            actionArr: actionArr,
            selectedAction: {},
            userNotes: doc.userNotes == null || doc.userNotes == undefined || doc.userNotes == "" ? "" : doc.userNotes,
            flag: "source",
            languages: doc.languages,
            isAction: doc.isAction
          })
        })
      }

      if (payload.data.vendor.length > 0) {
        payload.data.vendor.map((doc) => {
          let fileArr = [],
            actionArr = [];
          if (Object.keys(payload.data.action).length > 0) {
            payload.data.action[doc.taskNo].map((ac) => {
              let textObj = {
                label: "Sent to " + ac.name + " for " + ac.serviceName,
                value: "Sent to " + ac.name + " for " + ac.serviceName,
                userId: ac.userId,
                name: ac.name,
                taskId: ac.taskId,
                requestId: ac.requestId,
                taskNo: ac.taskNo,
                subTaskNo: ac.subTaskNo,
                serviceId: ac.serviceId,
                serviceName: ac.serviceName,
                bidId: ac.bidId,
                flag: "vendorreview",
              }
              actionArr.push(textObj);
            })
          }
          actionArr.push({
            label: "Sent to client review",
            value: "Sent to client review",
            // userId: ac.userId,
            // name: ac.name,
            taskId: doc.taskId,
            requestId: doc.requestId,
            taskNo: doc.taskNo,
            subTaskNo: doc.subTaskNo,
            serviceId: doc.serviceId,
            // serviceName: ac.serviceName,
            bidId: doc.bidId,
            flag: "client",
          })
          fileArr = doc.docPath.split("/");
          allTranslatedList.push({
            id: doc.id,
            filename: fileArr[2],
            serviceName: doc.serviceName,
            requestId: doc.requestId,
            serviceId: doc.serviceId,
            taskId: doc.taskId,
            taskNo: doc.taskNo,
            subTaskNo: doc.subTaskNo,
            revisionNo: doc.revisionNo,
            docName: doc.docName,
            docPath: doc.docPath,
            uploadedBy: doc.uploadedBy,
            uploadedByName: doc.uploadedByName,
            assignedTo: doc.assignedTo,
            assignedName: doc.assignedName,
            assignedTaskId: doc.assignedTaskId,
            directory: doc.directory,
            notes: doc.notes,
            action: doc.action,
            lastUpdated: doc.lastUpdated,
            bidId: doc.bidId,
            action: doc.action,
            actionArr: actionArr,
            selectedAction: {},
            userNotes: doc.userNotes == null || doc.userNotes == undefined || doc.userNotes == "" ? "" : doc.userNotes,
            flag: "vendor",
            languages: doc.languages,
            isAction: doc.isAction
          })
        })
      }

      if (payload.data.client.length > 0) {
        payload.data.client.map((doc) => {
          let fileArr = [],
            actionArr = [];
          if (Object.keys(payload.data.action).length > 0) {
            payload.data.action[doc.taskNo].map((ac) => {
              let textObj = {
                label: "Sent to " + ac.name + " for " + ac.serviceName,
                value: "Sent to " + ac.name + " for " + ac.serviceName,
                userId: ac.userId,
                name: ac.name,
                taskId: ac.taskId,
                requestId: ac.requestId,
                taskNo: ac.taskNo,
                subTaskNo: ac.subTaskNo,
                serviceId: ac.serviceId,
                serviceName: ac.serviceName,
                bidId: ac.bidId,
                flag: "vendorreview",
              }
              actionArr.push(textObj);
            })
          }
          fileArr = doc.docPath.split("/");
          allClientReviewDocs.push({
            id: doc.id,
            filename: fileArr[2],
            serviceName: doc.serviceName,
            requestId: doc.requestId,
            serviceId: doc.serviceId,
            taskId: doc.taskId,
            taskNo: doc.taskNo,
            subTaskNo: doc.subTaskNo,
            revisionNo: doc.revisionNo,
            docName: doc.docName,
            docPath: doc.docPath,
            uploadedBy: doc.uploadedBy,
            uploadedByName: doc.uploadedByName,
            assignedTo: doc.assignedTo,
            assignedName: doc.assignedName,
            assignedTaskId: doc.assignedTaskId,
            directory: doc.directory,
            notes: doc.notes,
            action: doc.action,
            lastUpdated: doc.lastUpdated,
            bidId: doc.bidId,
            action: doc.action,
            actionArr: actionArr,
            selectedAction: {},
            userNotes: doc.userNotes == null || doc.userNotes == undefined || doc.userNotes == "" ? "" : doc.userNotes,
            flag: "client",
            languages: doc.languages,
            isAction: doc.isAction
          })
        })
      }

      if (payload.data.vendorreview.length > 0) {
        payload.data.vendorreview.map((doc) => {
          let fileArr = [],
            actionArr = [];
          if (Object.keys(payload.data.action).length > 0) {
            payload.data.action[doc.taskNo].map((ac) => {
              let textObj = {
                label: "Sent to " + ac.name + " for " + ac.serviceName,
                value: "Sent to " + ac.name + " for " + ac.serviceName,
                userId: ac.userId,
                name: ac.name,
                taskId: ac.taskId,
                requestId: ac.requestId,
                taskNo: ac.taskNo,
                subTaskNo: ac.subTaskNo,
                serviceId: ac.serviceId,
                serviceName: ac.serviceName,
                bidId: ac.bidId,
                flag: "vendorreview",
              }
              actionArr.push(textObj);
            })
          }
          actionArr.push({
            label: "Sent to client review",
            value: "Sent to client review",
            // userId: ac.userId,
            // name: ac.name,
            taskId: doc.taskId,
            requestId: doc.requestId,
            taskNo: doc.taskNo,
            subTaskNo: doc.subTaskNo,
            serviceId: doc.serviceId,
            // serviceName: ac.serviceName,
            bidId: doc.bidId,
            flag: "client",
          })
          fileArr = doc.docPath.split("/");
          allVendorReviewdocs.push({
            id: doc.id,
            filename: fileArr[2],
            serviceName: doc.serviceName,
            requestId: doc.requestId,
            serviceId: doc.serviceId,
            taskId: doc.taskId,
            taskNo: doc.taskNo,
            subTaskNo: doc.subTaskNo,
            revisionNo: doc.revisionNo,
            docName: doc.docName,
            docPath: doc.docPath,
            uploadedBy: doc.uploadedBy,
            uploadedByName: doc.uploadedByName,
            assignedTo: doc.assignedTo,
            assignedName: doc.assignedName,
            assignedTaskId: doc.assignedTaskId,
            directory: doc.directory,
            notes: doc.notes,
            action: doc.action,
            lastUpdated: doc.lastUpdated,
            bidId: doc.bidId,
            action: doc.action,
            actionArr: actionArr,
            selectedAction: {},
            userNotes: doc.userNotes == null || doc.userNotes == undefined || doc.userNotes == "" ? "" : doc.userNotes,
            flag: "vendor",
            languages: doc.languages,
            isAction: doc.isAction
          })
        })
      }

      if (payload.data.final.length > 0) {
        payload.data.final.map((doc) => {
          let fileArr = [],
            actionArr = [];
          // if (Object.keys(payload.data.action).length > 0) {
          //   payload.data.action[doc.taskNo].map((ac) => {
          //     let textObj = {
          //       label: "Send to " + ac.name + " for " + ac.serviceName,
          //       value: "Send to " + ac.name + " for " + ac.serviceName,
          //       userId: ac.userId,
          //       name: ac.name,
          //       taskId: ac.taskId,
          //       requestId: ac.requestId,
          //       taskNo: ac.taskNo,
          //       subTaskNo: ac.subTaskNo,
          //       serviceId: ac.serviceId,
          //       serviceName: ac.serviceName,
          //       bidId: ac.bidId,
          //       flag: "final",
          //     }
          //     actionArr.push(textObj);
          //   })
          // }
          fileArr = doc.docPath.split("/");
          allFinalDocs.push({
            id: doc.id,
            filename: fileArr[2],
            serviceName: doc.serviceName,
            requestId: doc.requestId,
            serviceId: doc.serviceId,
            taskId: doc.taskId,
            taskNo: doc.taskNo,
            subTaskNo: doc.subTaskNo,
            revisionNo: doc.revisionNo,
            docName: doc.docName,
            docPath: doc.docPath,
            uploadedBy: doc.uploadedBy,
            uploadedByName: doc.uploadedByName,
            assignedTo: doc.assignedTo,
            assignedName: doc.assignedName,
            assignedTaskId: doc.assignedTaskId,
            directory: doc.directory,
            notes: doc.notes,
            action: doc.action,
            lastUpdated: doc.lastUpdated,
            bidId: doc.bidId,
            action: doc.action,
            actionArr: [],
            selectedAction: {},
            userNotes: doc.userNotes == null || doc.userNotes == undefined || doc.userNotes == "" ? "" : doc.userNotes,
            flag: "final",
            languages: doc.languages
          })
        })
      }
      // if (payload.data.translatedList) {
      //   allTranslatedList = payload.data.translatedList;
      // }
      // if (payload.data.vendorReviewList) {
      //   allVendorReviewdocs = payload.data.vendorReviewList;
      // }
      // if (payload.data.clientReviewList) {
      //   allClientReviewDocs = payload.data.clientReviewList;
      // }
      // if (payload.data.finalList) {
      //   allFinalDocs = payload.data.finalList;
      // }

      this.setState({
        allSourceDocuments: allSourceDocuments,
        allTranslatedList: allTranslatedList,
        allVendorReviewdocs: allVendorReviewdocs,
        allClientReviewDocs: allClientReviewDocs,
        allFinalDocs: allFinalDocs,
      });
    }
  };

  onTranslationUnitCostChange = (e) => {
    let val = zipValidate(e.target.value);
    if (Regex.ONLY_15DIGITS_REGEX.test(+val)) {
      this.setState({
        translationUnitCost: val,
      });
    }
  };
  onTranslationRateChange = (e) => {
    let val = zipValidate(e.target.value);
    if (Regex.ONLY_15DIGITS_REGEX.test(+val)) {
      this.setState({
        translationRate: val,
      });
    }
  };

  onAppointmentChange = (data) => {
    this.setState({
      appointmentTypeData: data,
    });
  };

  onSourceLangChange = (data) => {
    this.setState({
      sourceLangData: data,
    });
  };

  onTargetLangChange = (data) => {
    this.setState({
      targetLangData: data,
    });
  };

  onParticularChange = (e, index) => {
    let amt = this.state.additionalTranslationCharges;
    for (let i = 0; i < this.state.additionalTranslationCharges.length; i++) {
      if (i === index) {
        amt[i].title = e.target.value;
      }
    }

    this.setState({
      additionalTranslationCharges: amt,
    });
  };

  onParticularAmountChange = (e, index) => {
    let data = e.target.value;
    var valid = !isNaN(data);

    let amount = e.target.value === "" ? 0 : parseFloat(e.target.value);
    // consoleLog("Particulars array amount", amount)

    let amt = this.state.additionalTranslationCharges,
      totalAmt = 0,
      allTranslationSum = 0,
      sum = 0;
    if (valid) {
      this.state.taskData.map((aa) => {
        allTranslationSum = allTranslationSum + Number(aa.SubCost)
      })
      for (let i = 0; i < amt.length; i++) {
        if (i === index) {
          amt[i].amt = amount;
        }

        sum = sum + Number(amt[i].amt);
      }
      totalAmt = parseFloat(sum) + parseFloat(allTranslationSum);
    }

    this.setState({
      additionalTranslationCharges: amt,
      totalParticularAmt: sum,
      totalAmount: totalAmt.toFixed(2),
    });
  };

  addParticularField = () => {
    let arr = this.state.additionalTranslationCharges;
    // particularItem = "",
    // particularAmnt = 0;
    // for (let i = 0; i < this.state.particularsArr.length; i++) {
    arr.push({
      title: this.state.particular,
      // particularAmt: parseInt(this.state.particularAmt),
      amt: this.state.particularAmt,
    });

    this.setState({
      additionalTranslationCharges: arr,
    });
  };


  onDeleteParticulars = (index) => {
    let particularArray = this.state.additionalTranslationCharges;

    let arr = [],
      amt = 0,
      allTranslationSum = 0,
      particularAmt = 0;
    for (let i = 0; i < particularArray.length; i++) {
      if (i != index) {
        particularAmt = particularAmt + parseFloat(particularArray[i].amt);
        arr.push(particularArray[i]);
      }
    }
    this.state.taskData.map((aa) => {
      allTranslationSum = allTranslationSum + (aa.SubCost)
    })
    amt =
      amt +
      particularAmt +
      parseFloat(allTranslationSum)

    this.setState({
      additionalTranslationCharges: arr,
      totalAmount: amt.toFixed(2),
    });
  };


  onCreateQuote = async () => {
    let mainData = this.props.location;
    let preData = mainData.state;
    let errorCount = 0;

    this.state.additionalTranslationCharges.map((aa) => {
      if (aa.title === "") {
        toast.error("Please input particulars on additional charges !!!");
        errorCount++;
      }
    })



    if (errorCount === 0) {
      let data = {
        requestId: preData.id,
        fee: this.state.interpretationFee,
        total: this.state.totalAmount,
        rushFee: this.state.rushFee,
        additionalFee: this.state.particularsArr,
        additionalTranslationCharges: this.state.additionalTranslationCharges
      };
      consoleLog("CreateQuote DAta ::", data)
      // let res = await ApiCall("createQuote", data);
      // if (
      //   res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      //   res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      // ) {
      //   toast.success(AlertMessage.MESSAGE.JOB.QUOTE_SEND, {
      //     hideProgressBar: true,
      //   });
      //   return history.push("/adminViewAllJobs");
      // } else{
      //   toast.error("Error Occured!!!");
      // }
    }
  };

  // handleWordQty = (e, i) => {
  //   let arr = this.state.taskData;
  //   let val = 0;
  //   if (
  //     e.target.value !== "" &&
  //     !isNaN(e.target.value) &&
  //     e.target.value !== 0
  //   ) {
  //     val = parseFloat(e.target.value);
  //   } else {
  //     val = 0;
  //   }
  //   arr[i].wordCountQty = val;
  //   arr[i].wordCountRate = val * arr[i].wordCountCost;
  //   arr[i].SubCost = arr[i].wordCountRate + arr[i].dtpRate + arr[i].rushFeeRate;
  //   this.setState({
  //     taskData: arr
  //   })
  //   this.getTotalAmount();
  // }

  handleWordCost = (e, i) => {
    let arr = this.state.taskData;
    let val = 0;
    if (
      e.target.value !== "" &&
      !isNaN(e.target.value) &&
      e.target.value !== 0
    ) {
      val = parseFloat(e.target.value);
    } else {
      val = 0;
    }
    arr[i].wordCountCost = val;
    arr[i].wordCountRate = val * arr[i].wordCountQty;
    arr[i].SubCost = arr[i].wordCountRate + arr[i].dtpRate + arr[i].rushFeeRate;
    this.setState({
      taskData: arr
    })
    this.getTotalAmount();
  }

  handleDtpQty = (e, i) => {
    let arr = this.state.taskData;
    let val = 0;
    if (
      e.target.value !== "" &&
      !isNaN(e.target.value) &&
      e.target.value !== 0
    ) {
      val = parseFloat(e.target.value);
    } else {
      val = 0;
    }
    arr[i].dtpQty = val;
    arr[i].dtpRate = val * arr[i].dtpCost;
    arr[i].SubCost = arr[i].wordCountRate + arr[i].dtpRate + arr[i].rushFeeRate;
    this.setState({
      taskData: arr
    })
    this.getTotalAmount();
  }

  handleDtpUnitCost = (e, i) => {
    let arr = this.state.taskData;
    let val = 0;
    if (
      e.target.value !== "" &&
      !isNaN(e.target.value) &&
      e.target.value !== 0
    ) {
      val = parseFloat(e.target.value);
    } else {
      val = 0;
    }
    arr[i].dtpCost = val;
    arr[i].dtpRate = val * arr[i].dtpQty;
    arr[i].SubCost = arr[i].wordCountRate + arr[i].dtpRate + arr[i].rushFeeRate;
    this.setState({
      taskData: arr
    })
    this.getTotalAmount();
  }

  handleRushUnit = (e, i) => {
    let arr = this.state.taskData;
    let val = 0;
    if (
      e.target.value !== "" &&
      !isNaN(e.target.value) &&
      e.target.value !== 0
    ) {
      val = parseFloat(e.target.value);
    } else {
      val = 0;
    }
    arr[i].rushFeeUnit = val;
    arr[i].rushFeeRate = val * arr[i].rushFeeCost;
    arr[i].SubCost = arr[i].wordCountRate + arr[i].dtpRate + arr[i].rushFeeRate;
    this.setState({
      taskData: arr
    })
    this.getTotalAmount();
  }

  handleRushUnitCost = (e, i) => {
    let arr = this.state.taskData;
    let val = 0;
    if (
      e.target.value !== "" &&
      !isNaN(e.target.value) &&
      e.target.value !== 0
    ) {
      val = parseFloat(e.target.value);
    } else {
      val = 0;
    }
    arr[i].rushFeeCost = val;
    arr[i].rushFeeRate = val * arr[i].rushFeeUnit;
    arr[i].SubCost = arr[i].wordCountRate + arr[i].dtpRate + arr[i].rushFeeRate;
    this.setState({
      taskData: arr
    });
    this.getTotalAmount();
  }

  handleWordCountDescChange = (e, i) => {
    let arr = this.state.taskData;
    arr[i].wordCountDesc = e.target.value;
    this.setState({
      taskData: arr
    })
  }

  handleDtpDescChange = (e, i) => {
    let arr = this.state.taskData;
    arr[i].dtpDesc = e.target.value;
    this.setState({
      taskData: arr
    })
  }

  handleRushFeeDescChange = (e, i) => {
    let arr = this.state.taskData;
    arr[i].rushFeeDesc = e.target.value;
    this.setState({
      taskData: arr
    })
  }

  // getTotalAmount = () => {
  //   let arr = this.state.taskData,
  //     totalAmt = 0;

  //   arr.map((data, i) => {
  //     totalAmt = totalAmt + Number(data.SubCost);
  //     console.log("Total Amount : ", totalAmt)
  //   });

  //   this.setState({
  //     totalAmount: totalAmt
  //   });
  // }

  // OnSendQuote = async () => {
  //   let errorCount = 0;

  //   this.state.additionalTranslationCharges.map((aa) => {
  //     if (aa.title === "") {
  //       toast.error("Please input particulars on additional charges !!!");
  //       errorCount++;
  //     }
  //   })

  //   if (errorCount === 0) {
  //     let finalData = {
  //       requestId: this.state.requestId,
  //       total: this.state.totalAmount.toString(),
  //       taskDetails: this.state.taskData,
  //       additionalFee: this.state.additionalTranslationCharges
  //     }
  //     // consoleLog("Final Quote Send Data", finalData);
  //     let res = await ApiCall("createQuoteTranslation", finalData);
  //     if (
  //       res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
  //       res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
  //     ) {
  //       toast.success(AlertMessage.MESSAGE.JOB.QUOTE_SEND);
  //       return history.push("/adminProjectList");
  //     }
  //   }
  // }

  onDownload = (pos) => {
    this.handleMenuClose();
    // window.open(
    //   IMAGE_PATH_ONLY + this.state.taskList[pos].docZipPath,
    //   "_blank"
    // );
    var file_path = IMAGE_PATH_ONLY + this.state.taskList[pos].docZipPath;
    var a = document.createElement('a');
    a.href = file_path;
    a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  onDownloadSourceDocs = (pos) => {
    // this.handleMenuClose();
    // window.open(
    //   IMAGE_PATH_ONLY + this.state.allSourceDocuments[pos].filePath,
    //   "_blank"
    // );
    var file_path = IMAGE_PATH_ONLY + this.state.allSourceDocuments[pos].docPath;
    var a = document.createElement('a');
    a.href = file_path;
    a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  onDownloadTranslatedDocs = (pos) => {
    // this.handleMenuClose();
    // window.open(
    //   IMAGE_PATH_ONLY + this.state.allTranslatedList[pos].filePath,
    //   "_blank"
    // );
    var file_path = IMAGE_PATH_ONLY + this.state.allTranslatedList[pos].docPath;
    var a = document.createElement('a');
    a.href = file_path;
    a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  onDownloadVendorReviewDocs = (pos) => {
    // this.handleMenuClose();
    // window.open(
    //   IMAGE_PATH_ONLY + this.state.allVendorReviewdocs[pos].filePath,
    //   "_blank"
    // );
    var file_path = IMAGE_PATH_ONLY + this.state.allVendorReviewdocs[pos].docPath;
    var a = document.createElement('a');
    a.href = file_path;
    a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  onDownloadClientReviewDocs = (pos) => {
    // this.handleMenuClose();
    // window.open(
    //   IMAGE_PATH_ONLY + this.state.allClientReviewDocs[pos].filePath,
    //   "_blank"
    // );
    var file_path = IMAGE_PATH_ONLY + this.state.allClientReviewDocs[pos].docPath;
    var a = document.createElement('a');
    a.href = file_path;
    a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  onDownloadFinalDocs = (pos) => {
    // this.handleMenuClose();
    // window.open(
    //   IMAGE_PATH_ONLY + this.state.allFinalDocs[pos].filePath,
    //   "_blank"
    // );
    var file_path = IMAGE_PATH_ONLY + this.state.allFinalDocs[pos].docPath;
    var a = document.createElement('a');
    a.href = file_path;
    a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  handleWordQty = (e, i) => {
    let arr = this.state.taskData;
    let targetLanArr = [];
    targetLanArr = arr[i].targetLanguage.split(",");
    let val = 0;
    if (
      e.target.value !== "" &&
      !isNaN(e.target.value) &&
      e.target.value !== 0
    ) {
      val = parseFloat(e.target.value)
    } else {
      val = 0;
    }
    arr[i].wordCountQty = val;
    arr[i].wordCountRate = val * arr[i].wordCountCost;
    arr[i].SubCost = ((arr[i].wordCountRate + arr[i].dtpRate + arr[i].rushFeeRate) * parseInt(targetLanArr.length)).toFixed(2);
    this.setState({
      taskData: arr,
    });
    this.getTotalAmount();
  };

  handleWordCost = (e, i) => {
    let arr = this.state.taskData;
    let targetLanArr = [];
    targetLanArr = arr[i].targetLanguage.split(",");
    let val = 0;
    if (
      e.target.value !== "" &&
      !isNaN(e.target.value) &&
      e.target.value !== 0
    ) {
      if (decimalValue(e.target.value)) {
        val = e.target.value;
      } else {
        return false;
      }
      // val = parseFloat(e.target.value);
    } else {
      val = 0;
    }
    arr[i].wordCountCost = val;
    arr[i].wordCountRate = val * arr[i].wordCountQty;
    arr[i].SubCost = ((arr[i].wordCountRate + arr[i].dtpRate + arr[i].rushFeeRate) * parseInt(targetLanArr.length)).toFixed(2);
    this.setState({
      taskData: arr,
    });
    this.getTotalAmount();
  };

  handleDtpQty = (e, i) => {
    let arr = this.state.taskData;
    let targetLanArr = [];
    targetLanArr = arr[i].targetLanguage.split(",");
    let val = 0;
    if (
      e.target.value !== "" &&
      !isNaN(e.target.value) &&
      e.target.value !== 0
    ) {
      val = parseFloat(e.target.value);
    } else {
      val = 0;
    }
    arr[i].dtpQty = val;
    arr[i].dtpRate = val * arr[i].dtpCost;
    arr[i].SubCost = ((arr[i].wordCountRate + arr[i].dtpRate + arr[i].rushFeeRate) * parseInt(targetLanArr.length)).toFixed(2);
    this.setState({
      taskData: arr,
    });
    this.getTotalAmount();
  };

  handleDtpUnitCost = (e, i) => {
    let arr = this.state.taskData;
    let targetLanArr = [];
    targetLanArr = arr[i].targetLanguage.split(",");
    let val = 0;
    if (
      e.target.value !== "" &&
      !isNaN(e.target.value) &&
      e.target.value !== 0
    ) {
      if (decimalValue(e.target.value)) {
        val = e.target.value;
      } else {
        return false;
      }
      // val = parseFloat(e.target.value);
    } else {
      val = 0;
    }
    arr[i].dtpCost = val;
    arr[i].dtpRate = val * arr[i].dtpQty;
    arr[i].SubCost = ((arr[i].wordCountRate + arr[i].dtpRate + arr[i].rushFeeRate) * parseInt(targetLanArr.length)).toFixed(2);
    this.setState({
      taskData: arr,
    });
    this.getTotalAmount();
  };

  handleRushUnit = (e, i) => {
    let arr = this.state.taskData;
    let targetLanArr = [];
    targetLanArr = arr[i].targetLanguage.split(",");
    let val = 0;
    if (
      e.target.value !== "" &&
      !isNaN(e.target.value) &&
      e.target.value !== 0
    ) {
      val = parseFloat(e.target.value);
    } else {
      val = 0;
    }
    arr[i].rushFeeUnit = val;
    arr[i].rushFeeRate = val * arr[i].rushFeeCost;
    arr[i].SubCost = ((arr[i].wordCountRate + arr[i].dtpRate + arr[i].rushFeeRate) * parseInt(targetLanArr.length)).toFixed(2);
    this.setState({
      taskData: arr,
    });
    this.getTotalAmount();
  };

  handleRushUnitCost = (e, i) => {
    let arr = this.state.taskData;
    let targetLanArr = [];
    targetLanArr = arr[i].targetLanguage.split(",");
    let val = 0;
    if (
      e.target.value !== "" &&
      !isNaN(e.target.value) &&
      e.target.value !== 0
    ) {
      if (decimalValue(e.target.value)) {
        val = e.target.value;
      } else {
        return false;
      }
      // val = parseFloat(e.target.value);
    } else {
      val = 0;
    }
    arr[i].rushFeeCost = val;
    arr[i].rushFeeRate = val * arr[i].rushFeeUnit;
    arr[i].SubCost = ((arr[i].wordCountRate + arr[i].dtpRate + arr[i].rushFeeRate) * parseInt(targetLanArr.length)).toFixed(2);
    this.setState({
      taskData: arr,
    });
    this.getTotalAmount();
  };

  getTotalAmount = () => {
    let arr = this.state.taskData,
      totalAmt = 0;

    arr.map((data, i) => {
      totalAmt = totalAmt + Number(data.SubCost);
      // consoleLog("Total Cost : ", totalAmt)
    });

    this.setState({
      totalAmount: parseFloat(totalAmt).toFixed(2),
    });
  };

  OnSendQuote = async () => {
    let finalData = {
      requestId: this.state.requestId,
      total: this.state.totalAmount.toString(),
      taskDetails: this.state.taskData,
      additionalFee: this.state.additionalTranslationCharges
    };
    // consoleLog("Final Quote Send Data", finalData);
    let res = await ApiCall("createQuoteTranslation", finalData);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      toast.success(AlertMessage.MESSAGE.JOB.QUOTE_SEND);
      window.scrollTo(0, 0);
      this.setState({
        isLoad: true
      })
      this.load();
    } else {
      toast.error(res.message);
    }
  };

  OnSaveQuote = async () => {
    let finalData = {
      requestId: this.state.requestId,
      total: this.state.totalAmount.toString(),
      taskDetails: this.state.taskData,
      additionalFee: this.state.additionalTranslationCharges,
      approvalStatus: this.state.quoteApprovalStatus.toString(),
      quoteId: this.state.quoteId.toString()
    };
    // consoleLog("Final Quote Send Data", finalData);
    let res = await ApiCall("saveQuoteTranslation", finalData);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      toast.success(AlertMessage.MESSAGE.JOB.QUOTE_SAVE);
      window.scrollTo(0, 0);
      this.setState({
        isLoad: true
      })
      this.load();
    } else {
      toast.error(res.message);
    }
  };

  acceptClient = async () => {
    let data = {
      clientId: this.state.clientId,
      requestId: this.state.requestId
    }
    let res = await ApiCall("acceptClientQuote", data);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      toast.success(AlertMessage.MESSAGE.JOB.QUOTE_ACCEPTED_BY_CLIENT);
      setTimeout(
        function () {
          return history.push("/adminProjectList")
        }
          .bind(this),
        1000
      );
    }
  }

  allVendorCheck = (e) => {
    let arr = this.state.listData;
    let brr = [];
    if (e.target.checked) {
      arr.map((data) => {
        data.isQuoteSent = 1;
        brr.push(data.userId);
      });
      this.setState({
        allChecked: true,
        listData: arr,
        vendorAssigned: brr,
      });
    } else {
      arr.map((data) => {
        data.isQuoteSent = 0;
      });
      brr = [];
      this.setState({
        allChecked: false,
        listData: arr,
        vendorAssigned: brr,
      });
    }
  };

  listChecked = (e, i) => {
    let arr = this.state.listData,
      brr = this.state.vendorAssigned;
    if (e.target.checked) {
      arr[i].isQuoteSent = 1;
      brr.push(arr[i].userId);
      this.setState({
        vendorAssigned: brr,
        listData: arr
      });
    } else {
      arr[i].isQuoteSent = 0;
      brr.map((data, k) => {
        if (data === arr[i].userId) {
          brr.splice(k, 1);
        }
      });
      // consoleLog("Brr >>> ", brr)
      this.setState({
        vendorAssigned: brr,
        listData: arr
      });
    }
  };

  handleVendorAssign = async () => {
    await this.loaderChange(true);
    this.setState({
      vendorSentOfferBtn: true
    })
    await this.loaderChange(false);

    let errorCount = 0;
    if (this.state.vendorAssigned.length === 0) {
      errorCount++;
      toast.error("Please select a vendor");
      return false;
    }
    if (errorCount === 0) {
      let data = {
        requestId: this.state.requestId,
        vendorId: this.state.vendorAssigned,
        taskId: this.state.vendorListModalDetails.taskId,
        taskNo: this.state.vendorListModalDetails.taskNo.toString(),
        subTaskNo: this.state.vendorListModalDetails.subTaskNo.toString(),
      };
      let res = await ApiCall("sentTranslationOfferToVendor", data);
      if (
        res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        this.closeVendorListModal();
        toast.success(AlertMessage.MESSAGE.JOB.OFFER_SENT_SUCCESS);
        // return history.push("/adminProjectList");
        window.scrollTo(0, 0);
        this.setState({
          isLoad: true,
          vendorSentOfferBtn: false
        })
        this.load();
      } else {
        await this.loaderChange(true);
        this.setState({
          vendorSentOfferBtn: false
        })
        await this.loaderChange(false);
      }
    }
  };

  // openViewModal = () => {
  //   document.getElementById("backdrop").style.display = "block";
  //   document.getElementById("viewModal").style.display = "block";
  //   document.getElementById("viewModal").classList.add("show");
  // };
  // closeViewModal = () => {
  //   document.getElementById("backdrop").style.display = "none";
  //   document.getElementById("viewModal").style.display = "none";
  //   document.getElementById("viewModal").classList.remove("show");
  // };

  handleBidModal = async (id) => {
    // consoleLog("fetch id:", id)
    let arr = this.state.taskList,
      obj = {};
    arr.map((data) => {
      if (data.taskId === id) {
        obj = {
          // requestId: this.state.requestId,
          taskId: id
        };
        this.setState({
          bidtaskType: data.serviceName,
          bidTask: data.sourceLanguage + ">" + data.targetLanguage,
        });
        // consoleLog("Bid View Request ::", obj);
      }
    });
    let res = await ApiCall("getBidReqStat", obj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let payload = Decoder.decode(res.data.payload);
      consoleLog("Bid Request Details : ", payload.data);
      let tableWidth = 50 / payload.data.vendorList.length;
      // consoleLog("WidthLength ::", tableWidth)
      this.setState({
        bidVendorDetails: payload.data.vendorList,
        bidWidth: tableWidth + "%",
      });
      this.openBidModal();
    }
  };

  handleBidModalForSubTask = async (id, i, j) => {
    // consoleLog("Vendor Id: ", id)
    let arr = this.state.taskList,
      obj = { taskId: id };
    // consoleLog("Array data::", this.state.taskList)
    arr.map((data) => {
      if (data.taskId === id) {
        // obj = {
        //   // requestId: this.state.requestId,
        //   taskId: id
        // };
        // consoleLog("Selected Task datA for show bid details>>>>>>.", data)
        this.setState({
          bidtaskType: data.serviceName,
          bidTask: data.sourceLanguage + ">" + data.targetLanguage,
        });
        // consoleLog("Bid View Request ::", obj);
      }
    });
    let res = await ApiCall("getBidReqStat", obj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let payload = Decoder.decode(res.data.payload);
      // consoleLog("Bid Request Details : ", payload.data);
      let tableWidth = 50 / payload.data.vendorList.length;
      // consoleLog("WidthLength ::", tableWidth)
      this.setState({
        bidVendorDetails: payload.data.vendorList,
        bidWidth: tableWidth + "%",
      });
      this.openBidModal();
    }
  };
  openBidModal = () => {
    document.getElementById("backdrop").style.display = "block";
    document.getElementById("bid-modal").style.display = "block";
    document.getElementById("bid-modal").classList.add("show");
  };
  closeBidModal = () => {
    document.getElementById("backdrop").style.display = "none";
    document.getElementById("bid-modal").style.display = "none";
    document.getElementById("bid-modal").classList.remove("show");
  };

  vendorAssigned = async (id) => {
    let apiObj = {
      requestId: this.state.requestId,
      vendorId: this.state.bidVendorDetails[id].userId,
      taskId: this.state.bidVendorDetails[id].taskId,
      // taskNumber: this.state.bidVendorDetails[id].taskNo,
      bidId: this.state.bidVendorDetails[id].bidId,
    };
    // consoleLog("Assigned Vendor data", apiObj);
    let res = await ApiCall("assignVendorForTranslationV2", apiObj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      toast.success(AlertMessage.MESSAGE.JOB.VENDOR_ASSIGNED_SUCCESS);
      this.closeBidModal();
      // setTimeout(
      //   function () {
      //     return history.push("/adminProjectList");
      //   }.bind(this),
      //   1000
      // );
      window.scrollTo(0, 0);
      this.setState({
        isLoad: true
      })
      this.load();
    }
  };


  //................funct for menuBtn on click................
  menuBtnhandleClick = (index, event) => {
    this.setState({
      curIndex: index,
      anchorEl: event.currentTarget,
    });
  };

  handleMenuClose = () => {
    this.setState({
      anchorEl: null,
      anchorEl1: null,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleClick = () => {
    this.setState({
      open: true,
    });
  };
  openReviewModal = () => {
    document.getElementById("backdrop").style.display = "block";
    document.getElementById("review-modal").style.display = "block";
    document.getElementById("review-modal").classList.add("show");
  };
  closeReviewModal = () => {
    document.getElementById("backdrop").style.display = "none";
    document.getElementById("review-modal").style.display = "none";
    document.getElementById("review-modal").classList.remove("show");
  };
  openVendorListModal = () => {
    document.getElementById("backdrop").style.display = "block";
    document.getElementById("vendor_list_modal").style.display = "block";
    document.getElementById("vendor_list_modal").classList.add("show");
  };
  closeVendorListModal = () => {
    document.getElementById("backdrop").style.display = "none";
    document.getElementById("vendor_list_modal").style.display = "none";
    document.getElementById("vendor_list_modal").classList.remove("show");
  };

  onReviewNoteChange = (e) => {
    this.setState({
      reviewNote: e.target.value,
    });
  };

  sentUnderReview = async () => {
    this.closeReviewModal();
    let obj = {
      documentId: this.state.reviewDocId,
      note: this.state.reviewNote,
    };
    // consoleLog("ObjSend review API ::", obj)
    let res = await ApiCall("setVendorReviewDocument", obj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      toast.success("Document under reviewed");
      this.setState({
        reviewDocId: "",
        reviewNote: "",
      });

      let detailData = {
        requestId: this.state.requestId,
      };
      this.getAllSourceDocuments(detailData);
    }
  };

  sentToClient = async (docid) => {
    this.handleMenuClose();
    let obj = {
      documentId: docid,
    };
    consoleLog("ObjSend sentClient API ::", obj);
    let res = await ApiCall("sentDocToClient", obj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      toast.success("Document sent to client");

      let detailData = {
        requestId: this.state.requestId,
      };
      this.getAllSourceDocuments(detailData);
    }
  };

  openRiview = (id) => {
    this.handleMenuClose();
    this.setState({
      reviewDocId: id,
    });
    this.openReviewModal();
  };

  translationMainServiceTypeChange = (value, i) => {
    let arr = this.state.newTaskList;
    arr[i].selectedTask = value;
    arr[i].serviceName = value.label;
    arr[i].serviceId = value.value;
    this.setState({
      newTaskList: arr
    })

  };

  sourceLanguageChangeMainTask = (value, i) => {
    let arr = this.state.newTaskList;
    arr[i].selectedSourceLanguage = value;
    arr[i].sourceLanguage = value.label;
    arr[i].sourceLanguageId = value.value;
    this.setState({
      newTaskList: arr
    })
  };

  targetLanguageChangeMainTask = (value, i) => {
    // let lanArrId = [],
    //   lanArrName = [];
    // value.map((ll) => {
    //   lanArrId.push(ll.value);
    //   lanArrName.push(ll.label);
    // })
    let arr = this.state.newTaskList;
    arr[i].selectedTargetLangugae = value;
    arr[i].targetLanguage = value.label;
    arr[i].targetLanguageId = value.value;
    this.setState({
      newTaskList: arr
    })
  };

  noteChangeMainTask = (e, i) => {
    let arr = this.state.newTaskList;
    arr[i].notes = e.target.value;
    this.setState({
      newTaskList: arr
    })
  };


  translationServiceTypeChange = (value, i, j) => {
    let arr = this.state.newTaskList;
    arr[i].subTask[j].selectedTask = value;
    arr[i].subTask[j].serviceName = value.label;
    arr[i].subTask[j].serviceId = value.value;
    this.setState({
      newTaskList: arr
    })

  };

  sourceLanguageChangeTask = (value, i, j) => {
    let arr = this.state.newTaskList;
    arr[i].subTask[j].selectedSourceLanguage = value;
    arr[i].subTask[j].sourceLanguage = value.label;
    arr[i].subTask[j].sourceLanguageId = value.value;
    this.setState({
      newTaskList: arr
    })
  };

  targetLanguageChangeTask = (value, i, j) => {
    // let lanArrId = [],
    //   lanArrName = [];
    // value.map((ll) => {
    //   lanArrId.push(ll.value);
    //   lanArrName.push(ll.label);
    // })
    let arr = this.state.newTaskList;
    arr[i].subTask[j].selectedTargetLangugae = value;
    arr[i].subTask[j].targetLanguage = value.label;
    arr[i].subTask[j].targetLanguageId = value.value;
    this.setState({
      newTaskList: arr
    })
  };

  expectedDeadlineChange = (date, id) => {
    this.setState({
      expectedDeadline: SetUSAdateFormatV2(date),
      // isUpdateDeadline: false
    })
  };

  dueDateChangeTask = (date, id) => {
    let arr = this.state.newTaskList;
    arr[id].dueDate = SetUSAdateFormat(date);
    this.setState({
      newTaskList: arr
    })
  };

  dueDateChangeSubTask = (date, i, j) => {
    let arr = this.state.newTaskList;
    arr[i].subTask[j].dueDate = SetUSAdateFormat(date);
    this.setState({
      newTaskList: arr
    })
  };

  noteChangeTask = (e, i, j) => {
    let arr = this.state.newTaskList;
    arr[i].subTask[j].notes = e.target.value;
    this.setState({
      newTaskList: arr
    })
  };

  addSubtask = (pos) => {
    let arr = this.state.newTaskList;
    arr.map((data, i) => {
      if (i == pos) {
        let len = data.subTask.length + 1;
        data.subTask.push({
          id: i + 1 + "." + len,
          taskId: "0",
          taskNo: data.taskNo,
          taskRequestId: data.taskRequestId,
          subTaskNo: len,
          targetLanguageId: data.targetLanguageId,
          selectedTargetLangugae: data.selectedTargetLangugae,
          sourceLanguageId: data.sourceLanguageId,
          selectedSourceLanguage: data.selectedSourceLanguage,
          selectedTask: data.selectedTask,
          sourceLanguage: data.sourceLanguage,
          serviceName: data.serviceName,
          serviceId: data.serviceId,
          targetLanguage: data.targetLanguage,
          dueDate: data.dueDate,
          documentName: data.documentName,
          docZipPath: data.docZipPath,
          docDataArr: data.docDataArr,
          wordCount: data.wordCount,
          notes: "",
          vendors: [],
          isDisabled: false,
          bidCounter: data.bidCounter,
          vendor: ""
        });
      }
    });
    this.setState({
      newTaskList: arr,
    });
  };

  // .....................new source doc functionality...........................

  onFileChange = (index) => (e) => {
    this.state.dummySourceDoc[index].fileName = e.target.value;
    this.setState({
      dummySourceDoc: this.state.dummySourceDoc,
    });
  };
  onTaskIdChange = (index) => (e) => {
    this.state.dummySourceDoc[index].taskId = e.target.value;
    this.setState({
      dummySourceDoc: this.state.dummySourceDoc,
    });
  };
  onNotesChange = (index) => (e) => {
    this.state.dummySourceDoc[index].notesToVendor = e.target.value;
    this.setState({
      dummySourceDoc: this.state.dummySourceDoc,
    });
  };
  onActionChange = () => { };
  onSubmitSourceDoc = () => { };

  onResetSourceDoc = () => {
    this.state.dummySourceDoc.map((obj) => {
      obj.fileName = "";
      obj.taskId = "";
      obj.notesToVendor = "";
    });
    this.setState({
      dummySourceDoc: this.state.dummySourceDoc,
    });
  };

  // .....................new translated doc functionality...........................

  onFileChange_Translated = (index) => (e) => {
    this.state.dummyTranslatedDoc[index].fileName = e.target.value;
    this.setState({
      dummyTranslatedDoc: this.state.dummyTranslatedDoc,
    });
  };
  onTaskIdChange_Translated = (index) => (e) => {
    this.state.dummyTranslatedDoc[index].taskId = e.target.value;
    this.setState({
      dummyTranslatedDoc: this.state.dummyTranslatedDoc,
    });
  };
  onNotesChange_Translated = (index) => (e) => {
    this.state.dummyTranslatedDoc[index].notesToVendor = e.target.value;
    this.setState({
      dummyTranslatedDoc: this.state.dummyTranslatedDoc,
    });
  };
  onActionChange = () => { };

  onSubmitTranslatedDoc = () => { };

  onResetTranslatedDoc = () => {
    this.state.dummyTranslatedDoc.map((obj) => {
      obj.fileName = "";
      obj.taskId = "";
      obj.notesToVendor = "";
    });
    this.setState({
      dummyTranslatedDoc: this.state.dummyTranslatedDoc,
    });
  };
  // .....................new vendor review doc functionality...........................

  onFileChange_VendorReview = (index) => (e) => {
    this.state.dummyVendorReviewDoc[index].fileName = e.target.value;
    this.setState({
      dummyVendorReviewDoc: this.state.dummyVendorReviewDoc,
    });
  };
  onTaskIdChange_VendorReview = (index) => (e) => {
    this.state.dummyVendorReviewDoc[index].taskId = e.target.value;
    this.setState({
      dummyVendorReviewDoc: this.state.dummyVendorReviewDoc,
    });
  };
  onNotesChange_VendorReview = (index) => (e) => {
    this.state.dummyVendorReviewDoc[index].notesToVendor = e.target.value;
    this.setState({
      dummyVendorReviewDoc: this.state.dummyVendorReviewDoc,
    });
  };
  onActionChange = () => { };

  onSubmitVendorReviewDoc = () => { };

  onResetVendorReviewDoc = () => {
    this.state.dummyVendorReviewDoc.map((obj) => {
      obj.fileName = "";
      obj.taskId = "";
      obj.notesToVendor = "";
    });
    this.setState({
      dummyVendorReviewDoc: this.state.dummyVendorReviewDoc,
    });
  };

  // .....................new client review doc functionality...........................

  onFileChange_ClientReview = (index) => (e) => {
    this.state.dummyClientReviewDoc[index].fileName = e.target.value;
    this.setState({
      dummyClientReviewDoc: this.state.dummyClientReviewDoc,
    });
  };
  onTaskIdChange_ClientReview = (index) => (e) => {
    this.state.dummyClientReviewDoc[index].taskId = e.target.value;
    this.setState({
      dummyClientReviewDoc: this.state.dummyClientReviewDoc,
    });
  };
  onNotesChange_ClientReview = (index) => (e) => {
    this.state.dummyClientReviewDoc[index].notesToVendor = e.target.value;
    this.setState({
      dummyClientReviewDoc: this.state.dummyClientReviewDoc,
    });
  };
  onActionChange = () => { };

  onSubmitClientReviewDoc = () => { };

  onResetClientReviewDoc = () => {
    this.state.dummyClientReviewDoc.map((obj) => {
      obj.fileName = "";
      obj.taskId = "";
      obj.notesToVendor = "";
    });
    this.setState({
      dummyClientReviewDoc: this.state.dummyClientReviewDoc,
    });
  };

  // .....................new final doc functionality...........................

  onFileChange_Final = (index) => (e) => {
    this.state.dummyFinalDoc[index].fileName = e.target.value;
    this.setState({
      dummyFinalDoc: this.state.dummyFinalDoc,
    });
  };
  onTaskIdChange_Final = (index) => (e) => {
    this.state.dummyFinalDoc[index].taskId = e.target.value;
    this.setState({
      dummyFinalDoc: this.state.dummyFinalDoc,
    });
  };
  onNotesChange_Final = (index) => (e) => {
    this.state.dummyFinalDoc[index].notesToVendor = e.target.value;
    this.setState({
      dummyFinalDoc: this.state.dummyFinalDoc,
    });
  };
  onActionChange = () => { };

  onSubmitFinalDoc = () => { };

  onResetFinalDoc = () => {
    this.state.dummyFinalDoc.map((obj) => {
      obj.fileName = "";
      obj.taskId = "";
      obj.notesToVendor = "";
    });
    this.setState({
      dummyFinalDoc: this.state.dummyFinalDoc,
    });
  };



  onSubmitTaskReviewList = async () => {
    let arr = this.state.newTaskList,
      brr = [];

    arr.map((task) => {
      let crr = [];
      task.subTask.map((data) => {
        crr.push({
          id: data.id,
          taskId: data.taskId.toString(),
          taskNo: data.taskNo.toString(),
          taskRequestId: data.taskRequestId.toString(),
          subTaskNo: data.subTaskNo.toString(),
          serviceId: data.serviceId,
          serviceName: data.serviceName,
          targetLanguageId: data.targetLanguageId,
          sourceLanguageId: data.sourceLanguageId,
          sourceLanguage: data.sourceLanguage,
          targetLanguage: data.targetLanguage,
          dueDate: SetDatabaseDateFormat(data.dueDate),
          notes: data.notes,
          documentName: data.documentName,
          docZipPath: data.docZipPath,
          docDataArr: data.docDataArr,
          wordCount: data.wordCount.toString(),
          vendors: data.vendors
        })
      })
      brr.push({
        id: task.id,
        taskId: task.taskId.toString(),
        taskNo: task.taskNo.toString(),
        taskRequestId: task.taskRequestId.toString(),
        subTaskNo: task.subTaskNo.toString(),
        serviceId: task.serviceId,
        serviceName: task.serviceName,
        documentName: task.documentName,
        docZipPath: task.docZipPath,
        docDataArr: task.docDataArr,
        wordCount: task.wordCount.toString(),
        sourceLanguage: task.sourceLanguage,
        targetLanguage: task.targetLanguage,
        bidCounter: task.bidCounter,
        targetLanguageId: task.targetLanguageId,
        sourceLanguageId: task.sourceLanguageId,
        dueDate: SetDatabaseDateFormat(task.dueDate),
        notes: task.notes,
        vendors: task.vendors,
        subTask: crr,
      })
    })
    consoleLog("NewTask List req::", brr);

    let reqObj = {
      data: brr,
      requestId: this.state.requestId
    }
    if (brr.length == arr.length) {
      let res = await ApiCall("updateTranslationTask", reqObj);
      if (
        res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        toast.success("Data Updated Successfully");
        // setTimeout(
        //   () => { return history.push("/adminProjectList"); },
        //   1000
        // );
        window.scrollTo(0, 0);
        this.setState({
          isLoad: true
        })
        this.load();

      } else {
        toast.error("Error occured");
      }
    }

  }

  onOpenVendorListByTask = async (serviceId, sourceId, sourceLanguage, targetId, targetLanguage, taskId, taskNo, subTaskNo, serviceName, wordCount) => {
    // if (wordCount == 0) {
    //   toast.error("Word count should not be 0")
    // } else {
    let reqObj = {
      requestId: this.state.requestId,
      serviceId: serviceId,
      sourceLanguageId: sourceId,
      sourceLanguage: sourceLanguage,
      targetLanguageId: targetId,
      targetLanguage: targetLanguage,
      taskId: taskId,
      taskNo: taskNo.toString(),
      subTaskNo: subTaskNo.toString(),
      serviceName: serviceName,
      searchText: this.state.searchText
    }

    await this.loaderChange(true)

    this.setState({
      currentSearchData: reqObj
    })

    await this.loaderChange(false);

    // consoleLog("Vendor List Req Obj ::", reqObj)

    //................ For all Vendor.................

    let res = await ApiCall("getVendorsWorkingStatusTranslation", reqObj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let payload = Decoder.decode(res.data.payload);
      let vendorList = [],
        brr = [],
        scount = 0,
        totalPage = Math.ceil(payload.data.length / this.state.limit);

      payload.data.vendorList.map((vl) => {
        if (vl.isQuoteSent === 1 && vl.status != 5) {
          vl['sent'] = 1;
        } else {
          vl['sent'] = 0;
        }
        vendorList.push(vl);
      })

      vendorList.map((aa) => {
        if (aa.isQuoteSent === 1) {
          brr.push(aa.userId);
        }
        if (aa.status === 2) {
          scount++;
        }
      });
      if (scount > 0) {
        this.setState({
          isVendorAssigned: true,
        });
      }
      consoleLog("All Vendor List For offer translation task >>>>>", vendorList)

      this.setState({
        listData: vendorList,
        total_page: totalPage,
        vendorAssigned: brr,
        vendorListModalDetails: reqObj
      });
      this.openVendorListModal();
    }
    // }
  }

  getWorkingVendorList = async () => {

  }

  removeSubTask = async (i, j) => {
    if (this.state.newTaskList[i].subTask[j].taskId !== "0") {
      let reqObj = {
        taskId: this.state.newTaskList[i].subTask[j].taskId
      }
      let res = await ApiCall("removeTask", reqObj);
      if (
        res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        toast.success("Subtask removed successfully");

        let arr = this.state.newTaskList;
        let subTask = [];
        arr[i].subTask.splice(j, 1);
        // consoleLog("New task after remove::", arr);
        let brr = arr[i].subTask;
        brr.map((sub, s) => {
          subTask.push({
            id: arr[i].taskNo + "." + Number(s + 1),
            taskId: sub.taskId,
            taskNo: sub.taskNo,
            taskRequestId: sub.taskRequestId,
            subTaskNo: s + 1,
            targetLanguageId: sub.targetLanguageId,
            selectedTargetLangugae: sub.selectedTargetLangugae,
            sourceLanguageId: sub.sourceLanguageId,
            selectedSourceLanguage: sub.selectedSourceLanguage,
            selectedTask: sub.selectedTask,
            sourceLanguage: sub.sourceLanguage,
            serviceName: sub.serviceName,
            serviceId: sub.serviceId,
            targetLanguage: sub.targetLanguage,
            dueDate: SetUSAdateFormat(sub.dueDate),
            documentName: sub.documentName,
            docZipPath: sub.docZipPath,
            docDataArr: sub.docDataArr,
            wordCount: sub.wordCount,
            notes: sub.notes,
            vendors: sub.vendors,
            isDisabled: sub.taskId === "0" ? false : true,
            bidCounter: sub.bidCounter,
            vendor: sub.vendor
          })
        });

        arr[i].subTask = subTask;

        this.setState({
          newTaskList: arr
        })
      }
    } else {
      toast.success("Subtask removed successfully");
      let arr = this.state.newTaskList;
      let subTask = [];
      arr[i].subTask.splice(j, 1);
      // consoleLog("New task after remove::", arr);
      let brr = arr[i].subTask;
      brr.map((sub, s) => {
        subTask.push({
          id: arr[i].taskNo + "." + Number(s + 1),
          taskId: sub.taskId,
          taskNo: sub.taskNo,
          taskRequestId: sub.taskRequestId,
          subTaskNo: s + 1,
          targetLanguageId: sub.targetLanguageId,
          selectedTargetLangugae: sub.selectedTargetLangugae,
          sourceLanguageId: sub.sourceLanguageId,
          selectedSourceLanguage: sub.selectedSourceLanguage,
          selectedTask: sub.selectedTask,
          sourceLanguage: sub.sourceLanguage,
          serviceName: sub.serviceName,
          serviceId: sub.serviceId,
          targetLanguage: sub.targetLanguage,
          dueDate: SetUSAdateFormat(sub.dueDate),
          documentName: sub.documentName,
          docZipPath: sub.docZipPath,
          docDataArr: sub.docDataArr,
          wordCount: sub.wordCount,
          notes: sub.notes,
          vendors: sub.vendors,
          isDisabled: sub.taskId === "0" ? false : true,
          bidCounter: sub.bidCounter,
          vendor: sub.vendor
        })
      });

      arr[i].subTask = subTask;

      this.setState({
        newTaskList: arr
      })
    }
  }

  onSourceDocActionChange = (value) => {
    // let arr = this.state.allSourceDocuments;
    // arr[i].selectedAction = value;
    // this.setState({
    //   allSourceDocuments: arr
    // })

    this.setState({
      selectedModalActionArr: value
    })
  }

  showAction = (data) => {
    this.setState({
      modalActionData: data,
      modalActionArr: data.actionArr,
      selectedModalActionArr: data.selectedAction
    });
    this.openActionModal();
  }

  openActionModal = () => {
    this.setState({
      actionModal: true
    })
  }

  closeActionModal = () => {
    this.setState({
      actionModal: false
    })
  }

  actionModalNotesChange = (e) => {
    this.setState({
      modalNotes: e.target.value
    })
  }

  onSubmitActionDocument = async () => {
    if (Object.keys(this.state.modalActionData).length > 0) {
      this.setState({
        docSubmit: true
      })
      let docArr = [];
      this.state.docModalData.map((temp) => {
        docArr.push(temp.path)
      })
      let data = this.state.modalActionData;
      let selectedModalActionArr = this.state.selectedModalActionArr;
      let obj = {
        requestId: selectedModalActionArr.requestId,
        parentId: data.id,
        taskId: selectedModalActionArr.taskId,
        taskNo: selectedModalActionArr.taskNo,
        subTaskNo: selectedModalActionArr.subTaskNo.toString(),
        bidId: selectedModalActionArr.bidId,
        notes: this.state.modalNotes,
        docName: data.docName,
        action: selectedModalActionArr.value,
        serviceId: selectedModalActionArr.serviceId,
        directory: selectedModalActionArr.flag,
        vendor: selectedModalActionArr.userId,
        documentPath: docArr
      }
      consoleLog("Request Docs Api Modal", obj)
      let res = await ApiCall("sendTranslationDocRequest", obj);
      if (
        res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        this.setState({
          docSubmit: false,
          docModalData: [],
          modalNotes: ""
        })
        this.closeActionModal();
        toast.success("Request has been added successfully");
        // return history.push("/adminProjectList");
        window.scrollTo(0, 0);
        this.setState({
          isLoad: true
        })
        this.load();
      }
    }
  }

  changeTaskWordCount = (e, i) => {
    if (numberValidator(e.target.value)) {
      let number = e.target.value.toString();
      let aa = number.split("");
      if (aa[0] == 0 || aa[0] == " ") {
        let arr = this.state.taskList;
        arr[i].wordCount = aa[1];
        this.setState({
          taskList: arr
        })
      } else {
        let arr = this.state.taskList;
        arr[i].wordCount = e.target.value;
        this.setState({
          taskList: arr
        })
      }
    } else if (e.target.value === "") {
      let arr = this.state.taskList;
      arr[i].wordCount = 0;
      this.setState({
        taskList: arr
      })
    }
  }

  updateWordCount = async (data) => {
    // consoleLog("All Data::", data);
    let obj = {
      requestId: this.state.requestId,
      taskNo: data.taskNo.toString(),
      wordCount: data.wordCount.toString()
    }
    // consoleLog("Request for word count update", obj)
    let res = await ApiCall("updateWordCount", obj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      toast.success("Word Count has been updated successfully");
      this.load();
    }
  }

  unAssignVendor = async (id, taskId) => {
    let obj = {
      id: id,
      taskId: taskId
    }
    // consoleLog("Request for unassign Vendor", obj)
    let res = await ApiCall("updateAssignedVendor", obj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      toast.success("Vendor unassigned successfully");
      this.load();
    } else {
      toast.error(res.message)
    }
  }

  openAddDocumentModal = (type) => {
    this.setState({
      addDocModalFlag: type,
      addDocumentModal: true
    })
  }

  closeAddDocModal = () => {
    this.setState({
      addDocModalFlag: '',
      addDocumentModal: false,
      selectedTaskDropdown: {},
      addDocModalNotes: "",
      docModalData: [],
      documentNameAddDocModal: ""
    })
  }

  addDocumentModalNotesChange = (e) => {
    this.setState({
      addDocModalNotes: e.target.value
    })
  }

  deleteModalDocs = (i) => {
    let arr = this.state.docModalData;
    arr.splice(i, 1);
    this.setState({
      docModalData: arr,
    });
  };

  onUploadDocument = (e) => {
    this.setState({
      isDocUpload: true
    })
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    consoleLog("File Name >>>>> ", e.target.files[0].name);
    axios.post(APP_URL.IMAGE_URL, formData).then((res) => {

      if (res.data.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR) {
        let arr = this.state.docModalData;
        arr.push({
          path: res.data.data.path + res.data.data.filename,
          filename: e.target.files[0].name
        });
        this.setState({
          docModalData: arr,
          isDocUpload: false
        });
        toast.success(AlertMessage.MESSAGE.DOCUMENT.DOCUMENT_UPLOAD_SUCCESS, {
          hideProgressBar: true,
        });
      } else {
        this.setState({
          isDocUpload: false
        })
        toast.error(res.messege)
      }
    });
  };

  getAllDocTaskList = async (data) => {
    let res = await ApiCall("getDocTaskList", data);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let payload = Decoder.decode(res.data.payload);
      consoleLog("AllDocsDatsArr:", payload.data);
      let arr = [];
      payload.data.list.map((ll) => {
        arr.push({
          label: ll.listName,
          value: ll.id
        })
      })
      this.setState({
        allTaskDropdownList: arr
      })
    }
  }

  onTaskDropdownListChange = (value) => {
    this.setState({
      selectedTaskDropdown: value
    })
  }

  documentNameChange = (e) => {
    this.setState({
      documentNameAddDocModal: e.target.value,
    })
  }

  submitDocumentByUser = async () => {
    if (Object.keys(this.state.selectedTaskDropdown).length === 0) {
      toast.error("Please select action");
      return false;
    } else if (this.state.addDocModalNotes === "") {
      toast.error("Please enter notes");
      return false;
    }
    // else if (this.state.documentNameAddDocModal === "") {
    //   toast.error("Please enter document name");
    //   return false;
    // }
    else if (this.state.docModalData.length === 0) {
      toast.error("Please upload atleast one document");
      return false;
    } else {
      let data = {
        taskId: this.state.selectedTaskDropdown.value,
        directory: this.state.addDocModalFlag,
        notes: this.state.addDocModalNotes,
        documentPath: this.state.docModalData,
        docName: this.state.documentNameAddDocModal
      }
      consoleLog("Sent Document By Document Modal >>> ", data)
      let res = await ApiCall("addDocumentByUser", data);
      if (
        res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {

        this.closeAddDocModal();
        toast.success("Document Sent Successfully");
        window.scrollTo(0, 0);
        this.setState({
          isLoad: true
        })
        this.load();
      } else {
        toast.error(res.message)
      }
    }
  }


  approvePayable = async (id) => {
    let obj = {
      id: id,
      status: "1",
    };
    let res = await ApiCall("changeStatusAccountPayable", obj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      toast.success("Verified successfullly");
      let apidata = {
        requestId: this.state.requestId,
      };

      let payable = [],
        receivable = [];

      let payableRes = await ApiCall(
        "fetchPayableTranslation",
        apidata
      );
      if (
        payableRes.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        payableRes.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        let payload = Decoder.decode(payableRes.data.payload);
        // consoleLog("Payable List:::", payload.data);
        if (payload.data.details.length > 0) {
          payable = payload.data.details;
        }
      }

      let receivableRes = await ApiCall(
        "fetchReceivableTranslation",
        apidata
      );
      if (
        receivableRes.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        receivableRes.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        let payload = Decoder.decode(receivableRes.data.payload);
        // consoleLog("Receivable List:::", payload.data);
        if (payload.data.details.length > 0) {
          receivable = payload.data.details;
        }
      }

      this.setState({
        payable: payable,
        receivable: receivable,
      });
    }
  };

  showUpdateDeadline = () => {
    this.setState({
      isUpdateDeadline: true
    })
  }

  fetchNotes = async (id) => {
    let apiObj = {
      requestId: id
    };
    // consoleLog("FETCH NOTES REQ >>> ", apiObj)
    let res = await ApiCall("fetchInternalNotes", apiObj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let payload = Decoder.decode(res.data.payload);
      consoleLog("FETCH NOTES Project", payload.data);
      this.setState({
        allJobNotes: payload.data.notesData
      })
    }
  }

  changeInternalNotes = (e) => {
    this.setState({
      notesText: e.target.value
    })
  }

  addNotes = async () => {
    let errorCounter = 0;
    if (this.state.notesText.trim() === "") {
      toast.error("Please input some text");
      errorCounter++;
      return false;
    }

    if (errorCounter === 0) {
      let apiObj = {
        requestId: this.state.requestId,
        notes: this.state.notesText,
      };
      // consoleLog("ADD NOTES REQ >>> ", apiObj)
      let res = await ApiCall("addInternalNotes", apiObj);
      if (
        res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        this.fetchNotes(this.state.requestId);
        toast.success(AlertMessage.MESSAGE.NOTES.ADD_SUCCESS);
        this.setState({
          notesText: ""
        })
      }
    }
  }

  /* Chat function Start */

  fetchChatParticipants = async (id) => {
    let apiObj = {
      jobId: id
    };
    let res = await ApiCall("getChatParticipantList", apiObj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let arr = [];
      let payload = Decoder.decode(res.data.payload);

      payload.data.participants.map((pp) => {
        arr.push({
          label: pp.name + " ( " + pp.userId + " - " + this.getUserType(pp.userTypeId) + " )",
          value: pp.userId,
          userTypeId: pp.userTypeId,
          id: pp.id
        });
      })
      payload.data.staffList.map((st) => {
        arr.push({
          label: st.name + " (" + st.userId + " - Staff)",
          value: st.userId,
          userTypeId: st.userTypeId,
          id: st.id
        });
      });


      this.setState({
        chatParticipants: arr
      })
    }
  }

  getUserType = (id) => {
    let str = "";
    if (id == 3) {
      str = "Client";
    } else if (id == 4) {
      str = "vendor";
    } else {
      str = "Client Requester"
    }

    return str;
  }

  openParticipantModal = () => {
    this.setState({
      addParticipantShow: true
    })
  }

  closeParticipantModal = () => {
    this.setState({
      addParticipantShow: false
    })
  }

  onSelectChatParticipants = (value) => {
    this.setState({
      selectedChatParticipants: value
    })
  }

  changeChatGroupName = (e) => {
    this.setState({
      chatGroupName: e.target.value
    })
  }

  submitParticipants = async () => {
    let errorCounter = 0;
    if (this.state.chatGroupName === "") {
      errorCounter++;
      toast.error(AlertMessage.MESSAGE.CHAT.BLANK_GROUP_NAME);
      return false;
    } else if (this.state.selectedChatParticipants.length === 0) {
      errorCounter++;
      toast.error(AlertMessage.MESSAGE.CHAT.BLANK_PARTICIPANTS);
      return false;
    }

    if (errorCounter === 0) {
      let req = {
        groupName: this.state.chatGroupName,
        participants: this.state.selectedChatParticipants,
        jobId: this.state.jobId,
        requestId: this.state.requestId
      }

      let res = await ApiCall("submitChatJoinRequest", req);
      if (
        res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        this.fetchChatGroup(this.state.requestId)
        this.closeParticipantModal();
        toast.success("Group initiated successfully");
        this.setState({
          chatGroupName: "",
          selectedChatParticipants: []
        })
      } else {
        toast.error("Some Error Occured !!!");
      }
    }
  }
  changeChatGroup = (value) => {
    consoleLog("Change Chat group >>> ", value)
    let data = localStorage.getItem("AuthToken");
    let authUser = Decoder.decode(data);
    // consoleLog("Group Value >>> ", value.participantId);
    let temp = [];
    for (let i = 0; i < value.participantId.length; i++) {
      if (authUser.data.userid !== value.participantId[i])
        temp.push({
          label: value.participantName[i],
          value: value.participantId[i],
          actualId: value.participantActulaId[i]
        })
    }

    this.setState({
      selectedChatGroupValue: value,
      groupChatParticipant: temp
    })
    this.showChatPage(value)
  }

  fetchChatGroup = async (id) => {
    let apiObj = {
      jobId: id
    };

    let res = await ApiCall("fetchChatJoinRequest", apiObj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let payload = Decoder.decode(res.data.payload);
      consoleLog("All Chat Group", payload.data);
      let arr = [];
      if (payload.data.length > 0) {
        payload.data.map((item, i) => {
          let obj = {
            label: item.jobId + " - " + item.groupName,
            value: item
          }
          arr.push(obj)

        })
      }
      this.setState({
        chatGroup: arr
      })
    }
  }

  showChatPage = (value) => {
    return value !== "" ? <>
      <div className="prticipants-area _fl">
        <div className="row">
          <div className="col-md-6">
            <h3><span style={{ cursor: "pointer" }} onClick={() => this.openChatParticipantModal()}>{Number(value.participantId.length) - 1} Participant</span></h3>
          </div>

          <div className="col-md-6">
            <a href="javascript:void(0)" className="add-part-btn" onClick={this.openAddGroupChatParticipantModal}>
              + Add Participants
            </a>
          </div>
        </div>
      </div>
      <MainChatPage jobId={value._id} jobAlphaId={this.state.jobId} />
    </> : <></>

  }

  openChatParticipantModal = () => {
    this.setState({
      chatParticipantModal: true
    })
  }

  closeChatParticipantModal = () => {
    this.setState({
      chatParticipantModal: false
    })
  }

  fetchGroupChatParticipants = async (id) => {
    let apiObj = {
      jobId: id
    };
    let res = await ApiCall("getChatParticipantList", apiObj);
    // let res = await ApiCall("getSpecialChatParticipantList", apiObj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let arr = [];
      let payload = Decoder.decode(res.data.payload);
      consoleLog("JOB group PARTICIANT LIST >>>> ", payload.data)
      payload.data.participants.map((pp) => {
        arr.push({
          label: pp.name + " ( " + pp.userId + " - " + this.getUserType(pp.userTypeId) + " )",
          name: pp.name,
          value: pp.userId,
          userTypeId: pp.userTypeId,
          id: pp.id
        });
      })


      if (payload.data.staffList) {
        payload.data.staffList.map((st) => {
          arr.push({
            label: st.name + " (" + st.userId + " - Staff)",
            name: st.name,
            value: st.userId,
            userTypeId: st.userTypeId,
            id: st.id
          });
        });
      }


      this.setState({
        addGroupChatParticipant: arr
      })
    }
  }

  /* Chat Function end */

  viewDocNotesOpen = (val) => {
    this.setState({
      docNotes: val,
      viewDocNotes: true
    })
  }

  closeViewDocNotes = () => {
    this.setState({
      docNotes: "",
      viewDocNotes: false
    })
  }

  openServiceInfoModal = () => {
    this.setState({
      editServiceModal: true
    })
  }

  getBidStatusForVendor = (quoteSent, bidStatus) => {
    if (quoteSent === 1 && bidStatus === 0) {
      return (
        <span
          style={{
            color: "orange",
            fontSize: "12px",
          }}
        >
          Offered
        </span>
      )
    } else if (quoteSent === 1 && bidStatus === 1) {
      return (
        <span
          style={{
            color: "green",
            fontSize: "12px",
          }}
        >
          Bid Received
        </span>
      )
    } else if (quoteSent === 1 && bidStatus === 7) {
      return (
        <span
          style={{
            color: "red",
            fontSize: "12px",
          }}
        >
          Cancelled
        </span>
      )
    } else if (quoteSent === 1 && bidStatus === 5) {
      return (
        <span
          style={{
            color: "red",
            fontSize: "12px",
          }}
        >
          Unavailable
        </span>
      )
    } else {
      return (
        <span></span>
      )
    }
  }

  vendorSearchTextChange = (e) => {
    this.setState({
      searchText: e.target.value
    })
  }

  clickUpdateSummary = () => {
    this.setState({
      isUpdateProjectSummary: true
    })
  }

  closeEditProjectSummary = () => {
    this.setState({
      isUpdateProjectSummary: false
    })
  }

  changeTargetAudience = (e) => {
    this.setState({
      updateTargetAudience: e.target.value
    })
  }

  changenoteForAdmin = (e) => {
    this.setState({
      updateNotesForAdmin: e.target.value
    })
  }

  changeProjectDescription = (e) => {
    this.setState({
      updateProjectDescription: e.target.value
    })
  }

  updateProjectSummarySubmit = async () => {
    let data = {
      requestId: this.state.requestId,
      dtp: this.state.updateDTP.toString(),
      targetAudience: this.state.updateTargetAudience,
      expectedDeadline: SetDatabaseDateFormat(this.state.expectedDeadline),
      notesForAdmin: this.state.updateNotesForAdmin,
      projectDesccription: this.state.updateProjectDescription
    }

    // consoleLog("Update Translation Project >>>> ", data);
    let res = await ApiCall("updateTranslationProjectSummary", data);
    if (res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS) {
      toast.success("Translation project updated successfully");
      window.scrollTo(0, 0);
      this.setState({
        isLoad: true,
        isUpdateProjectSummary: false
      })
      this.load();
    } else {
      toast.error(res.message);
    }
  }

  dtpFormatChange = (e) => {
    this.setState({
      updateDTP: e.target.value
    })
  }

  isQuoteEnabled = (quoteApprovalStatus) => {
    // let quoteApprovalStatus = this.state.quoteApprovalStatus;
    quoteApprovalStatus = quoteApprovalStatus ? quoteApprovalStatus : "0"
    if (quoteApprovalStatus == 0 || quoteApprovalStatus == 1 || quoteApprovalStatus == 2 || quoteApprovalStatus == 7) {

      this.setState({
        isEnabled: true
      })
    } else {
      this.setState({
        isEnabled: false
      })
    }

    if (quoteApprovalStatus == 10 || quoteApprovalStatus == 11) {
      this.setState({
        isComplete: true
      })
    }
  }

  openCompleteModal = () => {
    this.setState({
      markAsCompleteModal: true
    })
  }

  closeCompleteModal = () => {
    this.setState({
      markAsCompleteModal: false
    })
  }

  completeNoteChange = (e) => {
    this.setState({
      completeNote: e.target.value
    })
  }

  completeRequest = async () => {
    if (this.state.completeNote === "") {
      toast.error(AlertMessage.MESSAGE.CLIENT_TRANSLATION.EMPTY_COMPLETE_NOTE);
      return false;
    } else {
      let obj = {
        requestId: this.state.requestId,
        note: this.state.completeNote
      }
      let res = await ApiCall("completeReq", obj);
      if (
        res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        this.setState({
          isComplete: true
        })
        this.closeCompleteModal();
        toast.success(AlertMessage.MESSAGE.CLIENT_TRANSLATION.COMPLETE_SUCCESS);
        // this.props.viewToastSuccess(AlertMessage.MESSAGE.CLIENT_TRANSLATION.EMPTY_COMPLETE_NOTE);
        return history.push("/adminProjectList");
        // this.load();
      }
    }
  }

  fetchProgressData = async (id) => {
    let obj = {
      jobId: id
    }
    consoleLog("Fetch request Progress Data >>> ", obj)
    let res = await ApiCall("fetchProgressLog", obj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let payload = Decoder.decode(res.data.payload);
      consoleLog("Progress Log Data  >>>>>", payload.data);
      if (payload.data.progressData.length > 0) {
        let arr = [],
          showData = [],
          notiArr = [];
        payload.data.progressData.map((item, i) => {
          arr.push({
            label: (
              <div>
                <span>{item.progressType}</span><br />
                <span>{SetDOBFormat(item.createDate)}&nbsp;{SetTimeFormat(item.createDate)}</span>
              </div>
            )
          })
          notiArr.push({
            body: item.progressType,
            time: moment(item.createDate).fromNow()
          })
        })
        for (let i = 0; i < arr.length; i++) {
          if (i < this.state.progressLogLimit) {
            showData.push(arr[i]);
          }
        }
        // let newNotiArr = notiArr.reverse();
        this.setState({
          progressLogData: arr,
          // allJobNotifications: newNotiArr,
          showProgressLog: showData,
          currentLog: showData.length
        })
      } else {
        this.setState({
          progressLogData: [],
          showProgressLog: [],
          // allJobNotifications: [],
          currentLog: 0
        })
      }
    }

  }

  /* Fetch job notification */
  fetchJobNotification = async (id) => {
    let obj = {
      requestId: id
    }
    let res = await ApiCall("getUserNotificationByJobid", obj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let payload = Decoder.decode(res.data.payload);
      consoleLog("Job Notification Data  >>>>>", payload.data);
      if (payload.data.length > 0) {
        let arr = [];
        arr = payload.data;
        this.setState({
          allJobNotifications: arr
        })
      } else {
        this.setState({
          allJobNotifications: []
        })
      }
    }
  }

  vendorFilterRatingChange = (value) => {
    this.setState({
      selectedVendorFilterRating: value
    })
  }

  vendorFilterStatusChange = (value) => {
    this.setState({
      selectedVendorFilterStatus: value
    })
  }

  vendorFilterNameChange = (e) => {
    this.setState({
      vendorFilterName: e.target.value
    })
  }

  vendorFilterEmailChange = (e) => {
    this.setState({
      vendorFilterEmail: e.target.value
    })
  }

  vendorFilterPhoneChange = (e) => {
    let value = e.target.value;
    value = value.replace(/[^0-9]/g, '');
    this.setState({
      vendorFilterPhone: value
    })
  }

  resetFilterVendor = async () => {
    await this.loaderChange(true);
    this.setState({
      selectedVendorFilterRating: {
        label: "All",
        value: ""
      },
      selectedVendorFilterStatus: {
        label: "All",
        value: ""
      },
      vendorFilterName: "",
      vendorFilterPhone: "",
      vendorFilterEmail: ""
    });
    await this.loaderChange(false);



    let tempObj = this.state.currentSearchData;
    tempObj["name"] = "";
    tempObj["phone"] = "";
    tempObj["email"] = "";
    tempObj["ratings"] = "";
    tempObj["status"] = "";
    consoleLog("Reset Filter request Filter Vendor >>>>>>> ", tempObj)
    this.vendorListSearchForTranslation(tempObj);
  }

  loaderChange = async (type) => {
    this.setState({
      isStateLoader: type
    })
  }

  vendorFilterApply = () => {
    let tempObj = this.state.currentSearchData;
    tempObj["name"] = this.state.vendorFilterName;
    tempObj["phone"] = this.state.vendorFilterPhone;
    tempObj["email"] = this.state.vendorFilterEmail;
    tempObj["ratings"] = this.state.selectedVendorFilterRating.value;
    tempObj["status"] = this.state.selectedVendorFilterStatus.value;

    consoleLog("Fiter Apply Vendor >>>>>>> ", tempObj);
    this.vendorListSearchForTranslation(tempObj);
  }



  vendorListSearchForTranslation = async (reqObj) => {

    //................ For all Vendor.................

    let res = await ApiCall("getVendorsWorkingStatusTranslation", reqObj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let payload = Decoder.decode(res.data.payload);
      let vendorList = [],
        brr = [],
        scount = 0,
        totalPage = Math.ceil(payload.data.length / this.state.limit);

      payload.data.vendorList.map((vl) => {
        if (vl.isQuoteSent === 1 && vl.status != 5) {
          vl['sent'] = 1;
        } else {
          vl['sent'] = 0;
        }
        vendorList.push(vl);
      })

      vendorList.map((aa) => {
        if (aa.isQuoteSent === 1) {
          brr.push(aa.userId);
        }
        if (aa.status === 2) {
          scount++;
        }
      });
      if (scount > 0) {
        this.setState({
          isVendorAssigned: true,
        });
      }
      consoleLog("All Vendor List For offer translation task >>>>>", vendorList)
      await this.loaderChange(true);
      this.setState({
        listData: vendorList,
        total_page: totalPage,
        vendorAssigned: brr,
        vendorListModalDetails: reqObj
      });
      await this.loaderChange(false);
      // this.openVendorListModal();
    }
  }

  /* Single chat initiate function start */

  onSelectChatParticipantsForSingle = (value) => {
    let arr = [];
    arr.push(value);
    this.setState({
      selectedChatParticipants: arr
    })
  }

  submitParticipantsForSingle = async () => {
    let errorCounter = 0;
    if (this.state.selectedChatParticipants.length === 0) {
      errorCounter++;
      toast.error(AlertMessage.MESSAGE.CHAT.BLANK_PARTICIPANTS);
      return false;
    }

    if (errorCounter === 0) {
      let req = {
        groupName: '',
        participants: this.state.selectedChatParticipants,
        jobId: this.state.jobId,
        requestId: this.state.requestId
      }

      let res = await ApiCall("singleJobChatInitiate", req);
      if (
        res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        this.fetchChatGroup(this.state.requestId)
        this.closeParticipantModal();
        toast.success("Chat initiated successfully");
        this.setState({
          chatGroupName: "",
          selectedChatParticipants: []
        })
      } else {
        toast.error("Some Error Occured !!!");
      }
    }
  }
  /* Single chat initiate function End */


  openAddGroupChatParticipantModal = () => {
    this.setState({
      addGrouoChatParticipantModal: true
    })
  }

  closeAddGroupChatParticipantModal = () => {
    this.setState({
      addGrouoChatParticipantModal: false
    })
  }

  removeParticipants = async (value) => {
    if (this.state.groupChatParticipant.length > 1) {
      let apiObj = {
        id: this.state.selectedChatGroupValue._id,
        participantName: value.label,
        participantId: value.value,
        participantActualId: value.actualId
      };
      // consoleLog("Remove Participants data req >>> ", apiObj)
      let res = await ApiCall("removeParticipantFromGroup", apiObj);
      if (
        res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
        res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
      ) {
        let payload = Decoder.decode(res.data.payload);
        this.fetchChatGroup(this.state.requestId);
        this.closeChatParticipantModal();
        toast.success(AlertMessage.MESSAGE.CHAT.REMOVE_PARTICIPANTS);
      }
    } else {
      toast.error(AlertMessage.MESSAGE.CHAT.NOT_REMOVE_PARTICIPANTS)
    }

  }

  submitParticipantsInGroup = async () => {

    // consoleLog("Add Group Particpat >>> ", this.state.selectedAddGroupChatParticipantValue);
    let participantId = [],
      participantName = [],
      participantActualId = [];

    if (this.state.selectedAddGroupChatParticipantValue.length > 0) {
      this.state.selectedAddGroupChatParticipantValue.map((data) => {
        participantId.push(data.value);
        participantActualId.push(data.id);
        participantName.push(data.name)
      })
    }

    let reqObj = {
      id: this.state.selectedChatGroupValue._id,
      participantName: participantName,
      participantId: participantId,
      participantActualId: participantActualId
    }

    // consoleLog("Add Participnat in exist group req >> ", reqObj)
    let res = await ApiCall("addParticipantFromGroup", reqObj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      this.closeAddGroupChatParticipantModal();
      await this.fetchChatGroup(this.state.requestId);
      toast.success(AlertMessage.MESSAGE.CHAT.ADD_PARTICIPANTS);
      await this.fetchChatParticipants(this.state.requestId);
      this.changeChatGroup(this.state.chatGroup[0].value);
      this.setState({
        selectedAddGroupChatParticipantValue: []
      })
    } else {
      toast.error(res.message)
    }


  }

  selectAddGroupParticipants = (value) => {
    this.setState({
      selectedAddGroupChatParticipantValue: value
    })
  }



  /* Fetch Email & SMS Log */

  fetchEmailSmsLog = async (id) => {
    let obj = {
      requestId: id
    }
    let res = await ApiCall("getEmailSmsLogByJobId", obj);
    if (
      res.error === ErrorCode.ERROR.ERROR.WITHOUT_ERROR &&
      res.respondcode === ErrorCode.ERROR.ERROR_CODE.SUCCESS
    ) {
      let payload = Decoder.decode(res.data.payload);
      consoleLog("Email SMS Log Data  >>>>>", payload);
      if (payload.data.log.length > 0) {
        let arr = [];
        this.setState({
          allEmailSmsLog: payload.data.log
        })
      } else {
        this.setState({
          allEmailSmsLog: []
        })
      }
    }

  }

  openViewEmailModal = async (data) => {
    await this.loaderChange(true);
    this.setState({
      viewEmailModal: true,
      viewEmailModalData: data
    });
    await this.loaderChange(false);
  }

  closeViewEmailModal = async () => {
    await this.loaderChange(true);
    this.setState({
      viewEmailModal: false,
      viewEmailModalData: {}
    });
    await this.loaderChange(false);
  }

  openEmailSendModal = async () => {

    await this.loaderChange(true);
    this.setState({
      sentEmailModal: true
    })
    await this.loaderChange(false);
  }

  closeEmailSendModal = async () => {
    await this.loaderChange(true);
    this.fetchEmailSmsLog(this.state.numJobId);
    this.setState({
      sentEmailModal: false
    })
    await this.loaderChange(false);
  }

  openSmsModal = async () => {
    await this.loaderChange(true);
    this.setState({
      sentSmsModal: true
    })
    await this.loaderChange(false);
  }

  closeSmsModal = async () => {
    await this.loaderChange(true);
    this.fetchEmailSmsLog(this.state.numJobId);
    this.setState({
      sentSmsModal: false
    })
    await this.loaderChange(false);
  }

  loaderChange = async (type) => {
    this.setState({
      loader: type
    })
  }

  render() {
    const open = Boolean(this.state.anchorEl); //used in MenuButton open
    // const open1 = Boolean(this.state.anchorEl1);

    // const { listData } = this.state;
    // consoleLog("state list on render:", this.state.listData)
    const vendor_list = this.state.listData.map((item, key) =>
      <tr>
        <td colspan="11">
          <div class="tble-row">
            <table width="100%" border="0" cellpadding="0" cellspacing="0">
              <tr>
                <td style={{ width: "5%", paddingLeft: "12px" }}>
                  {item.isQuoteSent === 1 && item.sent === 1 ? <></> :
                    <label class="custom_check2" >
                      <input
                        type="checkbox"
                        name={item.userId}
                        value={item.userId}
                        checked={
                          item.isQuoteSent === 1 ? true : false
                        }
                        onChange={(e) =>
                          this.listChecked(e, key)
                        } />
                      <span class="checkmark2"></span>
                    </label>
                  }
                </td>
                <td style={{ width: "30%" }}>
                  {item.agencyName === ""
                    ? item.fName + " " + item.lName
                    : item.fName +
                    " " +
                    item.lName +
                    " (" +
                    item.agencyName +
                    ")"}
                </td>
                <td style={{ width: "20%" }}>
                  <a href="javascript:void(0)" className="viewlink">
                    {item.email}
                  </a>
                </td>
                <td style={{ width: "15%" }}>+1 {item.mobile}</td>
                <td style={{ width: "10%" }}>
                  <img src={ImageName.IMAGE_NAME.STARYELLOW} />
                  <span className="rat_txt">{item.ratings}</span>
                </td>
                {/* <td style={{ width: "10%" }}></td> */}
                <td style={{ width: "10%" }}>
                  {/* {item.sent === 1 ? */}
                  {this.getBidStatusForVendor(item.isQuoteSent, item.status)}
                  {/* <></>} */}
                </td>
              </tr>
            </table>
          </div>
        </td>
      </tr>
    )
    return (
      <React.Fragment>
        <ToastContainer hideProgressBar theme="colored" />
        <div class="component-wrapper" hidden={!this.state.isLoad}>
          <ReactLoader />
        </div>

        <div className="component-wrapper" hidden={this.state.isLoad}>
          <div
            className="vn_frm"
            style={{ color: "grey", paddingBottom: "2%", paddingTop: "5%" }}
          >
            {" "}
            <Link to="/adminDashboard">Dashboard</Link> /{" "}
            {this.state.pathCheck == true ? (
              <Link to="/adminMainBillUnderVerification">
                Bills Under Verification
              </Link>
            ) : (
              <Link to="/adminProjectList">Translation</Link>
            )}
            / Details
          </div>
          <div className="job-details-tab jobdltste _fl sdw bid_vend_assign_pge">
            <ul className="nav nav-tabs start_tabs" style={{ justifyContent: "left" }}>
              {/* <li className="nav-item">
                {" "}
                <a
                  className="nav-link active"
                  data-toggle="tab"
                  href="#jobdetails"
                >
                  <div className="taber">
                    <figure>
                      <img src={ImageName.IMAGE_NAME.JOB_DETAILS} />
                    </figure>{" "}
                    Details
                  </div>
                </a>{" "}
              </li> */}
              <li className="nav-item">
                {" "}
                <a
                  className={this.state.showTab === "project" ? "nav-link active" : "nav-link"}
                  data-toggle="tab"
                  href="#projectdetails"
                >
                  <div className="taber">
                    <figure>
                      <img src={ImageName.IMAGE_NAME.JOB_DETAILS} />
                    </figure>{" "}
                    Project Details & Tasks
                  </div>
                </a>{" "}
              </li>
              <li className="nav-item">
                {" "}
                <a className="nav-link" data-toggle="tab" href="#clientdetails">
                  <div className="taber">
                    <figure>
                      <img src={ImageName.IMAGE_NAME.TAB_USER_ICON} />
                    </figure>
                    Client Details
                  </div>
                </a>{" "}
              </li>
              <li className="nav-item">
                {" "}
                <a className="nav-link" data-toggle="tab" href="#sendqute">
                  <div className="taber">
                    <figure>
                      <img src={ImageName.IMAGE_NAME.TABBAR} />
                    </figure>

                    {this.state.quoteId === "" ? "Sent Quote" : "View Quote"}
                  </div>
                </a>{" "}
              </li>
              <li className="nav-item p1">
                <a className="nav-link" data-toggle="tab" href="#Document">
                  <div className="taber">
                    <figure>
                      <img src={ImageName.IMAGE_NAME.DOCUMENTICON} />
                    </figure>
                    Document
                  </div>
                </a>
              </li>
              {this.state.quoteStatus === 10 ?
                <>
                  <li className="nav-item">
                    <a
                      className={this.state.showTab === "bills" ? "nav-link active" : "nav-link"}
                      data-toggle="tab"
                      href="#payablerecavable"
                    >
                      <div className="taber">
                        <figure>
                          <img src={ImageName.IMAGE_NAME.MENU.INVOICE_DOLLER} />
                        </figure>&nbsp;&nbsp;
                        Payables & Receivables
                      </div>
                    </a>
                  </li>
                </>
                :
                <></>
              }
              <li className="nav-item">
                {" "}
                <a className="nav-link" data-toggle="tab" href="#notetab">
                  <div className="taber">
                    <figure>
                      <img
                        src={ImageName.IMAGE_NAME.CHAT_ICON}
                        style={{ padding: "10px", width: "48px" }}
                      />
                    </figure>
                    Notes{" "}
                  </div>
                </a>{" "}
              </li>
              <li className="nav-item">
                {" "}
                <a className="nav-link" data-toggle="tab" href="#chattab">
                  <div className="taber">
                    <figure>
                      <img
                        src={ImageName.IMAGE_NAME.CHAT_ICON}
                        style={{ padding: "10px", width: "48px" }}
                      />
                    </figure>
                    Chat{" "}
                  </div>
                </a>{" "}
              </li>
              <li className="nav-item">
                {" "}
                <a className="nav-link" data-toggle="tab" href="#nofifications">
                  <div className="taber">
                    <figure>
                      <img src={ImageName.IMAGE_NAME.NOTIFICATION_ICON} />
                    </figure>
                    Notifications
                  </div>
                </a>{" "}
              </li>
              <li className="nav-item">
                {" "}
                <a className="nav-link" data-toggle="tab" href="#emailsms">
                  <div className="taber">
                    <figure>
                      <img src={ImageName.IMAGE_NAME.CHARSMSICON} />
                    </figure>
                    Email & SMS
                  </div>
                </a>{" "}
              </li>
            </ul>

            <div className="tab-content">
              <div className="tab-pane " id="jobdetails">
                <div className="job-section-tab">
                  <table
                    width="100%"
                    cellspacing="0"
                    cellpadding="0"
                    border="0"
                  >
                    <tbody>
                      <tr>
                        <td width="50%" align="left">
                          Project ID
                        </td>
                        <th width="50%" align="right">
                          {this.state.jobId}
                        </th>
                      </tr>

                      {/* <tr>
                        <td width="50%" align="left">
                          Appointment Type
                        </td>
                        <th width="50%" align="right">
                          <div className="f2f-b">
                            {this.state.appointmentType}
                          </div>
                        </th>
                      </tr> */}

                      {/* <tr>
                        <td width="50%" align="left">
                          Job Type
                        </td>
                        <th width="50%" align="right">
                          {this.state.jobType}
                        </th>
                      </tr> */}

                      {/* <tr>
                        <td width="50%" align="left">
                          Language
                        </td>
                        <th width="50%" align="right">
                          {this.state.language}
                        </th>
                      </tr> */}

                      <tr>
                        <td width="50%" align="left">
                          Target Audience
                        </td>
                        <th width="50%" align="right">
                          {this.state.targetAdience}
                        </th>
                      </tr>

                      <tr>
                        <td width="50%" align="left">
                          DTP format required
                        </td>
                        <th width="50%" align="right">
                          {this.state.isDtp === 1 ? "YES" : "NO"}
                        </th>
                      </tr>

                      <tr>
                        <td width="50%" align="left">
                          Expected Deadline
                        </td>
                        <th width="50%" align="right">
                          {SetDateFormat(this.state.date)}
                        </th>
                      </tr>

                      {/* <tr>
                        <td width="50%" align="left">
                          Service Location Address
                        </td>
                        <th width="50%" align="right">
                          {this.state.serviceAddress}
                        </th>
                      </tr> */}

                      {/* <tr>
                        <td width="50%" align="left">
                          Site Contant
                        </td>
                        <th width="50%" align="right">
                          {this.state.siteContant}
                        </th>
                      </tr> */}

                      <tr>
                        <td width="50%" align="left">
                          Consumer
                        </td>
                        <th width="50%" align="right">
                          {this.state.consumer}
                        </th>
                      </tr>
                      <tr>
                        <td width="50%" align="left">
                          Notes by Client
                        </td>
                        <th width="50%" align="right">
                          &nbsp;{this.state.notesByClient}
                        </th>
                      </tr>
                      {/* <tr>
                        <td width="50%" align="left">
                          <p class="notes">
                            Notes From <span>7C Lingo</span>
                          </p>
                        </td>
                        <th width="50%" align="right">
                          &nbsp;{this.state.notesBy7C}
                        </th>
                      </tr> */}
                    </tbody>
                  </table>
                  {this.state.taskList.length > 0 ? (
                    this.state.taskList.map((data, i) => (
                      <div className="c-l-s _fl text-center" key={i}>
                        <div className="row">
                          <div className="col-md-3">
                            <h4>Service type</h4>
                            <p>{data.serviceName}</p>
                          </div>
                          <div className="col-md-2">
                            <h4>Source Language</h4>
                            <p>{data.sourceLanguage}</p>
                          </div>
                          <div className="col-md-2">
                            <h4>Target Language</h4>
                            <p>{data.targetLanguage}</p>
                          </div>
                          <div className="col-md-2">
                            <h4>Document Name</h4>
                            <p>{data.documentName}</p>
                          </div>
                          <div className="col-md-2">
                            <h4>Source File</h4>
                            <p>
                              <a href="javascript:void(0)">
                                <img
                                  src={ImageName.IMAGE_NAME.DOWNLOADSHEET}
                                  onClick={() => this.onDownload(i)}
                                />
                              </a>
                            </p>
                          </div>
                          <div className="col-md-1">
                            {data.bidCounter > 0 ? (
                              <React.Fragment>
                                <h4>Bid</h4>
                                <p>
                                  <a href="javascript:void(0)">
                                    <img
                                      src={ImageName.IMAGE_NAME.EYE_BTN}
                                      onClick={() =>
                                        this.handleBidModal(data.taskId)
                                      }
                                    />
                                  </a>
                                </p>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <h4>Bid</h4>
                                <p>N/A</p>
                              </React.Fragment>
                            )}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                </div>
              </div>
              <div className={this.state.showTab === "project" ? "tab-pane  active" : "tab-pane"} id="projectdetails">
                {this.state.projectStatus == 11 ? <></> : <>
                  {this.state.isComplete ? <></> :
                    <div className="text_rite">
                      <div className="web_btn f-right">
                        <a
                          href="javascript:void(0)"
                          className="white-btn"
                          style={{ textDecoration: "none" }}
                          onClick={() => {
                            return history.push("/adminProjectList");
                          }}
                        >
                          BACK
                        </a>
                        <a
                          href="javascript:void(0)"
                          className="blue"
                          style={{ textDecoration: "none" }}
                          onClick={this.onSubmitTaskReviewList}
                        >
                          SUBMIT
                        </a>
                      </div>
                    </div>
                  }
                </>}

                <div className="document-list-wrap _fl sdw margin-top-30">
                  <div className="_fl ">
                    <h3 style={{ color: "#00a0df" }}>Project Summary &nbsp;&nbsp;
                      {this.state.projectStatus == 11 ? <></> : <>
                        {this.state.isEnabled ? <>
                          {!this.state.isUpdateProjectSummary ? <>
                            {this.state.isComplete ? <></> :
                              <FiEdit size={20} onClick={() => this.clickUpdateSummary()} style={{ cursor: "pointer" }}
                                data-bs-toggle="tooltip" data-bs-placement="left" title="Edit" />}
                          </> : <></>}
                        </> : <></>}
                      </>}
                    </h3>

                    <div className="c-l-s _fl table-listing-app">
                      <div className="row">
                        <div className="col-md-6">
                          <h4>Project ID</h4>
                          <p>{this.state.jobId}</p>
                        </div>
                        <div className="col-md-6">
                          <div className="web-form-bx">
                            <div className="frm-label">
                              Is DTP formatting Required? *
                            </div>
                            {this.state.isUpdateProjectSummary ? <>
                              <div className="check-field">
                                <label className="checkbox_btn">
                                  <input
                                    type="radio"
                                    name="radio"
                                    value="1"
                                    checked={this.state.updateDTP == 1 ? true : false}
                                    onChange={this.dtpFormatChange}
                                  />
                                  <span className="checkmark3"></span> Yes
                                </label>
                              </div>
                              <div className="check-field">
                                <label className="checkbox_btn">
                                  <input
                                    type="radio"
                                    name="radio"
                                    value="0"
                                    checked={this.state.updateDTP == 0 ? true : false}
                                    onChange={this.dtpFormatChange}
                                  />
                                  <span className="checkmark3"></span> No
                                </label>
                              </div> </> : <>
                              {this.state.isDtp == 1 ? "YES" : "NO"}
                            </>
                            }
                          </div>
                        </div>
                        <div className="col-md-6">
                          <h4>Target Audience (specific dialects, demographics, or populations)</h4>
                          {this.state.isUpdateProjectSummary ? <>
                            <textarea
                              value={this.state.updateTargetAudience}
                              name="mainTask_notes"
                              placeholder="Enter"
                              className="in-textarea msg min"
                              onChange={(e) =>
                                this.changeTargetAudience(e)
                              }
                              style={{ display: "inline-block", width: "85%" }}>

                            </textarea>
                          </> : <>
                            <p>{this.state.targetAdience}</p>
                          </>}
                        </div>
                        <div className="col-md-6">
                          <h4>Notes For <span style={{ color: "#00a0df" }}>7C Admin</span> </h4>
                          {this.state.isUpdateProjectSummary ? <>
                            <textarea
                              value={this.state.updateNotesForAdmin}
                              name="Project_Admin_notes"
                              placeholder="Enter"
                              className="in-textarea msg min"
                              onChange={(e) =>
                                this.changenoteForAdmin(e)
                              }
                              style={{ display: "inline-block", width: "85%" }}>

                            </textarea>
                          </> : <>
                            <p>{this.state.noteForAdmin === "" ? "N/A" : this.state.noteForAdmin}</p>
                          </>}
                        </div>
                        <div className="col-md-6">
                          <h4>Description </h4>
                          {this.state.isUpdateProjectSummary ? <>
                            <textarea
                              value={this.state.updateProjectDescription}
                              name="Project_Admin_Description"
                              placeholder="Enter"
                              className="in-textarea msg min"
                              onChange={(e) =>
                                this.changeProjectDescription(e)
                              }
                              style={{ display: "inline-block", width: "85%" }}>

                            </textarea>
                          </> : <>
                            <p>{this.state.notesByClient === "" ? "N/A" : this.state.notesByClient}</p>
                          </>}
                        </div>
                        <div className="col-md-6">
                          <h4>Expected Deadline</h4>
                          {!this.state.isUpdateProjectSummary ?
                            <p>
                              <span>{SetDateFormat(this.state.date)}</span>&nbsp;&nbsp;
                              {/* <span><FiEdit onClick={() => this.showUpdateDeadline()} /></span> */}
                            </p> : <>
                              <div className="form-input-fields">
                                <div
                                  className="input-group"
                                  style={{
                                    width: "40%",
                                    borderRadius: "9px",
                                    height: "43px",
                                    border: "1px solid #ced4da",
                                    boxShadow:
                                      "0px 0px 4px 0px rgb(0 0 0 / 28%)",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "80%",
                                      padding: "8px",
                                    }}
                                  >
                                    <span>
                                      {this.state.expectedDeadline}
                                    </span>
                                  </div>
                                  <div style={{ width: "20%" }}>
                                    <a style={{ float: "right" }}>
                                      <DatePicker
                                        selected={new Date(this.state.expectedDeadline)}
                                        dropdownMode="select"
                                        showMonthDropdown
                                        showYearDropdown
                                        adjustDateOnChange
                                        minDate={new Date()}
                                        onChange={(date) => this.expectedDeadlineChange(date)}
                                        customInput={<Schedule />}
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </>
                          }
                        </div>
                        {this.state.projectStatus == 10 ? <>
                          <div className="col-md-6">
                            <h4>Completion Notes </h4>
                            <p>{this.state.completeNotes}</p>
                          </div>
                        </> : <></>}
                        {this.state.projectStatus == 11 ? <>
                          {this.state.projectCancelReason !== "" ?
                            <div className="col-md-12">
                              <textarea className="cancelTranslationReason" value={"Cancel Reason : " + this.state.projectCancelReason} disabled={true} ></textarea>
                            </div> : <></>}
                        </> : <></>}
                        {this.state.projectStatus == 7 ? <>
                          {this.state.quoteRejectReason !== "" ? <>
                            <div className="col-md-12">
                              <textarea className="cancelTranslationReason" value={"Quote Decline Reason : " + this.state.quoteRejectReason + "\n" + "Quote Decline Description : " + this.state.quoteRejectDescription} disabled={true} ></textarea>
                            </div>
                          </> : <></>}
                        </> : <></>}
                        <div className="col-md-12"><h4>
                          {this.state.isUpdateProjectSummary ? <>
                            <div className="text_rite">
                              <div className="web_btn f-right">
                                <a
                                  href="javascript:void(0)"
                                  className="white-btn"
                                  style={{ textDecoration: "none" }}
                                  onClick={() => this.closeEditProjectSummary()}
                                >
                                  BACK
                                </a>
                                <a
                                  href="javascript:void(0)"
                                  className="blue"
                                  style={{ textDecoration: "none" }}
                                  onClick={() => this.updateProjectSummarySubmit()}
                                >
                                  UPDATE
                                </a>
                              </div>
                            </div>
                          </> : <>

                          </>}
                        </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="document-list-wrap _fl sdw margin-top-30">
                  <div className="_fl doc-wrap">
                    <h3>Service Info </h3>
                    <div className="job-section-tab table-listing-app">
                      {/* <span data-toggle="tooltip" className="edt_srv_inf" title="Edit Service Info">
                        <FiEdit size={30} onClick={() => this.openServiceInfoModal()} />
                      </span>
                      {this.state.editServiceModal ? <EditServiceInfoModal open={this.state.editServiceModal}/> : <></>} */}
                      {this.state.taskList.length > 0 ? (
                        this.state.taskList.map((data, i) => (
                          <div className="c-l-s _fl text-center" key={i}>
                            <div className="row">
                              <div className="col-md-2">
                                <h4>Service type</h4>
                                <p>{data.serviceName}</p>
                              </div>
                              <div className="col-md-2">
                                <h4>Source Language</h4>
                                <p>{data.sourceLanguage}</p>
                              </div>
                              <div className="col-md-2">
                                <h4>Target Language</h4>
                                <p>
                                  <span
                                    data-toggle="tooltip"
                                    title={data.targetLanguage}>
                                    {textTruncate(data.targetLanguage, 15)}
                                  </span>
                                </p>
                              </div>
                              <div className="col-md-2">
                                <h4>Document Name</h4>
                                <p>
                                  <span
                                    data-toggle="tooltip"
                                    title={data.documentName}>
                                    {textTruncate(data.documentName, 15)}
                                  </span>
                                </p>
                              </div>
                              <div className="col-md-2">
                                <h4>Document Notes</h4>
                                <p>
                                  <span
                                    data-toggle="tooltip"
                                    title={data.notes}>
                                    {textTruncate(data.notes, 15)}
                                  </span>
                                  &nbsp;
                                  <span style={{ display: "inline-block" }}>
                                    <AiOutlineEye
                                      data-bs-toggle="tooltip" data-bs-placement="left" title="View Notes"
                                      size={20}
                                      onClick={() => { this.viewDocNotesOpen(data.notes) }}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </span>
                                </p>
                              </div>
                              <div className="col-md-2">
                                <h4>Source File</h4>
                                <p>
                                  <a href="javascript:void(0)">
                                    <img
                                      data-bs-toggle="tooltip" data-bs-placement="left" title="Download Source Document"
                                      src={ImageName.IMAGE_NAME.DOWNLOADSHEET}
                                      onClick={() => this.onDownload(i)}
                                    />
                                  </a>
                                </p>
                              </div>
                              {/* <div className="col-md-1 mrtp_40">
                                <button
                                  className="adtst_btn"
                                  onClick={() => this.updateWordCount(data)}
                                >
                                  Update
                                </button>
                              </div> */}
                              {/* <div className="col-md-1">
                                {data.bidCounter > 0 ? <React.Fragment>
                                  <h4>Bid</h4>
                                  <p><a href="javascript:void(0)"><img src={ImageName.IMAGE_NAME.EYE_BTN} onClick={() => this.handleBidModal(data.taskId)} /></a></p>
                                </React.Fragment> : <React.Fragment>
                                  <h4>Bid</h4>
                                  <p>N/A</p>
                                </React.Fragment>
                                }
                              </div> */}
                            </div>
                          </div>
                        ))
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}
                    </div>
                  </div>
                </div>

                <div className="document-list-wrap _fl sdw margin-top-30">
                  <div className="_fl doc-wrap">
                    {this.state.quoteStatus > 7 ?
                      <h3>Task[s] Assigned</h3> :
                      <h3>Get Bids</h3>
                    }
                    {this.state.newTaskList.length > 0 ? (
                      <React.Fragment>
                        {this.state.newTaskList.map((data, i) => (
                          <React.Fragment>
                            <div className="table-listing-app tblt">
                              <div className="table-listing-app proj_tbl">
                                <p className="serv_typ">
                                  Service Type: {data.serviceName}
                                </p>
                                {/* <div className="table-responsive"> */}
                                <div>
                                  <div className="add_tsts">
                                    <table
                                      width="100%"
                                      cellspacing="0"
                                      cellpadding="0"
                                      border="0"
                                    >
                                      <tbody>
                                        <tr>
                                          {/* <th style={{ width: "5%" }}>
                                            <label className="custom_check2">
                                              <input
                                                type="checkbox"
                                                onClick={this.checked}
                                              />
                                              <span className="checkmark2"></span>
                                            </label>
                                          </th> */}
                                          <th style={{ width: "5%" }}>
                                            Task ID
                                          </th>
                                          <th style={{ width: "15%" }}>Task</th>
                                          <th style={{ width: "15%" }}>
                                            Source Lang
                                          </th>
                                          <th style={{ width: "15%" }}>
                                            Target Lang
                                          </th>
                                          <th style={{ width: "15%" }}>
                                            Due Date
                                          </th>
                                          <th style={{ width: "10%" }}>
                                            Notes
                                          </th>
                                          <th style={{ width: "10%" }}>
                                            Vendor[s]
                                          </th>
                                        </tr>
                                      </tbody>
                                      <tbody>
                                        <tr>
                                          {/* <td style={{ width: "5%" }}>
                                            <label className="custom_check2">
                                              <input type="checkbox" />

                                              <span className="checkmark2"></span>
                                            </label>
                                          </td> */}
                                          <td style={{ width: "5%" }}>
                                            {i + 1}
                                          </td>
                                          <td style={{ width: "15%" }}>
                                            <div className="bts-drop">
                                              <SelectBox
                                                optionData={
                                                  this.state
                                                    .allTranslationService
                                                }
                                                value={data.selectedTask}
                                                onSelectChange={(value) => this.translationMainServiceTypeChange(value, i)}
                                                isDisabled={data.isDisabled}
                                              ></SelectBox>
                                            </div>
                                          </td>
                                          <td style={{ width: "15%" }}>
                                            <div className="bts-drop">
                                              <SelectBox
                                                optionData={
                                                  this.state.allLanguageArr
                                                }
                                                value={
                                                  data.selectedSourceLanguage
                                                }
                                                onSelectChange={(value) => this.sourceLanguageChangeMainTask(value, i)}
                                                isDisabled={data.isDisabled}
                                              ></SelectBox>
                                            </div>
                                          </td>
                                          <td style={{ width: "15%" }}>
                                            <div className="bts-drop">
                                              <SelectBox
                                                optionData={
                                                  this.state.allLanguageArr
                                                }
                                                value={
                                                  data.selectedTargetLangugae
                                                }
                                                onSelectChange={(value) => this.targetLanguageChangeMainTask(value, i)}
                                                isDisabled={data.isDisabled}
                                              ></SelectBox>
                                              {/* <MultiSelectBox
                                                optionData={
                                                  this.state.allLanguageArr
                                                }
                                                value={
                                                  data.selectedTargetLangugae
                                                }
                                                // onSelectChange={(value) => this.targetLanguageChangeTask(value)}
                                                isDisabled={true}
                                              ></MultiSelectBox> */}
                                            </div>
                                          </td>
                                          <td style={{ width: "15%" }}>
                                            <div className="form-input-fields">
                                              <div
                                                className="input-group"
                                                style={{
                                                  width: "100%",
                                                  borderRadius: "9px",
                                                  height: "43px",
                                                  border: "1px solid #ced4da",
                                                  boxShadow:
                                                    "0px 0px 4px 0px rgb(0 0 0 / 28%)",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    width: "80%",
                                                    padding: "8px",
                                                  }}
                                                >
                                                  <span>
                                                    {data.dueDate}
                                                  </span>
                                                </div>
                                                <div style={{ width: "20%" }}>
                                                  <a style={{ float: "right" }}>
                                                    <DatePicker
                                                      dropdownMode="select"
                                                      showMonthDropdown
                                                      showYearDropdown
                                                      adjustDateOnChange
                                                      minDate={new Date()}
                                                      onChange={(date) => this.dueDateChangeTask(date, i)}
                                                      customInput={<Schedule />}
                                                      disabled={data.isDisabled}
                                                    />
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                          <td style={{ width: "15%" }}>
                                            <div className="web-form-bx" style={{ width: "100%" }}>
                                              {/* <input
                                                type="text"
                                                value={data.notes}
                                                name="mainTask_notes"
                                                placeholder="Enter"
                                                className="in-field2"
                                                onChange={(e) =>
                                                  this.noteChangeMainTask(e, i)
                                                }
                                                readOnly={data.isDisabled}
                                                style={{ display: "inline-block", width: "85%" }}
                                              /> */}
                                              <textarea
                                                value={data.notes}
                                                name="mainTask_notes"
                                                placeholder="Enter"
                                                className="in-field2"
                                                onChange={(e) =>
                                                  this.noteChangeMainTask(e, i)
                                                }
                                                readOnly={data.isDisabled}
                                                style={{ display: "inline-block", width: "85%" }}>

                                              </textarea>
                                              <span style={{ display: "inline-block" }}>
                                                <AiOutlineEye
                                                  data-bs-toggle="tooltip" data-bs-placement="left" title="View"
                                                  size={20}
                                                  onClick={() => { this.viewDocNotesOpen(data.notes) }}
                                                  style={{ cursor: "pointer" }}
                                                />
                                              </span>
                                            </div>
                                          </td>
                                          <td style={{ width: "10%" }}>
                                            <div className="web-form-bx">
                                              {data.vendor === "" ? <>
                                                {data.bidCounter > 0 ?
                                                  <>
                                                    <a href="javascript:void(0)" onClick={() => { this.onOpenVendorListByTask(data.serviceId, data.sourceLanguageId, data.sourceLanguage, data.targetLanguageId, data.targetLanguage, data.taskId, data.taskNo, data.subTaskNo, data.serviceName, data.wordCount) }}>
                                                      <img
                                                        data-bs-toggle="tooltip" data-bs-placement="left" title="Vendor List"
                                                        src={
                                                          ImageName.IMAGE_NAME.EYE_BTN
                                                        }
                                                      />
                                                    </a>
                                                    <a href="javascript:void(0)" data-bs-toggle="tooltip" data-bs-placement="left" title="Vendor Bids" style={{ paddingLeft: "5%" }} onClick={() => this.handleBidModal(data.taskId)} ><CgProfile size={25} />

                                                    </a>
                                                    <div className="circle-button_bid">{data.bidCounter}</div>
                                                  </>
                                                  : <a href="javascript:void(0)" onClick={() => { this.onOpenVendorListByTask(data.serviceId, data.sourceLanguageId, data.sourceLanguage, data.targetLanguageId, data.targetLanguage, data.taskId, data.taskNo, data.subTaskNo, data.serviceName, data.wordCount) }}>
                                                    <img
                                                      data-bs-toggle="tooltip" data-bs-placement="left" title="Vendor List"
                                                      src={
                                                        ImageName.IMAGE_NAME.EYE_BTN
                                                      }
                                                    />
                                                  </a>
                                                }</> : <>
                                                <span
                                                  data-toggle="tooltip"
                                                  title={data.vendor}>
                                                  {/* {textTruncate(data.vendor, 10)} */}
                                                  {data.vendor}
                                                  {data.status < 9 ?
                                                    <img
                                                      src={ImageName.IMAGE_NAME.CANCEL_BTN}
                                                      style={{ cursor: "pointer", paddingLeft: "10px" }}
                                                      alt=""
                                                      data-toggle="tooltip"
                                                      title="Unassign"
                                                      onClick={() => this.unAssignVendor(data.id, data.taskId)}
                                                    /> : <></>
                                                  }
                                                </span>
                                              </>}
                                            </div>
                                          </td>
                                        </tr>
                                        {data.subTask.length > 0 ? (
                                          <React.Fragment>
                                            {data.subTask.map((sub, j) => (
                                              <React.Fragment key={j}>
                                                <tr>
                                                  {/* <td style={{ width: "5%" }}>
                                                    <label className="custom_check2">
                                                      <input type="checkbox" />

                                                      <span className="checkmark2"></span>
                                                    </label>
                                                  </td> */}
                                                  <td style={{ width: "5%" }}>
                                                    {(i + 1) + "." + (j + 1)}
                                                  </td>
                                                  <td style={{ width: "15%" }}>
                                                    <div className="bts-drop">
                                                      <SelectBox
                                                        optionData={
                                                          this.state
                                                            .allTranslationService
                                                        }
                                                        value={
                                                          sub.selectedTask
                                                        }
                                                        onSelectChange={(
                                                          value
                                                        ) =>
                                                          this.translationServiceTypeChange(
                                                            value, i,
                                                            j
                                                          )
                                                        }
                                                        isDisabled={sub.isDisabled}
                                                      ></SelectBox>
                                                    </div>
                                                  </td>
                                                  <td style={{ width: "15%" }}>
                                                    <div className="bts-drop">
                                                      <SelectBox
                                                        optionData={
                                                          this.state
                                                            .allLanguageArr
                                                        }
                                                        value={
                                                          sub.selectedSourceLanguage
                                                        }
                                                        onSelectChange={(
                                                          value
                                                        ) =>
                                                          this.sourceLanguageChangeTask(
                                                            value, i,
                                                            j
                                                          )
                                                        }
                                                        isDisabled={sub.isDisabled}
                                                      ></SelectBox>
                                                    </div>
                                                  </td>
                                                  <td style={{ width: "15%" }}>
                                                    <div className="bts-drop">
                                                      <SelectBox
                                                        optionData={
                                                          this.state
                                                            .allLanguageArr
                                                        }
                                                        value={
                                                          sub.selectedTargetLangugae
                                                        }
                                                        onSelectChange={(
                                                          value
                                                        ) =>
                                                          this.targetLanguageChangeTask(
                                                            value, i,
                                                            j
                                                          )
                                                        }
                                                        isDisabled={sub.isDisabled}
                                                      ></SelectBox>
                                                      {/* <MultiSelectBox
                                                        optionData={
                                                          this.state
                                                            .allLanguageArr
                                                        }
                                                        value={
                                                          sub.selectedTargetLangugae
                                                        }
                                                        onSelectChange={(
                                                          value
                                                        ) =>
                                                          this.targetLanguageChangeTask(
                                                            value, i,
                                                            j
                                                          )
                                                        }
                                                        isDisabled={sub.isDisabled}
                                                      ></MultiSelectBox> */}
                                                    </div>
                                                  </td>
                                                  <td style={{ width: "15%" }}>
                                                    <div className="form-input-fields">
                                                      <div
                                                        className="input-group"
                                                        style={{
                                                          width: "100%",
                                                          borderRadius: "9px",
                                                          height: "43px",
                                                          border:
                                                            "1px solid #ced4da",
                                                          boxShadow:
                                                            "0px 0px 4px 0px rgb(0 0 0 / 28%)",
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            width: "80%",
                                                            padding: "8px",
                                                          }}
                                                        >
                                                          <span>
                                                            {
                                                              sub.dueDate
                                                            }
                                                          </span>
                                                        </div>
                                                        <div
                                                          style={{
                                                            width: "20%",
                                                          }}
                                                        >
                                                          <a
                                                            style={{
                                                              float: "right",
                                                            }}
                                                          >
                                                            <DatePicker
                                                              dropdownMode="select"
                                                              showMonthDropdown
                                                              showYearDropdown
                                                              adjustDateOnChange
                                                              minDate={
                                                                new Date()
                                                              }
                                                              onChange={(
                                                                date
                                                              ) =>
                                                                this.dueDateChangeSubTask(
                                                                  date, i,
                                                                  j
                                                                )
                                                              }
                                                              customInput={
                                                                <Schedule />
                                                              }
                                                              disabled={sub.isDisabled}
                                                            />
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td style={{ width: "10%" }}>
                                                    <div className="web-form-bx" style={{ width: "100%" }}>
                                                      {/* <input
                                                        type="text"
                                                        value={sub.notes}
                                                        name="subTask_notes"
                                                        placeholder="Enter"
                                                        className="in-field2"
                                                        onChange={(e) =>
                                                          this.noteChangeTask(
                                                            e, i,
                                                            j
                                                          )
                                                        }
                                                        readOnly={sub.isDisabled}
                                                        style={{ display: "inline-block", width: "85%" }}
                                                      /> */}
                                                      <textarea
                                                        value={sub.notes}
                                                        name="subTask_notes"
                                                        placeholder="Enter"
                                                        className="in-field2"
                                                        onChange={(e) =>
                                                          this.noteChangeTask(
                                                            e, i,
                                                            j
                                                          )
                                                        }
                                                        readOnly={sub.isDisabled}
                                                        style={{ display: "inline-block", width: "85%" }}>

                                                      </textarea>
                                                      <span style={{ display: "inline-block" }}>
                                                        <AiOutlineEye
                                                          data-bs-toggle="tooltip" data-bs-placement="left" title="View"
                                                          size={20}
                                                          onClick={() => { this.viewDocNotesOpen(sub.notes) }}
                                                          style={{ cursor: "pointer" }}
                                                        />
                                                      </span>
                                                    </div>
                                                  </td>
                                                  <td style={{ width: "5%" }}>
                                                    <div className="web-form-bx">
                                                      {sub.vendor === "" ? <>
                                                        {sub.taskId === "0" ? <>
                                                          <img
                                                            src={ImageName.IMAGE_NAME.CANCEL_BTN}
                                                            data-bs-toggle="tooltip" data-bs-placement="left" title="Unassign"
                                                            style={{ cursor: "pointer" }}
                                                            alt=""
                                                            onClick={() => this.removeSubTask(i, j)}
                                                          />
                                                        </> : <>
                                                          {sub.bidCounter > 0 ? <>
                                                            <a href="javascript:void(0)">
                                                              <img
                                                                data-bs-toggle="tooltip" data-bs-placement="left" title="Vendor List"
                                                                src={
                                                                  ImageName.IMAGE_NAME
                                                                    .EYE_BTN
                                                                }
                                                                onClick={() => { this.onOpenVendorListByTask(sub.serviceId, sub.sourceLanguageId, sub.sourceLanguage, sub.targetLanguageId, sub.targetLanguage, sub.taskId, sub.taskNo, sub.subTaskNo, data.serviceName, data.wordCount) }}
                                                              />
                                                              <a href="javascript:void(0)" data-bs-toggle="tooltip" data-bs-placement="left" title="Vendor Bids" style={{ paddingLeft: "5%" }} onClick={() => this.handleBidModalForSubTask(sub.taskId, i, j)} ><CgProfile size={25} /></a>
                                                              <div className="circle-button_bid">{sub.bidCounter}</div>
                                                            </a>
                                                          </> :
                                                            <a href="javascript:void(0)">
                                                              <img
                                                                data-bs-toggle="tooltip" data-bs-placement="left" title="Vendor List"
                                                                src={
                                                                  ImageName.IMAGE_NAME
                                                                    .EYE_BTN
                                                                }
                                                                onClick={() => { this.onOpenVendorListByTask(sub.serviceId, sub.sourceLanguageId, sub.sourceLanguage, sub.targetLanguageId, sub.targetLanguage, sub.taskId, sub.taskNo, sub.subTaskNo, data.serviceName, data.wordCount) }}
                                                              />
                                                              <img
                                                                src={ImageName.IMAGE_NAME.CANCEL_BTN}
                                                                style={{ cursor: "pointer", paddingLeft: "5%" }}
                                                                alt=""
                                                                data-bs-toggle="tooltip" data-bs-placement="left" title="Unassign"
                                                                onClick={() => this.removeSubTask(i, j)}
                                                              />
                                                            </a>
                                                          }
                                                        </>
                                                        }
                                                      </> : <>
                                                        {/* <span
                                                          data-toggle="tooltip"
                                                          title={sub.vendor}>
                                                          {textTruncate(sub.vendor, 20)}
                                                        </span>
                                                        <img
                                                          src={ImageName.IMAGE_NAME.CANCEL_BTN}
                                                          style={{ cursor: "pointer" }}
                                                          alt=""
                                                        // onClick={() => this.removeSubTask(i, j)}
                                                        /> */}
                                                        {/* {consoleLog("SUBBBB>>>>", sub)} */}
                                                        <span
                                                          data-toggle="tooltip"
                                                          title={sub.vendor}>
                                                          {/* {textTruncate(sub.vendor, 10)} */}
                                                          {sub.vendor}
                                                          {sub.status < 9 ?
                                                            <img
                                                              src={ImageName.IMAGE_NAME.CANCEL_BTN}
                                                              style={{ cursor: "pointer", paddingLeft: "10px" }}
                                                              alt=""
                                                              data-toggle="tooltip"
                                                              title="Unassign"
                                                              onClick={() => this.unAssignVendor(sub.mid, sub.taskId)}
                                                            /> : <></>
                                                          }
                                                        </span>
                                                      </>}
                                                    </div>
                                                  </td>
                                                </tr>
                                              </React.Fragment>
                                            ))}
                                          </React.Fragment>
                                        ) : (
                                          <React.Fragment></React.Fragment>
                                        )}
                                      </tbody>
                                    </table>
                                    {this.state.projectStatus == 11 ? <></> : <>
                                      {this.state.isComplete ? <></> :
                                        <div className="text_rite">
                                          <button
                                            className="adtst_btn"
                                            onClick={() => this.addSubtask(i)}
                                          >
                                            Add Task
                                          </button>
                                        </div>
                                      }
                                    </>}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </div>
                </div>

                {this.state.isComplete ? <></> :
                  <div className="_button-style m30 _fl text-center">
                    <a
                      href="javascript:void(0)"
                      className="white-btn"
                      style={{ textDecoration: "none" }}
                      onClick={() => {
                        return history.push("/adminProjectList");
                      }}
                    >
                      Back
                    </a>
                    {this.state.projectStatus == 11 ? <></> : <>
                      <a
                        href="javascript:void(0)"
                        className="blue-btn"
                        style={{ textDecoration: "none", color: "#fff" }}
                        onClick={this.onSubmitTaskReviewList}
                      >
                        Submit
                      </a>
                    </>}
                  </div>
                }

                {this.state.progressLogData.length > 0 ? <>
                  <Stepper style={{ overflowY: "auto" }}
                    steps={this.state.progressLogData}
                    activeStep={this.state.progressLogData.length}
                    styleConfig={{ completedBgColor: "#00a0df" }}
                  />
                </> : <></>}

                {/* <div className="button-container-steps">
                  <button >Back</button>
                  <button >Next</button>
                </div> */}
              </div>

              <div className="tab-pane" id="sendqute">
                <div className="job-section-tab">
                  <div className="view-quote-details-wrap">
                    <h3>View Quote</h3>
                    {this.state.quoteId === "" ? <React.Fragment></React.Fragment> :
                      <p>
                        <span>Quote ID</span>
                        {this.state.quoteId}
                      </p>
                    }
                    <p>
                      <span>Target Audience</span> {this.state.targetAdience}
                    </p>
                    <p>
                      <span>Expected Deadline</span>{" "}
                      {SetDateFormat(this.state.expectedDeadline)}
                    </p>
                    <p>
                      <span>Notes from Client</span>
                      {this.state.notesFromClient}
                    </p>
                    <p>
                      <span>Industry Type</span> {this.state.clientIndustryType}
                    </p>
                  </div>
                  <div className="translation_table">
                    {this.state.taskData.length > 0 ? (
                      <React.Fragment>
                        {this.state.taskData.map((item, i) => (
                          <div className="task_table">
                            <div class="tsk-col _fl m30 p-20">
                              <h3>
                                Task {i + 1} : {item.service}
                              </h3>
                              <ul>
                                <li
                                  data-toggle="tooltip"
                                  title={item.sourceLanguage}
                                >
                                  {/* {consoleLog("Item detail::", item.sourceLanguage)} */}
                                  <a
                                    href="javascript:void(0)"
                                    style={{ textDecoration: "none" }}
                                  >
                                    {textTruncate(item.sourceLanguage, 10)}
                                  </a>
                                </li>
                                <li
                                  data-toggle="tooltip"
                                  title={item.targetLanguage}
                                >
                                  <a
                                    href="javascript:void(0)"
                                    style={{ textDecoration: "none" }}
                                  >
                                    {textTruncate(item.targetLanguage, 10)}
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div class="tsk-tabl">
                              <table
                                width="100%"
                                border="0"
                                cellpadding="0"
                                cellspacing="0"
                              >
                                <tr>
                                  <th
                                    style={{
                                      width: "20%",
                                      textAlign: "center",
                                    }}
                                  >
                                    ITEM(S)
                                  </th>
                                  <th
                                    style={{
                                      width: "20%",
                                      textAlign: "center",
                                    }}
                                  >
                                    QUANTITY
                                  </th>
                                  <th
                                    style={{
                                      width: "20%",
                                      textAlign: "center",
                                    }}
                                  >
                                    UNIT COST
                                  </th>
                                  <th
                                    style={{
                                      width: "20%",
                                      textAlign: "center",
                                    }}
                                  >
                                    Description
                                  </th>
                                  <th
                                    style={{
                                      width: "20%",
                                      textAlign: "center",
                                    }}
                                  >
                                    RATE
                                  </th>
                                </tr>

                                <tr>
                                  <td style={{ textAlign: "left" }}>
                                    Approx Word Count
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <div
                                      className="input-group"
                                      style={{ justifyContent: "center" }}
                                    >
                                      <input
                                        type="text"
                                        value={item.wordCountQty}
                                        name=""
                                        placeholder=""
                                        class="in-field4 unit-cost"
                                        onChange={(e) => { this.handleWordQty(e, i) }}
                                        readOnly={!this.state.isEnabled}
                                      />
                                    </div>
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <div
                                      className="input-group"
                                      style={{ justifyContent: "center" }}
                                    >
                                      <div class="input-group-prepend">
                                        <span
                                          class="input-group-text"
                                          id="basic-addon1"
                                        >
                                          $
                                        </span>
                                      </div>
                                      <input
                                        type="number"
                                        value={item.wordCountCost}
                                        name=""
                                        placeholder=""
                                        class="in-field4 unit-cost"
                                        onChange={(e) => { this.handleWordCost(e, i) }}
                                        readOnly={!this.state.isEnabled}
                                      />
                                    </div>
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <div
                                    // className="input-group"
                                    // style={{ justifyContent: "center" }}
                                    >
                                      <textarea
                                        value={item.wordCountDesc}
                                        name="description_quote"
                                        placeholder=""
                                        class="in-textarea"
                                        style={{ resize: "none" }}
                                        onChange={(e) => { this.handleWordCountDescChange(e, i) }}
                                        readOnly={!this.state.isEnabled}
                                      ></textarea>
                                    </div>
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    $ {item.wordCountRate}
                                  </td>
                                </tr>

                                {this.state.isDtp === 1 ? (
                                  <tr>
                                    <td style={{ textAlign: "left" }}>
                                      Approx DTP Hours
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      <div
                                        className="input-group"
                                        style={{ justifyContent: "center" }}
                                      >
                                        <input
                                          type="text"
                                          value={item.dtpQty}
                                          name=""
                                          placeholder=""
                                          class="in-field4 unit-cost"
                                          onChange={(e) => { this.handleDtpQty(e, i) }}
                                          readOnly={!this.state.isEnabled}
                                        />
                                      </div>
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      <div
                                        className="input-group"
                                        style={{ justifyContent: "center" }}
                                      >
                                        <div class="input-group-prepend">
                                          <span
                                            class="input-group-text"
                                            id="basic-addon1"
                                          >
                                            $
                                          </span>
                                        </div>
                                        <input
                                          type="number"
                                          value={item.dtpCost}
                                          name=""
                                          placeholder=""
                                          class="in-field4 unit-cost"
                                          onChange={(e) => { this.handleDtpUnitCost(e, i) }}
                                          readOnly={!this.state.isEnabled}
                                        />
                                      </div>
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      <div
                                        className="input-group"
                                        style={{ justifyContent: "center" }}
                                      >
                                        <textarea
                                          value={item.dtpDesc}
                                          name="description_quote"
                                          placeholder=""
                                          class="in-textarea"
                                          style={{ resize: "none" }}
                                          onChange={(e) => { this.handleDtpDescChange(e, i) }}
                                          readOnly={!this.state.isEnabled}
                                        ></textarea>
                                      </div>
                                    </td>
                                    <td style={{ textAlign: "center" }}>
                                      $ {item.dtpRate}
                                    </td>
                                  </tr>
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )}
                                <tr>
                                  <td style={{ textAlign: "left" }}>
                                    Rush Fee (If Applicable)
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <div
                                      className="input-group"
                                      style={{ justifyContent: "center" }}
                                    >
                                      <input
                                        type="text"
                                        value={item.rushFeeUnit}
                                        name=""
                                        placeholder=""
                                        class="in-field4 unit-cost"
                                        onChange={(e) => { this.handleRushUnit(e, i) }}
                                        readOnly={!this.state.isEnabled}
                                      />
                                    </div>
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <div
                                      className="input-group"
                                      style={{ justifyContent: "center" }}
                                    >
                                      <div class="input-group-prepend">
                                        <span
                                          class="input-group-text"
                                          id="basic-addon1"
                                        >
                                          $
                                        </span>
                                      </div>
                                      <input
                                        type="number"
                                        value={item.rushFeeCost}
                                        name=""
                                        placeholder=""
                                        class="in-field4 unit-cost"
                                        onChange={(e) => { this.handleRushUnitCost(e, i) }}
                                        readOnly={!this.state.isEnabled}
                                      />
                                    </div>
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    <div
                                      className="input-group"
                                      style={{ justifyContent: "center" }}
                                    >
                                      <textarea
                                        value={item.rushFeeDesc}
                                        name="description_quote"
                                        placeholder=""
                                        class="in-textarea"
                                        style={{ resize: "none" }}
                                        onChange={(e) => { this.handleRushFeeDescChange(e, i) }}
                                        readOnly={!this.state.isEnabled}
                                      ></textarea>
                                    </div>
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    $ {item.rushFeeRate}
                                  </td>
                                </tr>

                                <tr>
                                  <td style={{ textAlign: "left" }}>
                                    SUB COST
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    &nbsp;
                                  </td>
                                  <td style={{ textAlign: "center" }}></td>
                                  <td style={{ textAlign: "center" }}></td>
                                  <td
                                    className="text-ttt"
                                    style={{ textAlign: "center" }}
                                  >
                                    $ {item.SubCost}
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        ))}
                      </React.Fragment>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                    {this.state.isEnabled ?
                      <div class="addmore_service text-right">
                        <a href="javascript:void(0)">
                          <div
                            onClick={this.addParticularField}
                            style={{ marginTop: "8px" }}
                          >
                            Add Additional Field
                          </div>
                        </a>
                      </div> : <></>}
                    {this.state.additionalTranslationCharges.length > 0 ? (
                      <React.Fragment>
                        <div class="tsk-col _fl p-20">
                          <h3>Additional Fees</h3>
                        </div>
                        <table style={{ border: "none", width: "100%" }}>
                          {this.state.additionalTranslationCharges.map(
                            (item, key) => (
                              <tr>
                                <td width="50%" align="left">
                                  <input
                                    className="inputfield flr"
                                    type="text"
                                    placeholder="Particulars"
                                    value={item.title}
                                    readOnly={!this.state.isEnabled}
                                    onChange={(e) =>
                                      this.onParticularChange(e, key)
                                    }
                                  />
                                </td>
                                <td width="50%" align="right">
                                  <div className="row">
                                    <div className="col-md-5"></div>
                                    <div className="col-md-6">
                                      <div
                                        className="input-group"
                                        style={{ justifyContent: "end" }}
                                      >
                                        <div class="input-group-prepend">
                                          <span
                                            class="input-group-text dollar"
                                            id="basic-addon1"
                                          >
                                            $
                                          </span>
                                        </div>
                                        <input
                                          className="inputfield flr"
                                          type="number"
                                          placeholder="Enter Amount"
                                          value={item.amt}
                                          style={{ width: "75%" }}
                                          onChange={(e) =>
                                            this.onParticularAmountChange(e, key)
                                          }
                                          readOnly={!this.state.isEnabled}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-1 delete-btn">
                                      {this.state.isEnabled ?
                                        <img
                                          src={ImageName.IMAGE_NAME.TRASH_BTN}
                                          onClick={() =>
                                            this.onDeleteParticulars(key)
                                          }
                                          style={{ cursor: "pointer" }}
                                        /> : <></>
                                      }
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )
                          )}
                        </table>
                      </React.Fragment>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                    <table style={{ border: "none", width: "100%" }}>
                      <tr>
                        <td style={{ width: "25%" }}>
                          <span
                            style={{
                              color: "#6aa881",
                              fontSize: "22px",
                              fontWeight: "800",
                            }}
                          >
                            Total Amount :{" "}
                          </span>
                        </td>
                        <td style={{ width: "25%" }}></td>
                        <td style={{ width: "25%" }}></td>
                        <td style={{ width: "25%" }}>
                          <span
                            style={{
                              color: "#6aa881",
                              fontSize: "22px",
                              fontWeight: "800",
                            }}
                          >
                            $ {this.state.totalAmount}
                          </span>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div className="_button-style m30 _fl text-center">
                    <a
                      href="javascript:void(0)"
                      className="white-btn"
                      style={{ textDecoration: "none" }}
                      onClick={() => {
                        return history.push("/adminProjectList");
                      }}
                    >
                      Back
                    </a>
                    {this.state.projectStatus == 11 ? <></> : <>
                      {
                        this.state.isEnabled ?
                          <a
                            href="javascript:void(0)"
                            className="blue-btn"
                            style={{ textDecoration: "none", color: "#fff" }}
                            onClick={this.OnSendQuote}
                          >
                            Send Quote
                          </a> : <></>
                      }
                      {this.state.quoteApprovalStatus == 0 || this.state.quoteApprovalStatus == 7 ?
                        <a
                          href="javascript:void(0)"
                          className="blue-btn"
                          style={{ textDecoration: "none", color: "#fff" }}
                          onClick={this.OnSaveQuote}
                        >
                          Save Quote
                        </a> : <></>
                      }
                    </>}
                    {this.state.quoteStatus === 1 ? <>
                      <button type="button" class="btn btn-success" onClick={this.acceptClient}>Accept</button> <span style={{ color: "gray" }}>(On behalf of client)</span>
                    </> : <></>
                    }
                  </div>
                </div>
              </div>

              {/* ..............For Documents Tab.............. */}

              <div className="tab-pane" id="Document">
                {/* ..................................new docs source accordian,,,,,,,,,,,,,,, */}

                <div className="document-list-wrap _fl sdw margin-top-30">
                  <div className="_fl doc-wrap">
                    <h3>Source Documents</h3>

                    <div className="table-listing-app tblt">
                      {/* <div style={{ float: "right", marginBottom: "10px" }}>
                        <button type="button" class="btn btn-secondary">
                          Download
                        </button>
                      </div> */}
                      <div className="table-responsive">
                        <table
                          width="100%"
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                        >
                          <tr>
                            <th style={{ width: "10%" }}>Filename</th>
                            <th style={{ width: "10%" }}>Service Type</th>
                            <th style={{ width: "5%" }}>Task ID</th>
                            <th style={{ width: "10%" }}>Last Modified on</th>
                            <th style={{ width: "20%" }}>Notes</th>
                            {/* <th style={{ width: "20%" }}>Status</th>
                            <th style={{ width: "20%" }}>Action</th> */}
                            {/* <th style={{ width: "10%" }}>Action</th> */}
                          </tr>
                          {this.state.allSourceDocuments.length > 0 ? (
                            <React.Fragment>
                              {this.state.allSourceDocuments.map((data, i) => (
                                <React.Fragment key={i}>
                                  <tr>
                                    <td>
                                      <p onClick={() => this.onDownloadSourceDocs(i)} style={{ cursor: "pointer", textDecoration: "underline" }}
                                        data-bs-toggle="tooltip" data-bs-placement="left" title="Download">{data.docName}</p>
                                    </td>
                                    <td><p>
                                      {data.serviceName}
                                      <br />
                                      {data.languages}
                                    </p></td>
                                    <td>
                                      <p>
                                        {data.taskNo + "." + data.subTaskNo}
                                      </p>
                                    </td>
                                    <td>
                                      <p>
                                        {SetDateFormat(data.lastUpdated) +
                                          " | " +
                                          SetTimeFormat(data.lastUpdated)}
                                      </p>
                                    </td>
                                    <td>
                                      <p className="tr_notes_p">
                                        <span
                                          className="tr_nl"
                                          data-toggle="tooltip"
                                          title={data.notes}>
                                          Instructed : {textTruncate(data.notes, 30)}
                                        </span>
                                        {data.userNotes === "" ? <></> :
                                          <span
                                            className="tr_nl"
                                            data-toggle="tooltip"
                                            title={data.notes}>
                                            User :  {textTruncate(data.userNotes, 45)}
                                          </span>
                                        }
                                      </p>
                                      <a href="javascript:void(0)">
                                        <img
                                          data-bs-toggle="tooltip" data-bs-placement="left" title="View"
                                          src={ImageName.IMAGE_NAME.EYE_BTN}
                                          onClick={() => { this.viewDocNotesOpen(data.notes) }}
                                        />
                                      </a>
                                    </td>
                                    {/* <td>
                                      {data.action === "" ?
                                        <>N/A</> :
                                        <p>
                                          {data.action}
                                        </p>}
                                    </td>
                                    <td>
                                      <FiEdit size={25} style={{ cursor: "pointer" }} onClick={() => this.showAction(data)} />
                                    </td> */}
                                  </tr>
                                </React.Fragment>
                              ))}
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <tr style={{ textAlign: "center" }}>
                                <td colSpan="7">
                                  <center style={{ fontSize: "20px" }}>
                                    No data found !!!
                                  </center>
                                </td>
                              </tr>
                            </React.Fragment>
                          )}
                        </table>
                      </div>
                      {/* <div style={{ float: "right", marginTop: "10px" }}>
                        <button
                          type="button"
                          class="btn btn-secondary"
                          style={{ marginRight: "10px" }}
                          onClick={this.onSubmitSourceDoc}
                        >
                          Submit
                        </button>
                        <button
                          type="button"
                          class="btn btn-secondary"
                          onClick={this.onResetSourceDoc}
                        >
                          Reset
                        </button>
                      </div> */}
                    </div>
                  </div>
                </div>

                {/* ..................................Translated Document Section ........................ */}

                <div className="document-list-wrap _fl sdw margin-top-30">
                  <div className="_fl doc-wrap">
                    <h3>Translated Documents </h3>



                    <div className="table-listing-app tblt">
                      {/* {this.state.isComplete ? <></> :
                        <div style={{ float: "right", marginBottom: "10px" }}>
                          <div className="upload-profile">
                            <label
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Add Document"
                              style={{ cursor: "pointer" }}
                            >
                              <button type="button" class="btn btn-secondary"
                                onClick={() => this.openAddDocumentModal('vendor')}>
                                Add
                              </button>
                            </label>
                          </div>
                        </div>
                      } */}
                      <div className="table-responsive">
                        <table
                          width="100%"
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                        >
                          <tr>
                            <th style={{ width: "10%" }}>Filename</th>
                            <th style={{ width: "5%" }}>Document Version</th>
                            <th style={{ width: "10%" }}>Service Type</th>
                            <th style={{ width: "5%" }}>Task ID</th>
                            <th style={{ width: "10%" }}>Last Modified on</th>
                            <th style={{ width: "20%" }}>Notes</th>
                            <th style={{ width: "20%" }}>Status</th>
                            <th style={{ width: "20%" }}>Action</th>
                          </tr>
                          {this.state.allTranslatedList.length > 0 ? (
                            <React.Fragment>
                              {this.state.allTranslatedList.map(
                                (data, i) => (
                                  <React.Fragment key={i}>
                                    <tr>
                                      <td>
                                        <p onClick={() => this.onDownloadTranslatedDocs(i)} style={{ cursor: "pointer", textDecoration: "underline" }}
                                          data-bs-toggle="tooltip" data-bs-placement="left" title="Download">{data.docName}</p>
                                      </td>
                                      <td><p>V{data.revisionNo}</p></td>
                                      <td><p>{data.serviceName}<br />
                                        {data.languages}</p></td>
                                      <td>
                                        <p>
                                          {data.taskNo + "." + data.subTaskNo}
                                        </p>
                                      </td>
                                      <td>
                                        <p>
                                          {SetDateFormat(data.lastUpdated) +
                                            " | " +
                                            SetTimeFormat(data.lastUpdated)}
                                        </p>
                                      </td>
                                      <td>
                                        <p className="tr_notes_p">
                                          <span
                                            className="tr_nl"
                                            data-toggle="tooltip"
                                            title={data.notes}>
                                            Instructed : {textTruncate(data.notes, 30)}
                                          </span>
                                          {data.userNotes === "" ? <></> :
                                            <span
                                              className="tr_nl"
                                              data-toggle="tooltip"
                                              title={data.notes}>
                                              User :  {textTruncate(data.userNotes, 45)}
                                            </span>
                                          }
                                        </p>
                                        <a href="javascript:void(0)">
                                          <img
                                            data-bs-toggle="tooltip" data-bs-placement="left" title="View"
                                            src={ImageName.IMAGE_NAME.EYE_BTN}
                                            onClick={() => { this.viewDocNotesOpen(data.notes) }}
                                          />
                                        </a>
                                      </td>
                                      <td>
                                        {data.action === "" ?
                                          <>N/A</> :
                                          <p>
                                            {data.action}
                                          </p>}
                                      </td>
                                      <td>
                                        {data.isAction == 1 ? <>
                                          {this.state.isComplete ? <></> :
                                            <FiEdit size={25} style={{ cursor: "pointer" }} onClick={() => this.showAction(data)} data-bs-toggle="tooltip" data-bs-placement="left" title="Action" />
                                          } </> : <></>
                                        }
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                )
                              )}
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <tr style={{ textAlign: "center" }}>
                                <td colSpan="8">
                                  <center style={{ fontSize: "20px" }}>
                                    No data found !!!
                                  </center>
                                </td>
                              </tr>
                            </React.Fragment>
                          )}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                {/* ..................................new vendor review accordian,,,,,,,,,,,,,,, */}

                <div className="document-list-wrap _fl sdw margin-top-30">
                  <div className="_fl doc-wrap">
                    <h3>Vendor Review Documents </h3>



                    <div className="table-listing-app tblt">
                      {/* {this.state.isComplete ? <></> :
                        <div style={{ float: "right", marginBottom: "10px" }}>
                          <div className="upload-profile">
                            <label
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Add Document"
                              style={{ cursor: "pointer" }}
                            >
                              <button type="button" class="btn btn-secondary"
                                onClick={() => this.openAddDocumentModal('vendor')}>
                                Add
                              </button>
                            </label>
                          </div>
                        </div>
                      } */}
                      <div className="table-responsive">
                        <table
                          width="100%"
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                        >
                          <tr>
                            <th style={{ width: "10%" }}>Filename</th>
                            <th style={{ width: "5%" }}>Document Version</th>
                            <th style={{ width: "10%" }}>Service Type</th>
                            <th style={{ width: "5%" }}>Task ID</th>
                            <th style={{ width: "10%" }}>Last Modified on</th>
                            <th style={{ width: "20%" }}>Notes</th>
                            <th style={{ width: "20%" }}>Status</th>
                            <th style={{ width: "20%" }}>Action</th>
                          </tr>
                          {this.state.allVendorReviewdocs.length > 0 ? (
                            <React.Fragment>
                              {this.state.allVendorReviewdocs.map(
                                (data, i) => (
                                  <React.Fragment key={i}>
                                    <tr>
                                      <td>
                                        <p onClick={() => this.onDownloadVendorReviewDocs(i)} style={{ cursor: "pointer", textDecoration: "underline" }}
                                          data-bs-toggle="tooltip" data-bs-placement="left" title="Download">{data.docName}</p>
                                      </td>
                                      <td><p>V{data.revisionNo}</p></td>
                                      <td><p>{data.serviceName}<br />
                                        {data.languages}</p></td>
                                      <td>
                                        <p>
                                          {data.taskNo + "." + data.subTaskNo}
                                        </p>
                                      </td>
                                      <td>
                                        <p>
                                          {SetDateFormat(data.lastUpdated) +
                                            " | " +
                                            SetTimeFormat(data.lastUpdated)}
                                        </p>
                                      </td>
                                      <td>
                                        <p className="tr_notes_p">
                                          <span
                                            className="tr_nl"
                                            data-toggle="tooltip"
                                            title={data.notes}>
                                            Instructed : {textTruncate(data.notes, 30)}
                                          </span>
                                          {data.userNotes === "" ? <></> :
                                            <span
                                              className="tr_nl"
                                              data-toggle="tooltip"
                                              title={data.notes}>
                                              User :  {textTruncate(data.userNotes, 45)}
                                            </span>
                                          }
                                        </p>
                                        <a href="javascript:void(0)">
                                          <img
                                            data-bs-toggle="tooltip" data-bs-placement="left" title="View"
                                            src={ImageName.IMAGE_NAME.EYE_BTN}
                                            onClick={() => { this.viewDocNotesOpen(data.notes) }}
                                          />
                                        </a>
                                      </td>
                                      <td>
                                        {data.action === "" ?
                                          <>N/A</> :
                                          <p>
                                            {data.action}
                                          </p>}
                                      </td>
                                      <td>
                                        {data.isAction == 1 ? <>
                                          {this.state.isComplete ? <></> :
                                            <FiEdit size={25} style={{ cursor: "pointer" }} onClick={() => this.showAction(data)} data-bs-toggle="tooltip" data-bs-placement="left" title="Action" />
                                          } </> : <></>
                                        }
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                )
                              )}
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <tr style={{ textAlign: "center" }}>
                                <td colSpan="8">
                                  <center style={{ fontSize: "20px" }}>
                                    No data found !!!
                                  </center>
                                </td>
                              </tr>
                            </React.Fragment>
                          )}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                {/* ..................................new client review accordian,,,,,,,,,,,,,,, */}

                <div className="document-list-wrap _fl sdw margin-top-30">
                  <div className="_fl doc-wrap">
                    <h3>Client Review Documents</h3>

                    <div className="table-listing-app tblt">
                      {/* {this.state.isComplete ? <></> :
                        <div style={{ float: "right", marginBottom: "10px" }}>

                          <div className="upload-profile">
                            <label
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Add Document"
                              style={{ cursor: "pointer" }}
                            >
                              <button type="button" class="btn btn-secondary"
                                onClick={() => this.openAddDocumentModal('client')}>
                                Add
                              </button>
                            </label>
                          </div>
                        </div>
                      } */}
                      <div className="table-responsive">
                        <table
                          width="100%"
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                        >
                          <tr>
                            <th style={{ width: "10%" }}>Filename</th>
                            <th style={{ width: "5%" }}>Document Version</th>
                            <th style={{ width: "10%" }}>Service Type</th>
                            <th style={{ width: "5%" }}>Task ID</th>
                            <th style={{ width: "10%" }}>Last Modified on</th>
                            <th style={{ width: "20%" }}>Notes</th>
                            <th style={{ width: "20%" }}>Status</th>
                            <th style={{ width: "20%" }}>Action</th>
                          </tr>
                          {this.state.allClientReviewDocs.length > 0 ? (
                            <React.Fragment>
                              {this.state.allClientReviewDocs.map(
                                (data, i) => (
                                  <React.Fragment key={i}>
                                    <tr>
                                      <td>
                                        <p onClick={() => this.onDownloadClientReviewDocs(i)} style={{ cursor: "pointer", textDecoration: "underline" }}
                                          data-bs-toggle="tooltip" data-bs-placement="left" title="Download">{data.docName}</p>
                                      </td>
                                      <td><p>V{data.revisionNo}</p></td>
                                      <td><p>{data.serviceName}<br />
                                        {data.languages}</p></td>
                                      <td>
                                        <p>
                                          {data.taskNo + "." + data.subTaskNo}
                                        </p>
                                      </td>
                                      <td>
                                        <p>
                                          {SetDateFormat(data.lastUpdated) +
                                            " | " +
                                            SetTimeFormat(data.lastUpdated)}
                                        </p>
                                      </td>
                                      <td>
                                        <p className="tr_notes_p">
                                          <span
                                            className="tr_nl"
                                            data-toggle="tooltip"
                                            title={data.notes}>
                                            Instructed : {textTruncate(data.notes, 30)}
                                          </span>
                                          {data.userNotes === "" ? <></> :
                                            <span
                                              className="tr_nl"
                                              data-toggle="tooltip"
                                              title={data.notes}>
                                              User :  {textTruncate(data.userNotes, 45)}
                                            </span>
                                          }
                                        </p>
                                        <a href="javascript:void(0)">
                                          <img
                                            data-bs-toggle="tooltip" data-bs-placement="left" title="View"
                                            src={ImageName.IMAGE_NAME.EYE_BTN}
                                            onClick={() => { this.viewDocNotesOpen(data.notes) }}
                                          />
                                        </a>
                                      </td>
                                      <td>
                                        {data.action === "" ?
                                          <>N/A</> :
                                          <p>
                                            {data.action}
                                          </p>}
                                      </td>
                                      <td>
                                        {data.isAction == 1 ? <>
                                          {this.state.isComplete ? <></> :
                                            <FiEdit size={25} style={{ cursor: "pointer" }} onClick={() => this.showAction(data)} data-bs-toggle="tooltip" data-bs-placement="left" title="Action" />
                                          } </> : <></>
                                        }
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                )
                              )}
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <tr style={{ textAlign: "center" }}>
                                <td colSpan="8">
                                  <center style={{ fontSize: "20px" }}>
                                    No data found !!!
                                  </center>
                                </td>
                              </tr>
                            </React.Fragment>
                          )}
                        </table>
                      </div>
                      {/* <div style={{ float: "right", marginTop: "10px" }}>
                        <button
                          type="button"
                          class="btn btn-secondary"
                          style={{ marginRight: "10px" }}
                          onClick={this.onSubmitClientReviewDoc}
                        >
                          Submit
                        </button>
                        <button
                          type="button"
                          class="btn btn-secondary"
                          onClick={this.onResetClientReviewDoc}
                        >
                          Reset
                        </button>
                      </div> */}
                    </div>
                  </div>
                </div>

                {/* ..................................new final doc accordian,,,,,,,,,,,,,,, */}

                <div className="document-list-wrap _fl sdw margin-top-30">
                  <div className="_fl doc-wrap">
                    <h3>Final Documents</h3>

                    <div className="table-listing-app tblt">
                      {/* {this.state.isComplete ? <></> :
                        <div style={{ float: "right", marginBottom: "10px" }}>
                          <div className="upload-profile">
                            <label
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Add Document"
                              style={{ cursor: "pointer" }}
                            >
                              <button type="button" class="btn btn-secondary"
                                onClick={() => this.openAddDocumentModal('final')}>
                                Add
                              </button>
                            </label>
                          </div>
                        </div>
                      } */}
                      <div className="table-responsive">
                        <table
                          width="100%"
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                        >
                          <tr>
                            <th style={{ width: "15%" }}>Filename</th>
                            <th style={{ width: "5%" }}>Document Version</th>
                            <th style={{ width: "20%" }}>Service Type</th>
                            <th style={{ width: "20%" }}>Task ID</th>
                            <th style={{ width: "20%" }}>Last Modified on</th>
                            <th style={{ width: "20%" }}>Notes</th>
                          </tr>
                          {this.state.allFinalDocs.length > 0 ? (
                            <React.Fragment>
                              {this.state.allFinalDocs.map((data, i) => (
                                <React.Fragment key={i}>
                                  <tr>
                                    <td>
                                      <p onClick={() => this.onDownloadFinalDocs(i)} style={{ cursor: "pointer", textDecoration: "underline" }}
                                        data-bs-toggle="tooltip" data-bs-placement="left" title="Download">{data.docName}</p>
                                    </td>
                                    <td><p>V{data.revisionNo}</p></td>
                                    <td><p>{data.serviceName}<br />
                                      {data.languages}</p></td>
                                    <td>
                                      <p>
                                        {data.taskNo + "." + data.subTaskNo}
                                      </p>
                                    </td>
                                    <td>
                                      <p>
                                        {SetDateFormat(data.lastUpdated) +
                                          " | " +
                                          SetTimeFormat(data.lastUpdated)}
                                      </p>
                                    </td>
                                    <td>
                                      <p className="tr_notes_p">
                                        <span
                                          className="tr_nl"
                                          data-toggle="tooltip"
                                          title={data.notes}>
                                          Instructed : {textTruncate(data.notes, 30)}
                                        </span>
                                        {data.userNotes === "" ? <></> :
                                          <span
                                            className="tr_nl"
                                            data-toggle="tooltip"
                                            title={data.notes}>
                                            User :  {textTruncate(data.userNotes, 45)}
                                          </span>
                                        }
                                      </p>
                                      <a href="javascript:void(0)">
                                        <img
                                          data-bs-toggle="tooltip" data-bs-placement="left" title="View"
                                          src={ImageName.IMAGE_NAME.EYE_BTN}
                                          onClick={() => { this.viewDocNotesOpen(data.notes) }}
                                        />
                                      </a>
                                    </td>
                                  </tr>
                                </React.Fragment>
                              ))}
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <tr style={{ textAlign: "center" }}>
                                <td colSpan="7">
                                  <center style={{ fontSize: "20px" }}>
                                    No data found !!!
                                  </center>
                                </td>
                              </tr>
                            </React.Fragment>
                          )}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="_button-style m30 _fl text-center">
                  {this.state.quoteStatus < 10 ? <>
                    {this.state.allFinalDocs.length > 0 ?
                      <a
                        href="javascript:void(0)"
                        className="blue-btn"
                        style={{ textDecoration: "none", color: "#fff" }}
                        onClick={() => this.openCompleteModal()}
                      >
                        Mark as complete
                      </a> : <></>
                    }</> : <></>
                  }
                </div>
              </div>



              <div className={this.state.showTab === "bills" ? "tab-pane  active" : "tab-pane"} id="payablerecavable">
                <div className="acc_payable">
                  <h3>Account Payables </h3>
                  <div className="table-listing-app tblt">
                    <div className="table-responsive">
                      <table
                        width="100%"
                        border="0"
                        cellpadding="0"
                        cellspacing="0"
                      >
                        <tr>
                          <th style={{ width: "15%" }}>Project ID</th>
                          {/* <th style={{ width: "20%" }}>Service</th> */}
                          <th style={{ width: "15%" }}>Vendor[S]</th>
                          <th style={{ width: "15%" }}>Description</th>
                          <th style={{ width: "10%" }}>Total Amount $</th>
                          <th style={{ width: "15%" }}>Status</th>
                          <th style={{ width: "10%" }}>Action</th>
                        </tr>
                        {this.state.payable.length > 0 ? (
                          <React.Fragment>
                            {this.state.payable.map((data) => (
                              <React.Fragment>
                                <tr>
                                  <td>{this.state.jobId}</td>
                                  {/* <td>
                                    {data.serviceName}
                                  </td> */}
                                  <td>{data.name}</td>
                                  <td>
                                    {data.description}
                                  </td>
                                  <td>${data.totalAmount}</td>
                                  <td>
                                    {data.status === 0 ? (
                                      <React.Fragment>
                                        <span className="progress-btn yellow">
                                          Pending
                                        </span>
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment>
                                        {data.status === 1 ? (
                                          <React.Fragment>
                                            <span className="progress-btn sky" >
                                              Verified
                                            </span>
                                          </React.Fragment>
                                        ) : data.status === 2 ? (
                                          <React.Fragment>
                                            <span className="progress-btn blue">
                                              Invoice Created
                                            </span>
                                          </React.Fragment>
                                        ) : (
                                          <React.Fragment>
                                            <span className="progress-btn red">
                                              Rejected
                                            </span>
                                          </React.Fragment>
                                        )}
                                      </React.Fragment>
                                    )}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {data.status === 0 ? (
                                      <React.Fragment>
                                        <a href="javascript:void(0)">
                                          <img
                                            data-bs-toggle="tooltip" data-bs-placement="left" title="Approve"
                                            src={
                                              ImageName.IMAGE_NAME.BLUE_TICK_JPG
                                            }
                                            style={{
                                              width: "20px",
                                              height: "20px",
                                            }}
                                            onClick={() =>
                                              this.approvePayable(data.id)
                                            }
                                          />
                                        </a>
                                        {/* <a href="javascript:void(0)"><img src={ImageName.IMAGE_NAME.CROSS_BTN} style={{ width: "20px", height: "20px" }} /></a> */}
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment></React.Fragment>
                                    )}
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                          </React.Fragment>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
                <div className="acc_payable">
                  <h3>Account Receivables</h3>
                  <div className="table-listing-app tblt">
                    <div className="table-responsive">
                      <table
                        width="100%"
                        border="0"
                        cellpadding="0"
                        cellspacing="0"
                      >
                        <tr>
                          <th style={{ width: "15%" }}>Project ID</th>
                          {/* <th style={{ width: "20%" }}>Service</th> */}
                          <th style={{ width: "15%" }}>Client[S]</th>
                          <th style={{ width: "15%" }}>Description</th>
                          <th style={{ width: "10%" }}>Total Amount $</th>
                          <th style={{ width: "15%" }}>Status</th>
                          <th style={{ width: "10%" }}>Action</th>
                        </tr>
                        {this.state.receivable.length > 0 ? (
                          <React.Fragment>
                            {this.state.receivable.map((data) => (
                              <React.Fragment>
                                <tr>
                                  <td>{this.state.jobId}</td>
                                  {/* <td>
                                    {data.serviceName}
                                  </td> */}
                                  <td>{data.name}</td>
                                  <td>
                                    {data.description}
                                  </td>
                                  <td>${data.totalAmount}</td>
                                  <td>
                                    {data.status === 0 ? (
                                      <React.Fragment>
                                        <span className="progress-btn yellow">
                                          Pending
                                        </span>
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment>
                                        {data.status === 1 ? (
                                          <React.Fragment>
                                            <span className="progress-btn sky" >
                                              Verified
                                            </span>
                                          </React.Fragment>
                                        ) : data.status === 2 ? (
                                          <React.Fragment>
                                            <span className="progress-btn blue">
                                              Invoice Created
                                            </span>
                                          </React.Fragment>
                                        ) : (
                                          <React.Fragment>
                                            <span className="progress-btn red">
                                              Rejected
                                            </span>
                                          </React.Fragment>
                                        )}
                                      </React.Fragment>
                                    )}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {data.status === 0 ? (
                                      <React.Fragment>
                                        <a href="javascript:void(0)">
                                          <img
                                            data-bs-toggle="tooltip" data-bs-placement="left" title="Approve"
                                            src={
                                              ImageName.IMAGE_NAME.BLUE_TICK_JPG
                                            }
                                            style={{
                                              width: "20px",
                                              height: "20px",
                                            }}
                                            onClick={() =>
                                              this.approvePayable(data.id)
                                            }
                                          />
                                        </a>
                                        {/* <a href="javascript:void(0)"><img src={ImageName.IMAGE_NAME.CROSS_BTN} style={{ width: "20px", height: "20px" }} /></a> */}
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment></React.Fragment>
                                    )}
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                          </React.Fragment>
                        ) : (
                          <React.Fragment></React.Fragment>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tab-pane" id="clientdetails">
                <div className="job-section-tab">
                  <h3>Client Information</h3>
                  <div className="c-l-s _fl">
                    <div className="row">
                      <div className="col-md-4">
                        <h4>First Name</h4>
                        <p>{this.state.clientFirstName}</p>
                      </div>
                      <div className="col-md-4">
                        <h4>Last Name</h4>
                        <p>{this.state.clientLastName}</p>
                      </div>
                      <div className="col-md-4">
                        <h4>Client</h4>
                        <p data-bs-toggle="tooltip" data-bs-placement="left" title="View">
                          <span className="blue" style={{ cursor: "pointer" }}>{this.state.clientName}</span>
                        </p>
                      </div>
                      <div className="col-md-4">
                        <h4>Email ID</h4>
                        <p>{this.state.clientEmailId}</p>
                      </div>
                      <div className="col-md-4">
                        <h4>Phone Number</h4>
                        <p>
                          +{this.state.countryCode} {this.state.clientPhone}
                        </p>
                      </div>
                      <div className="col-md-4">
                        <h4>Industry Type</h4>
                        <p>{this.state.clientIndustryType}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* .................. Note Tab View .......................  */}
              <div className="tab-pane" id="notetab">
                <div className="job-section-tab">
                  <div className="chat-app-information-component">
                    <div className="chat-app-component">
                      {this.state.allJobNotes.map((data, i) =>
                        <div className="participants-chat-row" key={i}>
                          <figure>
                            <img src={ImageName.IMAGE_NAME.LOGO1} />
                          </figure>
                          <div className="chatinfo-bx">
                            <p className="cht-user">
                              <b>{data.userName}</b> <span>{SetUSAdateFormatV2(data.createDate) + " " + SetTimeFormat(data.createDate)}</span>
                            </p>
                            <p className="chat-text">
                              {data.notes}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>


                  </div>
                  <div className="chat-app-type-area">
                    <input
                      type="text"
                      value={this.state.notesText}
                      name="notes"
                      className="chat-field-bx"
                      onChange={(e) => this.changeInternalNotes(e)}
                    />
                    <button type="submit" className="send-btn-app" onClick={() => this.addNotes()}>
                      send
                    </button>
                  </div>
                </div>
              </div>

              <div className="tab-pane" id="chattab">
                <div className="job-section-tab">
                  <div className="prticipants-area _fl">


                    <div className="row">
                      <div className="col-md-6">
                        {/* <h3>3 Participants</h3> */}
                        <button type="button" className="new_chat_btn" onClick={() => this.openParticipantModal()}>
                          <img src={ImageName.IMAGE_NAME.MENU.CHAT_ICON_BLUE} />
                          <span>New Chat</span>
                        </button>
                      </div>

                      {/* <div className="col-md-6">
                        <a href="javascript:void(0)" className="add-part-btn" onClick={this.openParticipantModal}>
                          + Create Group
                        </a>
                      </div> */}
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        {this.state.chatGroup.length > 0 ?
                          <ChatGroupList
                            value={this.state.chatGroup}
                            changeGroupValue={(value) => this.changeChatGroup(value)}
                          /> :
                          <></>
                        }
                      </div>
                    </div>
                  </div>
                  {this.showChatPage(this.state.selectedChatGroupValue)}
                </div>
              </div>


              <Modal
                open={this.state.addParticipantShow}
                onClose={this.closeParticipantModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={styleCancelModal}>
                  <div className="doc_action_modal">
                    <div className="row">
                      {/* <div className="web-form-bx">
                        <div className="frm-label">
                          Group Name
                        </div>
                        <div className="form-input-fields">
                          <input
                            type="text"
                            placeholder="Enter group name..."
                            className="textbox4"
                            style={{
                              borderRadius: "9px",
                              boxShadow:
                                "0px 0px 4px 0px rgb(0 0 0 / 20%)",
                            }}
                            value={this.state.chatGroupName}
                            onChange={this.changeChatGroupName}
                          />
                        </div>
                      </div> */}
                      <div className="web-form-bx">
                        <div className="frm-label">Participants *</div>
                        <div className="bts-drop">
                          <SelectBox
                            optionData={this.state.chatParticipants}
                            value={this.state.selectedChatParticipants}
                            onSelectChange={(value) =>
                              this.onSelectChatParticipantsForSingle(value)
                            }
                          ></SelectBox>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="web-form-bx selct">
                          <div className="_button-style _fl text-center">
                            <a
                              href="javascript:void(0)"
                              className="white-btn"
                              onClick={() => this.closeParticipantModal()}
                              style={{ textDecoration: "none" }}
                            >
                              close
                            </a>
                            <a
                              href="javascript:void(0)"
                              className="blue-btn"
                              style={{ textDecoration: "none", color: "#fff" }}
                              onClick={() => this.submitParticipantsForSingle()}
                            >
                              Send Requests
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
              </Modal>

              <Modal
                open={this.state.addGrouoChatParticipantModal}
                onClose={this.closeAddGroupChatParticipantModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">

                <Box sx={styleCancelModal}>
                  <div className="doc_action_modal">
                    <div className="row">
                      <div className="web-form-bx">
                        <div className="frm-label">Participants *</div>
                        <div className="bts-drop">
                          <MultiSelectBox
                            optionData={this.state.addGroupChatParticipant}
                            value={this.state.selectedAddGroupChatParticipantValue}
                            onSelectChange={(value) =>
                              this.selectAddGroupParticipants(value)
                            }
                          ></MultiSelectBox>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="web-form-bx selct">
                          <div className="_button-style _fl text-center">
                            <a
                              href="javascript:void(0)"
                              className="white-btn"
                              onClick={() => this.closeAddGroupChatParticipantModal()}
                              style={{ textDecoration: "none" }}
                            >
                              close
                            </a>
                            <a
                              href="javascript:void(0)"
                              className="blue-btn"
                              style={{ textDecoration: "none", color: "#fff" }}
                              onClick={() => this.submitParticipantsInGroup()}
                            >
                              Add
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
              </Modal>

              <Modal
                open={this.state.chatParticipantModal}
                onClose={this.closeChatParticipantModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={styleCancelModal}>
                  <div className="doc_action_modal">
                    <div className="row">
                      <div className="web-form-bx">
                        <div className="frm-label">Participants List</div>
                        <div className="row">
                          {this.state.groupChatParticipant.map((data, i) => <>
                            <div className="col-md-10" key={i}>
                              {data.label}
                            </div>
                            {this.state.groupChatParticipant.length > 1 ?
                              <div className="col-md-2">
                                <MdDelete style={{ cursor: "pointer" }} onClick={() => this.removeParticipants(data)} />
                              </div> : <></>}
                          </>)}
                        </div>
                      </div>
                      {/* <div className="col-md-12">
                        <div className="web-form-bx selct">
                          <div className="_button-style _fl text-center">
                            <a
                              href="javascript:void(0)"
                              className="white-btn"
                              onClick={() => this.closeChatParticipantModal()}
                              style={{ textDecoration: "none" }}
                            >
                              close
                            </a>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </Box>
              </Modal>


              <div className="tab-pane" id="nofifications">
                <div className="job-section-tab">
                  <div className="chat-app-information-component">
                    <div className="chat-app-component">
                      {this.state.allJobNotifications.map((data, key) => <>
                        <div class="accordion" id={"accordionExample" + key} >
                          <div class="card notify_card">
                            <div
                              class="card-header"
                              id={"headingOne" + key}
                              type="button"
                              style={{
                                borderRadius: "20px ",
                                padding: "20px 0px",
                                backgroundColor: "white",
                              }}
                              data-toggle="collapse"
                              data-target={"#collapseOne" + key}
                              aria-expanded="false"
                              aria-controls={"collapseOne" + key}
                            // onClick={() => this._onCheckNotification(item,key)}
                            >
                              <div>
                                <div className="row notify">
                                  <div className="col-md-2">
                                    <React.Fragment>
                                      <img
                                        src={ImageName.IMAGE_NAME.CLINGO_LOGO_GRAY_COLOR}
                                        style={{ width: "40px" }}
                                      ></img>
                                    </React.Fragment>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="job_details">
                                      {data.title}<br />
                                      {data.body}
                                    </div>
                                  </div>
                                  <div className="col-md-2">
                                    <div className="notify_time">
                                      {moment(data.createDate).format("LLL")}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                      )}
                    </div>
                  </div>
                </div>
              </div>



              {/* ................ Email & SMS data tab ................. */}
              <div className="tab-pane" id="emailsms">
                <div className="job-section-tab">
                  {/* <EditJob mainData={this.state.reqId} /> */}
                  <h2>Email & SMS Log Details</h2>
                  {(this.state.userType === UsersEnums.APPLICATION_ROLE.SUPER_ADMIN || this.state.userType === UsersEnums.APPLICATION_ROLE.ADMIN_STAFF) ?
                    <div className="mailSmsBtn">
                      <RiMailSendLine size={30} data-bs-toggle="tooltip" data-bs-placement="left" title="Send Mail"
                        style={{ cursor: 'pointer' }} onClick={() => this.openEmailSendModal()} />
                      {this.state.sentEmailModal == true ? <React.Fragment><EmailModal showShare={this.state.sentEmailModal} jobId={this.state.numJobId} closeMailModal={() => this.closeEmailSendModal()} /></React.Fragment> : <React.Fragment></React.Fragment>}
                      <MdOutlineSms size={30} data-bs-toggle="tooltip" data-bs-placement="left" title="Send SMS" style={{ cursor: 'pointer' }} onClick={() => this.openSmsModal()} />
                      {this.state.sentSmsModal == true ? <React.Fragment><SmsModal showsms={this.state.sentSmsModal} jobId={this.state.numJobId} closeSms={() => this.closeSmsModal()} /></React.Fragment> : <React.Fragment></React.Fragment>}

                    </div> :
                    <></>
                  }
                  <div className="chat-app-information-component">
                    <div className="chat-app-component">
                      {this.state.allEmailSmsLog.length > 0 ? <React.Fragment>
                        {this.state.allEmailSmsLog.map((data, key) => <React.Fragment key={key}>
                          <div class="accordion" id={"accordionExample" + key} >
                            <div class="card notify_card">
                              <div
                                class="card-header"
                                id={"headingOne" + key}
                                type="button"
                                style={{
                                  borderRadius: "20px ",
                                  padding: "20px 0px",
                                  backgroundColor: "white",
                                }}
                                data-toggle="collapse"
                                data-target={"#collapseOne" + key}
                                aria-expanded="false"
                                aria-controls={"collapseOne" + key}
                              // onClick={() => this._onCheckNotification(item,key)}
                              >
                                <div>
                                  <div className="row notify" onClick={() => this.openViewEmailModal(data)}>
                                    <div className="col-md-2">
                                      <React.Fragment>
                                        <img
                                          src={ImageName.IMAGE_NAME.CLINGO_LOGO_GRAY_COLOR}
                                          style={{ width: "40px" }}
                                        ></img>
                                      </React.Fragment>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="job_details">
                                        {data.logType} : {data.subject}
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="notify_time">
                                        {data.receiver}
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="notify_time">
                                        {SetDateFormat(data.receivedDate)} {SetTimeFormat(data.receivedDate)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>)}
                      </React.Fragment> : <React.Fragment></React.Fragment>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="viewModal" className="modal fade modelwindow" role="dialog">
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="filter-head _fl document-hd">
                <h3 className="text-center center-text">
                  Interpreter Availability
                </h3>
                <button
                  type="button"
                  className="close"
                  onClick={() => this.closeViewModal()}
                >
                  X
                </button>
              </div>

              <div className="modal-body">
                <div className="table-listing-app card">
                  <div className="table-responsive">
                    {/* {consoleLog(
                      "Available Data HTML>>",
                      this.state.availabileData.Tuesday
                    )} */}
                    {Object.keys(this.state.availabileData).length > 0 ? (
                      <BidModal value={this.state.availabileData} />
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </div>
                </div>

                <div className="_button-style _fl text-center">
                  {/* <a className="grey-btn" onClick={() => this.closeModal()}>cancel</a> */}
                  {/* <a className="blue-btn">save</a> */}
                  {/* <a href="#" className="buledark-btn">save & reverse</a> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* //...................... For Bid show Modal....................... */}
        <div
          id="bid-modal"
          className="modal fade modelwindow largewindow"
          role="dialog"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-body p30">
                <div className="mtch-top-text">
                  <p>
                    <span>Project ID</span> {this.state.jobId}
                  </p>
                  <p>
                    <span>Service</span> {this.state.bidtaskType}
                  </p>
                  <p>
                    <span>Task</span> {this.state.bidTask}
                  </p>
                  <p>
                    <span>Client Name</span>
                    {this.state.clientName}
                  </p>
                </div>

                <div className="mv-text _fl">
                  <h2>Matching Vendor</h2>
                </div>

                <div className="matching-vendor-table _fl sdw">
                  <div className="depr_table p10">
                    <div className="table-responsive">
                      <table
                        width="100%"
                        border="0"
                        cellpadding="0"
                        cellspacing="0"
                      >
                        <tbody>
                          <tr>
                            <th style={{ width: "60%" }}>Vendor Name</th>
                            {this.state.bidVendorDetails.length > 0 ? (
                              <React.Fragment>
                                {this.state.bidVendorDetails.map((data) => (
                                  <React.Fragment>
                                    <th
                                      style={{ width: this.state.bidWidth }}
                                      className="th_1"
                                    >
                                      {data.name}
                                    </th>
                                  </React.Fragment>
                                ))}
                              </React.Fragment>
                            ) : (
                              <React.Fragment></React.Fragment>
                            )}
                          </tr>
                          <tr>
                            <td>Action/Status</td>
                            {this.state.bidVendorDetails.length > 0 ? (
                              <React.Fragment>
                                {this.state.bidVendorDetails.map((data) => (
                                  <React.Fragment>
                                    {data.status === 1 ? (
                                      <React.Fragment>
                                        <td className="accept">Bid Received</td>
                                      </React.Fragment>
                                    ) : (
                                      <React.Fragment>
                                        {data.status == 5 ? (
                                          <td className="reject">Rejected</td>
                                        ) : (
                                          <React.Fragment>
                                            <td style={{ color: "orange" }}>
                                              Pending
                                            </td>
                                          </React.Fragment>
                                        )}
                                      </React.Fragment>
                                    )}
                                  </React.Fragment>
                                ))}
                              </React.Fragment>
                            ) : (
                              <React.Fragment></React.Fragment>
                            )}
                          </tr>
                          <tr>
                            <td>Proposed New Deadline</td>
                            {this.state.bidVendorDetails.length > 0 ? (
                              <React.Fragment>
                                {this.state.bidVendorDetails.map((data) => (
                                  <React.Fragment>
                                    {data.status === 1 ? (
                                      <td
                                        style={{ width: this.state.bidWidth }}
                                      >
                                        {data.proposedDeadline !== null ? SetUSAdateFormatV2(data.proposedDeadline) : "N/A"}
                                      </td>
                                    ) : (
                                      <td
                                        style={{ width: this.state.bidWidth }}
                                      >
                                        ....
                                      </td>
                                    )}
                                  </React.Fragment>
                                ))}
                              </React.Fragment>
                            ) : (
                              <React.Fragment></React.Fragment>
                            )}
                          </tr>
                          <tr>
                            <td>Aproximate Word count</td>
                            {this.state.bidVendorDetails.length > 0 ? (
                              <React.Fragment>
                                {this.state.bidVendorDetails.map((data) => (
                                  <React.Fragment>
                                    {data.status === 1 ? (
                                      <td
                                        style={{ width: this.state.bidWidth }}
                                      >
                                        {data.wordCount}
                                      </td>
                                    ) : (
                                      <td
                                        style={{ width: this.state.bidWidth }}
                                      >
                                        ....
                                      </td>
                                    )}
                                  </React.Fragment>
                                ))}
                              </React.Fragment>
                            ) : (
                              <React.Fragment></React.Fragment>
                            )}
                          </tr>

                          <tr>
                            <td>Unit Cost </td>
                            {this.state.bidVendorDetails.length > 0 ? (
                              <React.Fragment>
                                {this.state.bidVendorDetails.map((data) => (
                                  <React.Fragment>
                                    {data.status === 1 ? (
                                      <td
                                        style={{ width: this.state.bidWidth }}
                                      >
                                        $ {data.bidFee + " ( " + data.bidUnit + " )"}
                                      </td>
                                    ) : (
                                      <td
                                        style={{ width: this.state.bidWidth }}
                                      >
                                        ....
                                      </td>
                                    )}
                                  </React.Fragment>
                                ))}
                              </React.Fragment>
                            ) : (
                              <React.Fragment></React.Fragment>
                            )}
                          </tr>
                          <tr>
                            <td>Aproximate DTP hours</td>
                            {this.state.bidVendorDetails.length > 0 ? (
                              <React.Fragment>
                                {this.state.bidVendorDetails.map((data) => (
                                  <React.Fragment>
                                    {data.status === 1 ? (
                                      <td
                                        style={{ width: this.state.bidWidth }}
                                      >
                                        {data.additionalFees.dtpHour}
                                      </td>
                                    ) : (
                                      <td
                                        style={{ width: this.state.bidWidth }}
                                      >
                                        ....
                                      </td>
                                    )}
                                  </React.Fragment>
                                ))}
                              </React.Fragment>
                            ) : (
                              <React.Fragment></React.Fragment>
                            )}
                          </tr>
                          <tr>
                            <td>DTP/Hour Rate</td>
                            {this.state.bidVendorDetails.length > 0 ? (
                              <React.Fragment>
                                {this.state.bidVendorDetails.map((data) => (
                                  <React.Fragment>
                                    {data.status === 1 ? (
                                      <td
                                        style={{ width: this.state.bidWidth }}
                                      >
                                        $ {data.additionalFees.dtpHourRate}
                                      </td>
                                    ) : (
                                      <td
                                        style={{ width: this.state.bidWidth }}
                                      >
                                        ....
                                      </td>
                                    )}
                                  </React.Fragment>
                                ))}
                              </React.Fragment>
                            ) : (
                              <React.Fragment></React.Fragment>
                            )}
                          </tr>
                          <tr>
                            <td>Reject Reason (If Applicable)</td>
                            {this.state.bidVendorDetails.length > 0 ? (
                              <React.Fragment>
                                {this.state.bidVendorDetails.map((data) => (
                                  <React.Fragment>
                                    {data.status === 1 ? (
                                      <td
                                        style={{ width: this.state.bidWidth }}
                                      >
                                        ....
                                      </td>
                                    ) : (
                                      <td
                                        style={{ width: this.state.bidWidth }}
                                      >
                                        {data.rejectReason}
                                      </td>
                                    )}
                                  </React.Fragment>
                                ))}
                              </React.Fragment>
                            ) : (
                              <React.Fragment></React.Fragment>
                            )}
                          </tr>
                          <tr>
                            <td>Notes</td>
                            {this.state.bidVendorDetails.length > 0 ? (
                              <React.Fragment>
                                {this.state.bidVendorDetails.map((data) => (
                                  <React.Fragment>
                                    {data.note === "" ? (
                                      <td
                                        style={{ width: this.state.bidWidth }}
                                      >
                                        ....
                                      </td>
                                    ) : (
                                      <td
                                        style={{ width: this.state.bidWidth }}
                                      >
                                        {data.note}
                                      </td>
                                    )}
                                  </React.Fragment>
                                ))}
                              </React.Fragment>
                            ) : (
                              <React.Fragment></React.Fragment>
                            )}
                          </tr>

                          <tr className="tt-count">
                            <td className="f1">Total Quote</td>
                            {this.state.bidVendorDetails.length > 0 ? (
                              <React.Fragment>
                                {this.state.bidVendorDetails.map((data) => (
                                  <React.Fragment>
                                    {data.status === 1 ? (
                                      <td
                                        style={{
                                          width: this.state.bidWidth,
                                          color: "green",
                                        }}
                                      >
                                        $ {data.totalBidFee}
                                      </td>
                                    ) : (
                                      <td
                                        style={{ width: this.state.bidWidth }}
                                      >
                                        ....
                                      </td>
                                    )}
                                  </React.Fragment>
                                ))}
                              </React.Fragment>
                            ) : (
                              <React.Fragment></React.Fragment>
                            )}
                          </tr>
                          {this.state.quoteStatus >= 2 && this.state.quoteStatus != 7 ?
                            <tr>
                              <td>&nbsp;</td>
                              {this.state.bidVendorDetails.length > 0 ? (
                                <React.Fragment>
                                  {this.state.bidVendorDetails.map((data, i) => (
                                    <React.Fragment key={i}>
                                      {data.status === 1 ? (
                                        <React.Fragment>
                                          <td>
                                            <a
                                              className="bidAssignBtn"
                                              onClick={() =>
                                                this.vendorAssigned(i)
                                              }
                                            >
                                              Assign
                                            </a>
                                          </td>
                                        </React.Fragment>
                                      ) : (
                                        <React.Fragment>
                                          {/* {data.status === 2 ?
                                      <td className="reject" >Rejected</td> : <React.Fragment>
                                        <td style={{ color: "orange" }}>Pending</td>
                                      </React.Fragment>
                                    } */}
                                          <td></td>
                                        </React.Fragment>
                                      )}
                                    </React.Fragment>
                                  ))}
                                </React.Fragment>
                              ) : (
                                <React.Fragment></React.Fragment>
                              )}
                            </tr>
                            : <></>
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="web-form-bx selct">
                      <div className="_button-style _fl text-center">
                        <a
                          href="javascript:void(0)"
                          className="white-btn"
                          onClick={this.closeBidModal}
                          style={{ textDecoration: "none" }}
                        >
                          Back
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ..................Decline modal................................. */}
        <div id="review-modal" className="modal fade modelwindow" role="dialog">
          <div className="modal-dialog modal-md modal-dialog-centered decline-modal-width">
            <div className="modal-content" style={{ width: "100%" }}>
              <div className="cancel-job-head">
                <div className="row">
                  <div className="col-md-12">
                    <h2>Under Review Notes</h2>
                    <button className="close-page">
                      <img
                        src={ImageName.IMAGE_NAME.CLOSE_BTN_3}
                        onClick={this.closeReviewModal}
                      />
                    </button>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <div className="create-jeneral-wrap _fl">
                  <div className="create-row-app">
                    <div className="row" style={{ marginBottom: "15px" }}>
                      <div className="col-md-12">
                        <div className="web-form-app">
                          <div className="web-form-bx selct">
                            <div className="frm-label lblSize">Notes</div>
                            <div
                              className="form-input-fields"
                              style={{ marginBottom: "20px" }}
                            >
                              <textarea
                                value={this.state.reviewNote}
                                placeholder=""
                                style={{ resize: "none" }}
                                className="in-textarea msg min table-style"
                                onChange={this.onReviewNoteChange}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6"></div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="web-form-bx selct">
                          <div className="_button-style _fl text-center">
                            <a
                              href="javascript:void(0)"
                              className="white-btn"
                              onClick={this.closeReviewModal}
                              style={{ textDecoration: "none" }}
                            >
                              cancel
                            </a>
                            <a
                              href="javascript:void(0)"
                              className="blue-btn"
                              style={{ textDecoration: "none" }}
                              onClick={this.sentUnderReview}
                            >
                              Sent
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ...............Vendor List Modal............... */}
        <div id="vendor_list_modal" class="modal fade modelwindow full" role="dialog">
          <div class="modal-dialog modal-dialog-centered modal-lg">

            {/* <!-- Modal content--> */}
            <div class="modal-content">
              <div class="modal-body inset-padding ">

                <div class="_fl list_head-row_translation">
                  <div class="row">
                    <div class="col-md-8">
                      <ul>
                        <li>Service Type : {this.state.vendorListModalDetails.serviceName}</li>
                        <li>Language : {this.state.vendorListModalDetails.sourceLanguage} {" > "}{this.state.vendorListModalDetails.targetLanguage} </li>
                        {/* <li>{this.state.vendorListModalDetails.targetLanguage}</li> */}
                      </ul>
                    </div>
                    {/* <div class="col-md-4"><div class="vn_frm">
                      <input type="text" value={this.state.searchText} name="" placeholder="Search" class="inputfield flr" onChange={(e) => this.vendorSearchTextChange(e)} />
                    </div>
                    </div> */}
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="vendoroff_input-container">
                        <label for="name">Name:</label>
                        <input
                          type="text"
                          id="name"
                          name="d_from"
                          className="textbox4"
                          value={this.state.vendorFilterName}
                          onChange={this.vendorFilterNameChange}
                        />
                        <label for="phone">Phone:</label>
                        <input
                          type="text"
                          id="phone"
                          name="d_from"
                          className="textbox4"
                          value={this.state.vendorFilterPhone}
                          onChange={this.vendorFilterPhoneChange}
                        />
                        <label for="email">Email:</label>
                        <input
                          type="text"
                          id="email"
                          name="d_from"
                          className="textbox4"
                          value={this.state.vendorFilterEmail}
                          onChange={this.vendorFilterEmailChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="vendoroff_input-container">
                        <label for="email">Rating:</label>
                        <div className="bts-drop">
                          <SelectBox
                            optionData={this.state.allVendorRating}
                            value={this.state.selectedVendorFilterRating}
                            onSelectChange={(value) =>
                              this.vendorFilterRatingChange(value)
                            }
                          ></SelectBox>
                        </div>
                        <label for="email">Status:</label>
                        <div className="bts-drop">
                          <SelectBox
                            optionData={this.state.allVendorFilterStatus}
                            value={this.state.selectedVendorFilterStatus}
                            onSelectChange={(value) =>
                              this.vendorFilterStatusChange(value)
                            }
                          ></SelectBox>
                        </div>
                        <div className="available_vendor_filter_btn_div">
                          <button className="vendoroff_filter_submit" onClick={() => this.vendorFilterApply()}>Apply</button>
                          <button className="vendoroff_filter_cancel" onClick={() => this.resetFilterVendor()}>Reset</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="table-listing-app">
                  <div class="table-responsive">
                    <table width="100%" border="0" cellpadding="0" cellspacing="0">
                      <tbody>
                        <tr>
                          <th style={{ width: "5%", paddingLeft: "12px" }}>
                            {/* <label class="custom_check2">
                              <input type="checkbox" />
                              <span class="checkmark2"></span>
                            </label> */}
                          </th>
                          <th style={{ width: "30%" }}>Name / Agency</th>
                          <th style={{ width: "20%" }}>Email </th>
                          <th style={{ width: "15%" }}>Phone Number</th>
                          <th style={{ width: "10%" }}>Rating</th>
                          {/* <th style={{ width: "10%" }}>Availability</th> */}
                          <th style={{ width: "10%" }}>Status</th>
                        </tr>
                        {/* {consoleLog("List data of vendor list>>", this.state.listData)} */}
                        {this.state.listData.length > 0 ? <React.Fragment>
                          {/* {this.state.listData.map((item, key) => <React.Fragment>
                            <tr>
                              <td colspan="11">
                                <div class="tble-row">
                                  <table width="100%" border="0" cellpadding="0" cellspacing="0">
                                    <tr>
                                      <td style={{ width: "5%", paddingLeft: "12px" }}>
                                        <label class="custom_check2">
                                          <input
                                            type="checkbox"
                                            name={item.userId}
                                            value={item.userId}
                                            defaultChecked={
                                              item.isQuoteSent === 1 ? true : false
                                            }
                                            onChange={(e) =>
                                              this.listChecked(e, key)
                                            } />
                                          <span class="checkmark2"></span>
                                        </label>
                                      </td>
                                      <td style={{ width: "30%" }}>
                                        {item.agencyName === ""
                                          ? item.fName + " " + item.lName
                                          : item.fName +
                                          " " +
                                          item.lName +
                                          " (" +
                                          item.agencyName +
                                          ")"}
                                      </td>
                                      <td style={{ width: "20%" }}>
                                        <a href="javascript:void(0)" className="viewlink">
                                          {item.email}
                                        </a>
                                      </td>
                                      <td style={{ width: "15%" }}>+1 {item.mobile}</td>
                                      <td style={{ width: "10%" }}>
                                        <img src={ImageName.IMAGE_NAME.STARYELLOW} />
                                        <span className="rat_txt">{item.ratings}</span>
                                      </td>
                                      <td style={{ width: "10%" }}></td>
                                    </tr>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          </React.Fragment>)} */}
                          {vendor_list}
                        </React.Fragment> : <React.Fragment>
                          <tr>
                            <td colspan="6">No data found</td>
                          </tr>
                        </React.Fragment>}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="_button-style _fl text-center margin-top-30">
                  <a href="javascript:void(0)" style={{ textDecoration: "none" }} className="white-btn" onClick={() => { this.closeVendorListModal() }}>Cancel</a>
                  {this.state.vendorSentOfferBtn ?
                    <a
                      href="javascript:void(0)"
                      style={{ textDecoration: "none", color: "#fff" }}
                      className="blue-btn" >
                      <i class="fa fa-spinner fa-spin"></i>
                    </a> : <>
                      <a
                        href="javascript:void(0)"
                        style={{ textDecoration: "none", color: "#fff" }}
                        className="blue-btn"
                        onClick={() => { this.handleVendorAssign() }}>Apply</a>
                    </>
                  }
                  {/* <a href="javascript:void(0)" style={{ textDecoration: "none", color: "#fff" }} className="blue-btn" onClick={() => { this.handleVendorAssign() }}>Apply</a> */}
                </div>
              </div>

            </div>

          </div>
        </div>

        <div
          class="modal-backdrop fade show"
          id="backdrop"
          style={{ display: "none" }}
        ></div>


        <Modal
          open={this.state.actionModal}
          onClose={this.closeActionModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleCancelModal}>
            <div className="doc_action_modal">
              <div className="row">
                <div className="web-form-bx">
                  Action :
                  <div
                    className="dropdwn"
                    style={{ width: "100%" }}
                  >
                    <Select
                      styles={customStyles}
                      name="select"
                      placeholder="Select"
                      components={{
                        DropdownIndicator,
                        IndicatorSeparator: () => null,
                      }}
                      value={this.state.selectedModalActionArr}
                      options={this.state.modalActionArr}
                      onChange={(value) =>
                        this.onSourceDocActionChange(value)
                      }
                    />

                  </div>
                </div>
              </div>
              <div className="row">
                <div className="web-form-bx">
                  Documents :
                </div>
                {this.state.docModalData.length > 0 ? <React.Fragment>
                  {this.state.docModalData.map((data, i) => (
                    <div className="col-md-6 tr_docs" style={{ paddingTop: "10px" }}>
                      <div
                        className="docModalCrossBtn"
                        onClick={() => this.deleteModalDocs(i)}
                      >
                        <img src={ImageName.IMAGE_NAME.CROSS_BTN} />
                      </div>
                      <img
                        style={{
                          cursor: "pointer",
                          margin: "auto",
                          width: "25px",
                        }}
                        data-toggle="tooltip"
                        data-placement="top"
                        title={data.path}
                        src={ImageName.IMAGE_NAME.UPLOADED_FILE}
                      /><br />
                      <span>{data.filename}</span>
                    </div>
                  ))}</React.Fragment> :
                  <React.Fragment></React.Fragment>
                }
                <div className="col-md-6" style={{ paddingTop: "10px" }}>
                  <div className="upload-profile">
                    {this.state.isDocUpload ?
                      <i class="fa fa-spinner fa-spin"></i> :
                      <label
                        for={"doc_image"}
                      >
                        <img
                          style={{
                            cursor: "pointer",
                            margin: "auto",
                          }}
                          src={ImageName.IMAGE_NAME.ADD_WITH_CIRCLE_BG}
                        />
                        <input
                          type="file"
                          id={"doc_image"}
                          style={{ display: "none" }}
                          onChange={(e) => {
                            this.onUploadDocument(e, this.state.docModalIndex);
                          }}
                        />
                      </label>
                    }
                  </div>
                </div>
              </div>
              <div className="row" style={{ paddingTop: "10px" }}>
                <div className="web-form-bx">
                  Notes :
                  <div className="form-input-fields">
                    <textarea
                      value={this.state.modalNotes}
                      placeholder=""
                      className="in-textarea msg min table-style"
                      onChange={this.actionModalNotesChange}
                      style={{ resize: "none" }}
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="web-form-bx selct">
                    <div className="_button-style _fl text-center">
                      <a
                        href="javascript:void(0)"
                        className="white-btn"
                        onClick={() => this.closeActionModal()}
                        style={{ textDecoration: "none" }}
                      >
                        close
                      </a>
                      {!this.state.docSubmit ?
                        <a
                          href="javascript:void(0)"
                          className="blue-btn"
                          style={{ textDecoration: "none", color: "#fff" }}
                          onClick={() => this.onSubmitActionDocument()}
                        >
                          submit
                        </a> :
                        <a
                          href="javascript:void(0)"
                          className="blue-btn"
                          style={{ textDecoration: "none", color: "#fff" }}
                        // onClick={() => this.onSubmitActionDocument()}
                        >
                          <i class="fa fa-spinner fa-spin"></i>
                        </a>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Modal>


        <Modal
          open={this.state.addDocumentModal}
          onClose={this.closeAddDocModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleCancelModal}>
            <div className="doc_action_modal">
              <div className="row">
                <div className="web-form-bx">
                  Action :
                  <div
                    className="dropdwn"
                    style={{ width: "100%" }}
                  >
                    <Select
                      styles={customStyles}
                      name="select"
                      placeholder="Select"
                      components={{
                        DropdownIndicator,
                        IndicatorSeparator: () => null,
                      }}
                      value={this.state.selectedTaskDropdown}
                      options={this.state.allTaskDropdownList}
                      onChange={(value) =>
                        this.onTaskDropdownListChange(value)
                      }
                    />

                  </div>
                </div>
              </div>
              {/* <div className="row">
                <div className="web-form-bx">
                  Document Name :
                  <input
                    type="text"
                    className="in-field3"
                    placeholder="Enter Name"
                    value={this.state.documentNameAddDocModal}
                    onChange={this.documentNameChange}
                  />
                </div>
              </div> */}
              <div className="row">
                <div className="web-form-bx">
                  Documents :
                </div>
                {this.state.docModalData.length > 0 ? <React.Fragment>
                  {this.state.docModalData.map((data, i) => (
                    <div className="col-md-2" style={{ paddingTop: "10px" }}>
                      <div
                        className="docModalCrossBtn"
                        onClick={() => this.deleteModalDocs(i)}
                      >
                        <img src={ImageName.IMAGE_NAME.CROSS_BTN} />
                      </div>
                      <img
                        style={{
                          cursor: "pointer",
                          margin: "auto",
                          width: "25px",
                        }}
                        data-toggle="tooltip"
                        data-placement="top"
                        title={data}
                        src={ImageName.IMAGE_NAME.UPLOADED_FILE}
                      />
                    </div>
                  ))}</React.Fragment> :
                  <React.Fragment></React.Fragment>
                }
                <div className="col-md-2" style={{ paddingTop: "10px" }}>
                  <div className="upload-profile">
                    {this.state.isDocUpload ?
                      <i class="fa fa-spinner fa-spin"></i> :
                      <label
                        for={"doc_image"}
                      >
                        <img
                          style={{
                            cursor: "pointer",
                            margin: "auto",
                          }}
                          src={ImageName.IMAGE_NAME.ADD_WITH_CIRCLE_BG}
                        />
                        <input
                          type="file"
                          id={"doc_image"}
                          style={{ display: "none" }}
                          onChange={(e) => {
                            this.onUploadDocument(e, this.state.docModalIndex);
                          }}
                        />
                      </label>
                    }
                  </div>
                </div>
              </div>
              <div className="row ven-doc-notes">
                <div className="web-form-bx">
                  Notes :
                  <div className="form-input-fields">
                    <textarea
                      value={this.state.addDocModalNotes}
                      placeholder="Enter notes"
                      className="in-textarea msg min table-style"
                      onChange={this.addDocumentModalNotesChange}
                      style={{ resize: "none" }}
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="web-form-bx selct">
                    <div className="_button-style _fl text-center">
                      <a
                        href="javascript:void(0)"
                        className="white-btn"
                        onClick={() => this.closeAddDocModal()}
                        style={{ textDecoration: "none" }}
                      >
                        close
                      </a>
                      <a
                        href="javascript:void(0)"
                        className="blue-btn"
                        style={{ textDecoration: "none", color: "#fff" }}
                        onClick={() => this.submitDocumentByUser()}
                      >
                        submit
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Modal>

        <Modal
          open={this.state.markAsCompleteModal}
          onClose={this.closeCompleteModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleCancelModal}>
            <div className="doc_action_modal">
              <div className="row ven-doc-notes">
                <div className="web-form-bx">
                  Notes :
                  <div className="form-input-fields">
                    <textarea
                      value={this.state.completeNote}
                      placeholder="Enter Notes"
                      className="in-textarea msg min table-style"
                      onChange={this.completeNoteChange}
                      style={{ resize: "none" }}
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="web-form-bx selct">
                    <div className="_button-style _fl text-center">
                      <a
                        href="javascript:void(0)"
                        className="white-btn"
                        onClick={() => this.closeCompleteModal()}
                        style={{ textDecoration: "none" }}
                      >
                        close
                      </a>
                      <a
                        href="javascript:void(0)"
                        className="blue-btn"
                        style={{ textDecoration: "none", color: "#fff" }}
                        onClick={() => this.completeRequest()}
                      >
                        submit
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Modal>

        <Modal
          open={this.state.viewDocNotes}
          onClose={this.closeViewDocNotes}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleCancelModal}>
            <div className="doc_action_modal">
              <span className="tr_nl">
                {this.state.docNotes}
              </span>
            </div>
          </Box>
        </Modal>


        <Modal
          open={this.state.viewEmailModal}
          onClose={this.closeViewEmailModal}
          size="lg"
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          centered
        >
          <Box sx={styleEmailViewModal}>
            <div className="email_details_header">
              {this.state.viewEmailModalData.logType === 'Email' ? 'Email Data' : 'SMS Data'}
            </div>
            <div className="doc_action_modal">
              <div className="row">
                {this.state.viewEmailModalData.logType === 'Email' ?
                  <div className="col-md-12">
                    <label>Sender : &nbsp;</label>
                    {this.state.viewEmailModalData.sender}
                  </div> : <></>
                }
                <div className="col-md-12">
                  <label>Sent To : &nbsp;</label>
                  {this.state.viewEmailModalData.receiver}
                </div>
                {this.state.viewEmailModalData.logType === 'Email' ?
                  <div className="col-md-12">
                    <label>Subject : &nbsp;</label>
                    {this.state.viewEmailModalData.subject}
                  </div> : <></>
                }
                <div className="col-md-12">
                  <span className="tr_nl">
                    {ReactHtmlParser(this.state.viewEmailModalData.content)}
                  </span>
                </div>
                <Button onClick={this.closeViewEmailModal} color="primary">
                  Close
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
      </React.Fragment >
    );
  }
}

class BidModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <table width="100%" border="0" cellpadding="0" cellspacing="0">
          <tr>
            <th style={{ width: "14%" }}>Sunday</th>
            <th style={{ width: "14%" }}>Monday</th>
            <th style={{ width: "14%" }}>Tuesday</th>
            <th style={{ width: "14%" }}>Wednesday</th>
            <th style={{ width: "14%" }}>Thursday</th>
            <th style={{ width: "14%" }}>Friday</th>
            <th style={{ width: "14%" }}>Saturday</th>
          </tr>
          <tr>
            <td
              style={{
                justifyContent: "center",
              }}
            >
              {this.props.value.Sunday.map((obj) => (
                <React.Fragment>
                  <div>
                    {obj.serviceName.split(",").map((item) => (
                      <div className="f2f_rate">{item}</div>
                    ))}
                    <div
                      style={{
                        marginLeft: "5px",
                        marginTop: "8px",
                      }}
                    >
                      {obj.startTime} - {obj.endTime}
                    </div>
                  </div>
                  <br />
                </React.Fragment>
              ))}
            </td>
            {/* .............monday................. */}
            <td
              style={{
                justifyContent: "center",
              }}
            >
              {this.props.value.Monday.map((obj) => (
                <React.Fragment>
                  <div>
                    {obj.serviceName.split(",").map((item) => (
                      <div className="f2f_rate">{item}</div>
                    ))}
                    <div
                      style={{
                        marginLeft: "5px",
                        marginTop: "8px",
                      }}
                    >
                      {obj.startTime} - {obj.endTime}
                    </div>
                  </div>
                  <br />
                </React.Fragment>
              ))}
            </td>
            {/* ..............Tuesday................ */}
            <td
              style={{
                justifyContent: "center",
              }}
            >
              {this.props.value.Tuesday.map((obj) => (
                <React.Fragment>
                  <div>
                    {obj.serviceName.split(",").map((item) => (
                      <div className="f2f_rate">{item}</div>
                    ))}
                    <div
                      style={{
                        marginLeft: "5px",
                        marginTop: "8px",
                      }}
                    >
                      {obj.startTime} - {obj.endTime}
                    </div>
                  </div>
                  <br />
                </React.Fragment>
              ))}
            </td>
            {/* ..........wednesday............... */}
            <td
              style={{
                justifyContent: "center",
              }}
            >
              {this.props.value.Wednesday.map((obj) => (
                <React.Fragment>
                  <div>
                    {obj.serviceName.split(",").map((item) => (
                      <div className="f2f_rate">{item}</div>
                    ))}
                    <div
                      style={{
                        marginLeft: "5px",
                        marginTop: "8px",
                      }}
                    >
                      {obj.startTime} - {obj.endTime}
                    </div>
                  </div>
                  <br />
                </React.Fragment>
              ))}
            </td>
            {/* ................thursday............... */}
            <td
              style={{
                justifyContent: "center",
              }}
            >
              {this.props.value.Thursday.map((obj) => (
                <React.Fragment>
                  <div>
                    {obj.serviceName.split(",").map((item) => (
                      <div className="f2f_rate">{item}</div>
                    ))}
                    <div
                      style={{
                        marginLeft: "5px",
                        marginTop: "8px",
                      }}
                    >
                      {obj.startTime} - {obj.endTime}
                    </div>
                  </div>
                  <br />
                </React.Fragment>
              ))}
            </td>
            {/* ............friday.................... */}
            <td
              style={{
                justifyContent: "center",
              }}
            >
              {this.props.value.Friday.map((obj) => (
                <React.Fragment>
                  <div>
                    {obj.serviceName.split(",").map((item) => (
                      <div className="f2f_rate">{item}</div>
                    ))}
                    <div
                      style={{
                        marginLeft: "5px",
                        marginTop: "8px",
                      }}
                    >
                      {obj.startTime} - {obj.endTime}
                    </div>
                  </div>
                  <br />
                </React.Fragment>
              ))}
            </td>
            {/* ................saturday.......... */}
            <td
              style={{
                justifyContent: "center",
              }}
            >
              {this.props.value.Saturday.map((obj) => (
                <React.Fragment>
                  <div>
                    {obj.serviceName.split(",").map((item) => (
                      <div className="f2f_rate">{item}</div>
                    ))}
                    <div
                      style={{
                        marginLeft: "5px",
                        marginTop: "8px",
                      }}
                    >
                      {obj.startTime} - {obj.endTime}
                    </div>
                  </div>
                  <br />
                </React.Fragment>
              ))}
            </td>
          </tr>
        </table>
      </React.Fragment>
    );
  }
}

class Schedule extends React.Component {
  render() {
    const { onClick } = this.props;
    return (
      <img
        style={{
          width: "35px",
          height: "37px",
          borderRadius: "4px",
          cursor: "pointer",
        }}
        src={ImageName.IMAGE_NAME.CALENDER4}
        onClick={onClick}
      />
    );
  }
}
