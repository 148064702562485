// define all fields for common data

export const COMMON = Object.freeze({
  CLIENT: [
    {
      id: "tru6pftdf5kuz38q56",
      name: "Paul Disney"
    },
    {
      id: "tru6pftdf5kuz38q34",
      name: "Rozer Alfred"
    }
  ],
  SUPERVISOR: [
    {
      id: "tru6pftdf5kuz38a11",
      clientId: "tru6pftdf5kuz38q56",
      name: "Stephen Walter"
    },
    {
      id: "tru6pftdf5kuz38d45",
      clientId: "tru6pftdf5kuz38q56",
      name: "Henry Conner"
    }, {
      id: "tru6pftdf5kuz38b12",
      clientId: "tru6pftdf5kuz38q34",
      name: "Gabriel Lopez"
    },
    {
      id: "tru6pftdf5kuz38c19",
      clientId: "tru6pftdf5kuz38q34",
      name: "Pablo Hichcock"
    }
  ],
  DISPLAY_ARR: [
    // {
    //   label: "10",
    //   value: 10,
    // },
    {
      label: "20",
      value: 20,
    },
    {
      label: "30",
      value: 30,
    },
    {
      label: "40",
      value: 40,
    },
    {
      label: "50",
      value: 50,
    },
  ],
  NOTIFICATION: {
    GENERAL: "GEN",
    REMINDER: "REMIND",
    JOB: "JOB",
    BIRTHDAY: "BIRTH"
  },
  PROJECT_DUMMY_TASK_TRANSLATION: [
    {
      "targetLanguageId": "3",
      "sourceLanguageId": "110",
      "id": 50,
      "documentType": ".docx",
      "documentName": "Doc 1",
      "documentPath": "/images/dummypdf1644822478588.pdf",
      "wordCount": 1000,
      "sourceLanguage": "English",
      "serviceName": "Translation",
      "targetLanguage": "Adja",
      "bidCounter": 0,
      "subTask": []
    },
    {
      "targetLanguageId": "3",
      "sourceLanguageId": "110",
      "id": 51,
      "documentType": ".docx",
      "documentName": "Doc 2",
      "documentPath": "/images/dummyExcel1644822510438.xlsx",
      "wordCount": 1000,
      "sourceLanguage": "English",
      "serviceName": "Voice Over",
      "targetLanguage": "Adja",
      "bidCounter": 0,
      "subTask": []
    }
  ],
  CLIENT_JOB_STATUS: [
    {
      label: "Requested",
      value: "0",
    },
    {
      label: "Quote Received",
      value: "1",
    },
    {
      label: "Quote Accepted",
      value: "2",
    },
    {
      label: "Offer Sent to Interpreter",
      value: "3",
    },
    {
      label: "Offer Accepted by Interpreter",
      value: "4",
    },
    {
      label: "Offer Rejected by Interpreter",
      value: "5",
    },
    // {
    //   label: "Bids Received",
    //   value: "6",
    // },
    {
      label: "Quote Rejected",
      value: "7",
    },

    {
      label: "Interpreter Assigned",
      value: "8",
    },
    {
      label: "InProgress",
      value: "9",
    },
    {
      label: "Completed",
      value: "10",
    },
    {
      label: "Cancelled",
      value: "11",
    },
  ],
  CLIENT_HISTORY_JOB_STATUS: [
    {
      label: "Completed",
      value: "10",
    },
    {
      label: "Cancelled",
      value: "11",
    },
  ],

  REPORT_TYPE: [
    {
      label: "Language Report",
      value: "Language Report"
    },
    {
      label: "Vendor Jobs Report",
      value: "Vendor Jobs Report"
    },
    {
      label: "Outstanding Invoices",
      value: "Outstanding Invoices"
    },
    {
      label: "Outstanding Payables",
      value: "Outstanding Payables"
    },
    {
      label: "On-Demand VRI Summary",
      value: "On-Demand VRI Summary"
    },
    {
      label: "On-Demand OPI Summary",
      value: "On-Demand OPI Summary"
    },
    {
      label: "Newly Onboarded Vendors",
      value: "Newly Onboarded Vendors"
    },
    {
      label: "Newly Onboarded Clients",
      value: "Newly Onboarded Clients"
    },
    {
      label: "Historical Fulfillment Report",
      value: "Historical Fulfillment Report"
    },
    {
      label: "Dashboard",
      value: "Dashboard"
    },
    {
      label: "Customer Satisfaction Report",
      value: "Customer Satisfaction Report"
    },
    {
      label: "Historical Job Status Per Customer",
      value: "Historical Job Status Per Customer"
    },
    {
      label: "Client Profile Report",
      value: "Client Profile Report"
    },
    {
      label: "Vendor Profile Report",
      value: "Vendor Profile Report"
    },
    {
      label: "Profit Margin Report",
      value: "Profit Margin Report"
    }
  ],

  REPORT_TIME_PERIOD: [
    {
      label: "Last 10 Days",
      value: "Last 10 Days"
    },
    {
      label: "Last 1 Month",
      value: "Last 1 Month"
    },
    {
      label: "Last 3 Months",
      value: "Last 3 Months"
    },
    {
      label: "Last 6 Months",
      value: "Last 6 Months"
    },
    {
      label: "custom",
      value: "custom"
    }
  ],

  DECLINE_QUOTE_BY_CLIENT: [
    {
      label: "Price",
      value: "Price"
    },
    {
      label: "Deadline",
      value: "Deadline"
    },
    {
      label: "No Longer Needed",
      value: "No Longer Needed"
    },
    {
      label: "Other",
      value: "Other"
    }
  ],

  ROLE_ARR_FOR_AUDIO: [
    {
      label: "All",
      value: ""
    },
    {
      label: "Client Admin",
      value: "3"
    },
    {
      label: "Requester",
      value: "6"
    },
    {
      label: "Supervisor",
      value: "7"
    },
    {
      label: "Admin Staff",
      value: "2"
    },
    {
      label: "Vendor",
      value: "4"
    }
  ],

  SORTING_ARRAY_AVAILABLE_VENDOR: [
    { "value": 1, "label": "Name (A to Z)", "sort": "name", "shortBy": "ASC" },
    { "value": 2, "label": "Name (Z to A)", "sort": "name", "shortBy": "DESC" },
    { "value": 3, "label": "Distance (Low to High)", "sort": "distance", "shortBy": "ASC" },
    { "value": 4, "label": "Distance (High to Low)", "sort": "distance", "shortBy": "DESC" },
    { "value": 5, "label": "Relationship to Client(Low to High)", "sort": "Relationship", "shortBy": "ASC" },
    { "value": 6, "label": "Relationship to Client(High to Low)", "sort": "Relationship", "shortBy": "DESC" },
    { "value": 7, "label": "Relationship to Lei(Low to High)", "sort": "RelationshipLei", "shortBy": "ASC" },
    { "value": 8, "label": "Relationship to Lei(High to Low)", "sort": "RelationshipLei", "shortBy": "DESC" },
    { "value": 9, "label": "Ratings(Low to High)", "sort": "ratings", "shortBy": "ASC" },
    { "value": 10, "label": "Ratings(High to Low)", "sort": "ratings", "shortBy": "DESC" }
  ]

});